import SubscriptionService from "../../services/SubscriptionService";
import Notifications from "../../util/Notifications";
import Logger from "../../data/Logger";

export const getSubscriptions = (filters) => () =>
  new Promise((resolve) => {
    SubscriptionService.getSubscriptions(filters)
      .then((r) => {
        resolve(r);
      })
      .catch(() => {
        Notifications.error("Failed to get Subscriptions");
        resolve(false);
      });
  });

export const deleteSubscription = (id) => () =>
  new Promise((resolve) => {
    Notifications.async(SubscriptionService.deleteSubscription(id), {
      loadingMessage: `Deleting subscription ${id}`,
      successMessage: "Deleted subscription successfully",
      errorMessage: "Failed to delete the subscription",
    })
      .then(() => resolve(true))
      .catch((e) => {
        Logger.exception(e);
        resolve(false);
      });
  });

export const addSubscription = (data) => () =>
  new Promise((resolve, reject) => {
    SubscriptionService.addSubscription(data)
      .then((r) => {
        Notifications.success("Subscription added successfully");
        resolve(r);
      })
      .catch((e) => {
        Notifications.error("Failed to add Subscriptions");
        Logger.exception(e);
        reject(e);
      });
  });

export const updateSubscription = (id, data) => () =>
  new Promise((resolve, reject) => {
    SubscriptionService.updateSubscription(id, data)
      .then((r) => {
        Notifications.success("Updated subscription");
        resolve(r);
      })
      .catch((e) => {
        console.log(e.errorMessage);
        Notifications.error("Failed to update Subscriptions");
        Logger.exception(e);
        reject(e);
      });
  });

export const getSubscription = (id) => () =>
  new Promise((resolve) => {
    (Array.isArray(id)
      ? Promise.all(id.map((i) => SubscriptionService.getSubscription(i)))
      : SubscriptionService.getSubscription(id)
    )
      .then((r) => resolve(r))
      .catch(() => resolve(false));
  });

export const searchSchool = (input) => () =>
  new Promise((resolve) => {
    SubscriptionService.searchSchool(input)
      .then((r) => resolve(r?.results))
      .catch((e) => {
        Logger.exception(e);
        resolve(false);
      });
  });

export const getSchoolOwner = (input) => () =>
  new Promise((resolve) => {
    SubscriptionService.searchSchool(input)
      .then((r) => resolve(r?.results[0].user_id))
      .catch((e) => {
        Logger.exception(e);
        resolve(false);
      });
  });
