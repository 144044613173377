import { connect } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { Button, Form, Input } from 'antd';
import {
  addQna,
  deleteQna,
  getAdventure,
  updateQna,
} from '../../redux/qna-activity/actions';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import PageLoader from '../../components/PageLoader';
import InputRules from '../../util/InputRules';
import Language from '../../data/Language';
import WordsInput from '../../components/form/QnaInput';
import Data from '../../data';

function parseAdventure(adventure) {
  adventure.language_to = adventure.language_to?.id;
  adventure.language_from = adventure.language_from?.id;
  adventure.words = adventure.words?.map(Data.mapWord);
  return adventure;
}

function formatAdventure(form) {
  return form;
}

function AdventureForm({
  id,
  item,
  addQna,
  deleteQna,
  getAdventure,
  updateQna,
  onDone,
  onCancel,
  submitting,
  setSubmitting,
}) {
  const [loading, setLoading] = useState(false);
  const formRef = useRef();
  const isEdit = !!id;

  const [adventure, setAdventure] = useState(item ? parseAdventure(item) : null);

  useEffect(() => {
    if (!id || item) return;

    setLoading(true);
    getAdventure(id)
      .then(word => (word ? setAdventure(parseAdventure(word)) : onCancel()))

      .finally(() => setLoading(false));
  }, [item, onCancel, getAdventure, id]);

  const onSubmit = values => {
    values = formatAdventure(values);

    if (adventure?.words === values?.words) {
      values = {
        ...values,
        words: undefined,
      };
    }

    setSubmitting(true);
    if (isEdit) {
      updateQna(id, values)
        .then(updated => onDone(updated))
        .catch(() => {})
        .finally(() => setSubmitting(false));
    } else {
      addQna(values)
        .then(added => onDone(added))
        .catch(() => {})
        .finally(() => setSubmitting(false));
    }
  };

  const onDelete = () => {
    ConfirmationDialog({
      title: 'Are you sure you want to delete this QnA?',
      onConfirm: () => {
        deleteQna(id).then(() => onDone(adventure));
      },
    });
  };

  return loading ? (
    <PageLoader />
  ) : (
    <Form
      ref={formRef}
      className="adventure-form"
      onFinish={onSubmit}
      layout="vertical"
      initialValues={
        isEdit
          ? adventure
          : {
              is_public: true,
              is_paid: false,
              total_games: 4,
              language_from: Language.JAPANESE.id,
              language_to: Language.ENGLISH.id,
            }
      }
    >
      <Form.Item
        label="Name"
        name="name"
        rules={InputRules.required('Please input name')}
      >
        <Input placeholder="Qna name" />
      </Form.Item>

      <Form.Item name="qnas" label="QnA (Available QnA)">
        <WordsInput multiple />
      </Form.Item>

      <div className="form-buttons">
        {!isEdit ? (
          <div />
        ) : (
          <Button
            type="text"
            size="large"
            loading={loading}
            onClick={onDelete}
            className="danger"
            disabled={submitting}
          >
            Delete
          </Button>
        )}
        <Button
          type="primary"
          htmlType="submit"
          size="large"
          loading={submitting}
          className="wide"
          disabled={submitting}
        >
          {isEdit ? 'Update this ' : 'Add'} QnA Activity
        </Button>
      </div>
    </Form>
  );
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  getAdventure: (...args) => dispatch(getAdventure(...args)),
  addQna: (...args) => dispatch(addQna(...args)),
  updateQna: (...args) => dispatch(updateQna(...args)),
  deleteQna: (...args) => dispatch(deleteQna(...args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdventureForm);
