import { http } from "../util/http";
import Util from "../util";

export default class UnityLogService {
  static paginate(filters) {
    return http.get(`/analytics/logs/unity/?${Util.toQuery(filters)}`);
  }

  static async search(input) {
    return http.get(`/analytics/logs/unity/?search=${input}`);
  }

  static get(id) {
    return http.get(`/analytics/logs/unity/${id}/`);
  }

  static getDownloadURL(id) {
    console.log("chal nahi raha tha?")
    return http.get(`/analytics/logs/unity/${id}/get_log_file/`);
  }
}
