import Page from "../../components/layout/Page";
import { useState } from "react";
import EditableTable from "./InputTable";
import { Button, Input } from "antd";
import { CSVLink } from 'react-csv';

const  StudentRegistration = () => {
  const [file, setFile] = useState();
  const [array, setArray] = useState([]);
  const headers = [
    { label: "email", key: "email" },
    { label: "username", key: "username" },
    { label: "password", key: "password" },
    { label: "FirstName", key: "FirstName" },
    { label: "LastName", key: "LastName" },
    { label: "TeacherCode", key: "TeacherCode" },
    { label: "InstituteCode", key: "InstituteCode" },
    { label: "Status", key: "Comments" },
    ];

  const data = [
    {
    email: "Example email",
    username: "Example username",
    password: "Example password",
    FirstName: "Example first name",
    LastName: "Example last name",
    TeacherCode: "Example teacher code",
    InstituteCode: "Example institude code",
    Comments: "",
    },
  ]

  const fileReader = new FileReader();

  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
  };

  const csvFileToArray = (string) => {
    const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");

    const arr = csvRows.map((i) => {
      const values = i.split(",");
      const obj = csvHeader.reduce((object, header, index) => {
        object[header] = values[index];
        return object;
      }, {});
      return obj;
    });

    setArray(arr);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();

    if (file) {
      fileReader.onload = function (event) {
        const text = event.target.result;
        csvFileToArray(text);
      };

      fileReader.readAsText(file);
    }
  };

  return (
    <Page title="Student Rewards">
        <form>
          <div style={{display:'flex'}}>
            <div style={{display:'flex',flex:0.8}}>
          <h4>
          Step 1:
          </h4>
          <Input
            style={{ width:"auto",fontSize:16,marginLeft:10,marginRight:10}}
            type={"file"}
            id={"csvFileInput"}
            accept={".csv"}
            onChange={handleOnChange}
          />
          <h4 style={{marginLeft:50,marginRight:10}}>
            Step 2:
          </h4>
          <Button
            onClick={(e) => {
              handleOnSubmit(e);
            }}
          >
            IMPORT CSV
          </Button>
          </div>
          <div style={{flex:0.2}}>
          <CSVLink
        headers={headers}
        data={data}
        filename="template.csv"
        target="_blank"
      >
        Download Template
      </CSVLink>
      </div>
      </div>
      Total: {array?.length}
        </form>
      <EditableTable
      data={array} />
    </Page>
  );
}

export default StudentRegistration;