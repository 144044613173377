import { http } from "../util/http";

export default class ExportReportService {
  static getAllExports() {
    return http.get(`dictionary/list_export_data/`);
  }

  static exportReport(type, name) {
    return http.post("dictionary/export_data/", { type, name });
  }
}
