import { connect } from "react-redux";
import { Button, Form, Input } from "antd";
import InputRules from "../../util/InputRules";
import { createReward } from "../../redux/student-reward/actions";

function StudentRewardForm({
  id,
  createReward,
  onDone,
  onCancel,
  submitting,
  setSubmitting,
}) {
  function formatForm(reward_json) {
    const user = id;
    return { user, reward_json };
  }

  const onSubmit = (values) => {
    values = formatForm(values);

    setSubmitting(true);
    createReward(values)
      .then((added) => onDone(added))
      .catch(() => {})
      .finally(() => setSubmitting(false));
  };

  return (
    <Form className="tag-form" onFinish={onSubmit} layout="vertical">
      <p>Message</p>
      <Form.Item name="message" rules={InputRules.required("Enter Message")}>
        <Input placeholder="Enter Message" type="text" />
      </Form.Item>
      <p>Coins</p>
      <Form.Item name="coins">
        <Input type="number" defaultValue="0" min="0" />
      </Form.Item>
      <p>XP</p>
      <Form.Item name="xp">
        <Input type="number" defaultValue="0" min="0" />
      </Form.Item>
      <p>Food</p>
      <Form.Item name="food">
        <Input type="number" defaultValue="0" min="0" />
      </Form.Item>
      <p>Wood</p>
      <Form.Item name="wood">
        <Input type="number" defaultValue="0" min="0" />
      </Form.Item>
      <p>Stone</p>
      <Form.Item name="stone">
        <Input type="number" defaultValue="0" min="0" />
      </Form.Item>
      <p>Metal</p>
      <Form.Item name="metal">
        <Input type="number" defaultValue="0" min="0" />
      </Form.Item>
      <p>User Id : {id}</p>

      <div className="form-buttons">
        <div />
        <Button
          type="primary"
          htmlType="submit"
          size="large"
          loading={submitting}
          className="wide"
          disabled={submitting}
        >
          Give Student Reward
        </Button>
      </div>
    </Form>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  createReward: (...args) => dispatch(createReward(...args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StudentRewardForm);
