import Logger from "../../data/Logger";
import Notifications from "../../util/Notifications";
import SentenceService from "../../services/SentenceService";

export const getSentence = (id) => () => new Promise(resolve => {
	(Array.isArray(id)
		? Promise.all(id.map(i => SentenceService.get(i)))
		: SentenceService.get(id))
		.then(r => resolve(r))
		.catch(() => resolve(false));
});

export const getSentenceTranslation = (id) => () => new Promise(resolve => {
	SentenceService.getTranslation(id)
		.then(r => resolve(r))
		.catch(() => resolve(false));
});

export const addSentence = (data) => () => new Promise((resolve, reject) => {
	SentenceService.add(data.word, data)
		.then(r => {
			Notifications.success("Sentence added successfully");
			resolve(r)
		})
		.catch((e) => {
			Logger.exception(e);
			reject(e);
		});
})

export const addSentenceTranslation = (id, data) => () => new Promise((resolve, reject) => {
	SentenceService.addTranslation(data)
		.then(r => {
			Notifications.success("Sentence translation added successfully");
			resolve(r)
		})
		.catch((e) => {
			Logger.exception(e);
			reject(e);
		});
})

export const updateSentence = (id, data) => () => new Promise((resolve, reject) => {
	SentenceService.update(id, data)
		.then(r => {
			Notifications.success("Updated sentence");
			resolve(r)
		})
		.catch((e) => {
			Logger.exception(e);
			reject(e);
		});
})

export const updateSentenceTranslation = (id, data) => () => new Promise((resolve, reject) => {
	SentenceService.updateTranslation(id, data)
		.then(r => {
			Notifications.success("Updated sentence translation");
			resolve(r)
		})
		.catch((e) => {
			Logger.exception(e);
			reject(e);
		});
})

export const deleteSentence = (id) => () => new Promise((resolve) => {
	Notifications.async(SentenceService.delete(id), {
		loadingMessage: `Deleting sentence ${id}`,
		successMessage: "Deleted sentence successfully",
		errorMessage: "Failed to delete the sentence",
	})
		.then(() => resolve(true))
		.catch((e) => {
			Logger.exception(e);
			resolve(false);
		});
})

export const deleteSentenceTranslation = (id) => () => new Promise((resolve) => {
	Notifications.async(SentenceService.deleteTranslation(id), {
		loadingMessage: `Deleting sentence translation ${id}`,
		successMessage: "Deleted sentence translation successfully",
		errorMessage: "Failed to delete the sentence translation",
	})
		.then(() => resolve(true))
		.catch((e) => {
			Logger.exception(e);
			resolve(false);
		});
})

export const getSentences = (filters) => () => new Promise(resolve => {
	if (typeof filters === 'string') filters = { search: filters };
	SentenceService.paginate(filters)
		.then(r => resolve(r))
		.catch(() => {
			Notifications.error("Failed to get sentences");
			resolve(false);
		})
});

export const searchSentences = (input) => () => new Promise(resolve => {
	SentenceService.search(input)
		.then(r => resolve(r?.results))
		.catch(() => {
			Notifications.error("Failed to get sentences");
			resolve(false);
		})
});

export const getSentenceTranslations = (filters) => () => new Promise(resolve => {
	SentenceService.paginateTranslations(filters)
		.then(r => resolve(r))
		.catch(() => {
			Notifications.error("Failed to get translated sentences");
			resolve(false);
		})
});
