import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, Image, Menu, Tag } from 'antd';
import { CloseCircleOutlined, DownOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Page from '../../components/layout/Page';
import Links from '../../navigation/Links';
import TableView from '../../components/TableView';
import Data from '../../data';
import { deleteWord, getWords } from '../../redux/qna/actions';


const TableColumns = ({ filters, onDelete } = {}) => [
  {
    title: 'Id',
    dataIndex: 'id',
    fixed: 'left',
  },
  {
    title: 'Question',
    dataIndex: 'question',
    ellipsis: true,
  },
  {
    title: 'Answer',
    dataIndex: 'answer',
    ellipsis: true,
  },
  {
    title: 'QnA Type',
    dataIndex: 'qna_type',
    ellipsis: true,
  },
  {
    title: 'Tags',
    dataIndex: 'tags',
    ellipsis: true,
    fixed: 'left',
    filters: filters?.tags?.map(t => ({ value: t.id, text: t.name })),
    filterMultiple: true,
    onFilter: (value, record) => !!record.tags?.find(t => t.id === value),
    render: value => value?.map(v => v.name).map((s, i) => <Tag key={i}>{s}</Tag>),
  },
  {
    title: 'Image',
    name: 'has_image',
    width: 80,
    filters: [
      {
        text: 'Has Image',
        value: true,
      },
      {
        text: 'No Image',
        value: false,
      },
    ],
    filterMultiple: false,
    onFilter: (value, record) => (value ? !!record.image : !record.image),
    render: value =>
      !value.image ? (
        <CloseCircleOutlined />
      ) : (
        <Image
          src={value.image}
          height={50}
          width={50}
          style={{ objectFit: 'contain' }}
        />
      ),
  },
  {
    title: 'Action',
    key: 'action',
    width: 160,
    fixed: 'right',
    render: word => (
      <span className="table-action-buttons">
        <Link
          className="text-button view"
          style={{ color: 'white' }}
          to={Links.Qna(word)}
        >
          View
        </Link>
        <Dropdown
          trigger={['click']}
          className="table-more-button"
          overlay={
            <Menu className="actions-menu">
              <Menu.Item className="negative delete" onClick={() => onDelete(word)}>
                Delete
              </Menu.Item>
            </Menu>
          }
        >
          <span className="text-button ant-dropdown-link">
            More <DownOutlined />
          </span>
        </Dropdown>
      </span>
    ),
  },
];

function Words({ getWords, deleteWord, history }) {
  const [bulkUploadDialog, setBulkUploadDialog] = useState(false);

  return (
    <Page title="Words">
      <TableView
        title="QnAs"
        columns={TableColumns}
        getItems={getWords}
        deleteItem={deleteWord}
        addButton={{
          text: 'Add QnA',
          link: Links.ADD_QNA,
        }}
        searchLabel="Search QnA"
        mapItem={Data.mapWord}
        parseFilters={filters => {
          let tagsList = '';
          for (let i = 0; i < filters.tags?.length; i++) {
            tagsList += `${filters.tags[i].id},`;
          }

          return {
            ...filters,
            tags: tagsList,
          };
        }}
      />

      
    </Page>
  );
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  getWords: (...args) => dispatch(getWords(...args)),
  deleteWord: (...args) => dispatch(deleteWord(...args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Words));
