import Dialog from "../../components/Dialog";
import { useState } from "react";
import TagForm from "./TagForm";

export default function TagDialog({ visible, hide, item, ...props }) {
	const [loading, setLoading] = useState(false);

	return (
		<Dialog
			title={item ? "Update Tag" : "Add Tag"}
			visible={visible} onCancel={hide} closable={!loading}
		>
			<TagForm
				{...props} id={item?.id}
				submitting={loading}
				setSubmitting={l => setLoading(l)}
				onCancel={hide}
			/>
		</Dialog>
	)
}
