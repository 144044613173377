import Page from "../../components/layout/Page";
import TableView from "../../components/TableView";
import { getSubscriptionLogs } from "../../redux/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Util from "../../util";

const TableColumns = () => [
  {
    title: "Id",
    dataIndex: "id",
  },
  {
    title: "School Name",
    dataIndex: "school_name",
    ellipsis: true,
  },
  {
    title: "Subscription Type",
    dataIndex: "subscription_type",
    ellipsis: true,
  },
  {
    title: "Seats",
    dataIndex: "seats",
    ellipsis: true,
  },
  {
    title: "Date",
    dataIndex: "log_date",
    ellipsis: true,

    render: (item) => {
      return Util.formatDate(item);
    },
  },
];

function SubscriptionLogs({ getSubscriptionLog }) {
  return (
    <Page title="Subscription Logs">
      <TableView
        title="Subscription Logs"
        columns={TableColumns}
        getItems={getSubscriptionLog}
        showTotal={true}
        addButton={false}
        isCheckable={false}
      />
    </Page>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getSubscriptionLog: (...args) => dispatch(getSubscriptionLogs(...args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SubscriptionLogs));
