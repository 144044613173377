import Dialog from "../../components/Dialog";
import { useState } from "react";
import StudentRewardForm from "./StudentRewardForm";

export default function StudentRewardDialog({ visible, hide, item, ...props }) {
  const [loading, setLoading] = useState(false);
  return (
    <Dialog
      title={"Give Student Rewards"}
      visible={visible}
      onCancel={hide}
      closable={!loading}
      >
      <StudentRewardForm
        {...props}
        id={item?.user_id}
        submitting={loading}
        setSubmitting={(l) => setLoading(l)}
        onCancel={hide}
      />
    </Dialog>
  );
}
