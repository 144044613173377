import { connect } from "react-redux";
import {
  addAdventure,
  deleteAdventure,
  getAdventure,
  updateAdventure,
} from "../../redux/actions";
import { useEffect, useRef, useState } from "react";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import PageLoader from "../../components/PageLoader";
import { Button, Form, Input, InputNumber, Radio, Select } from "antd";
import InputRules from "../../util/InputRules";
import LanguageInput from "../../components/form/LanguageInput";
import Language from "../../data/Language";
import WordsInput from "../../components/form/WordsInput";
import Data from "../../data";
const { Option } = Select;

const DYNAMIC_LANGUAGE = false;

function parseAdventure(adventure) {
  adventure.language_to = adventure.language_to?.id;
  adventure.language_from = adventure.language_from?.id;
  adventure.words = adventure.words?.map(Data.mapWord);
  return adventure;
}

function formatAdventure(form) {
  return form;
}

function AdventureForm({
  id,
  item,
  addAdventure,
  deleteAdventure,
  getAdventure,
  updateAdventure,
  onDone,
  onCancel,
  submitting,
  setSubmitting,
}) {
  const [loading, setLoading] = useState(false);
  const formRef = useRef();
  const isEdit = !!id;

  const [adventure, setAdventure] = useState(
    item ? parseAdventure(item) : null
  );

  useEffect(() => {
    if (!id || item) return;

    setLoading(true);
    getAdventure(id)
      .then((word) => (word ? setAdventure(parseAdventure(word)) : onCancel()))
      .finally(() => setLoading(false));
  }, [item, onCancel, getAdventure, id]);

  const onSubmit = (values) => {
    values = formatAdventure(values);

    if (adventure?.words === values?.words) {
      values = {
        ...values,
        words: undefined,
      };
    }

    setSubmitting(true);
    if (isEdit) {
      updateAdventure(id, values)
        .then((updated) => onDone(updated))
        .catch(() => {})
        .finally(() => setSubmitting(false));
    } else {
      addAdventure(values)
        .then((added) => onDone(added))
        .catch(() => {})
        .finally(() => setSubmitting(false));
    }
  };

  const onDelete = () => {
    ConfirmationDialog({
      title: "Are you sure you want to delete this adventure?",
      onConfirm: () => {
        deleteAdventure(id).then(() => onDone(adventure));
      },
    });
  };

  return loading ? (
    <PageLoader />
  ) : (
    <Form
      ref={formRef}
      className="adventure-form"
      onFinish={onSubmit}
      layout="vertical"
      initialValues={
        isEdit
          ? adventure
          : {
              is_public: true,
              is_paid: false,
              total_games: 4,
              language_from: Language.JAPANESE.id,
              language_to: Language.ENGLISH.id,
            }
      }
    >
      <Form.Item
        label="Name"
        name="name"
        rules={InputRules.required("Please input name")}
      >
        <Input placeholder="Adventure name" />
      </Form.Item>

      <Form.Item style={{ marginBottom: 0 }}>
        <Form.Item
          name="is_public"
          style={{
            display: "inline-block",
            width: "max-width",
            marginRight: 20,
          }}
        >
          <Radio.Group disabled={true} buttonStyle="solid">
            <Radio.Button value={true}>Public</Radio.Button>
            <Radio.Button value={false}>Private</Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name="adventure_type"
          style={{
            display: "inline-block",
            width: "max-width",
            marginRight: 20,
          }}
        >
          <Select
				defaultValue={1}
				labelField="Adventure Type" searchable={false}>
            <Option value={1}>Standard</Option>
            <Option value={2}>Campfire no audio</Option>
            <Option value={3}>Bridge game first with no audio</Option>
            </Select>
        </Form.Item>

        <Form.Item
          name="is_paid"
          style={{ display: "inline-block", width: "max-width" }}
        >
          <Radio.Group buttonStyle="solid">
            <Radio.Button value={false}>Free</Radio.Button>
            <Radio.Button value={true}>Paid</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Form.Item>

      <Form.Item style={{ marginBottom: 0 }}>
        <LanguageInput
          name="language_to"
          label="Language To"
          required={true}
          disabled={!DYNAMIC_LANGUAGE}
          style={{ display: "inline-block", width: "calc(50% - 12px)" }}
        />
        <span
          style={{
            display: "inline-block",
            width: "24px",
            lineHeight: "32px",
            textAlign: "center",
          }}
        />
        <LanguageInput
          name="language_from"
          label="Language From"
          required={true}
          disabled={!DYNAMIC_LANGUAGE}
          style={{ display: "inline-block", width: "calc(50% - 12px)" }}
        />
      </Form.Item>
      <Form.Item name="learning_aim" label="Learning Aim">
        <Select
          required="true"
          size="large"
          style={{ minWidth: 160 }}
          placeholder="Learning Aim"
          allowClear
        >
          <Select.Option value={1}>Spelling the word</Select.Option>
          <Select.Option value={2}>Writing the sentence</Select.Option>
          <Select.Option value={3}>Saying the word</Select.Option>
          <Select.Option value={4}>Say the sentence</Select.Option>
          <Select.Option value={null}>Null</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item name="total_games" label="Total games">
        <InputNumber min={1} />
      </Form.Item>

      <Form.Item
        name="words"
        label="Words (Available words)"
        rules={InputRules.arrayLength({ required: true, min: 4, max: 10 })}
      >
        <WordsInput multiple />
      </Form.Item>

      <div className="form-buttons">
        {!isEdit ? (
          <div />
        ) : (
          <Button
            type="text"
            size="large"
            loading={loading}
            onClick={onDelete}
            className="danger"
            disabled={submitting}
          >
            Delete
          </Button>
        )}
        <Button
          type="primary"
          htmlType="submit"
          size="large"
          loading={submitting}
          className="wide"
          disabled={submitting}
        >
          {isEdit ? "Update this " : "Add"} Adventure
        </Button>
      </div>
    </Form>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getAdventure: (...args) => dispatch(getAdventure(...args)),
  addAdventure: (...args) => dispatch(addAdventure(...args)),
  updateAdventure: (...args) => dispatch(updateAdventure(...args)),
  deleteAdventure: (...args) => dispatch(deleteAdventure(...args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdventureForm);
