import { http } from "../util/http";

export default class SchoolService {
	static getAll() {
		return http.get(`/schools`);
	}

	static getTeachers() {
		return http.get('/school/teachers');
	}

	static getStudents() {
		return http.get('/school/students');
	}
}
