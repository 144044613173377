import { connect } from "react-redux";
import {
	addAssignedAdventure,
	deleteAssignedAdventure,
	getAssignedAdventure,
	updateAssignedAdventure
} from "../../redux/actions";
import { useEffect, useState } from "react";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import PageLoader from "../../components/PageLoader";
import { Button, Form, InputNumber } from "antd";
import InputRules from "../../util/InputRules";
import TeachersInput from "../../components/form/TeachersInput";
import SchoolsInput from "../../components/form/SchoolsInput";
import AdventuresInput from "../../components/form/AdventuresInput";
import StudentsInput from "../../components/form/StudentsInput";
import LearningAimInput from "../../components/form/LearningAimInput";

function parseAdventure(adventure) {
	return adventure;
}

function formatAdventure(form) {
	return form;
}

function AssignedAdventureForm({
	                               id,
	                               item,
	                               getAssignedAdventure,
	                               addAssignedAdventure,
	                               updateAssignedAdventure,
	                               deleteAssignedAdventure,
	                               onDone,
	                               onCancel,
	                               submitting,
	                               setSubmitting
                               }) {
	const [loading, setLoading] = useState(false);
	const isEdit = !!id;

	const [assignedAdventure, setAssignedAdventure] = useState(item ? parseAdventure(item) : null);

	useEffect(() => {
		if (!id || item) return;

		setLoading(true);
		getAssignedAdventure(id)
			.then(word => word ? setAssignedAdventure(parseAdventure(word)) : onCancel())
			.finally(() => setLoading(false))
	}, [item, onCancel, getAssignedAdventure, id]);

	const onSubmit = (values) => {
		values = formatAdventure(values);

		setSubmitting(true);
		if (isEdit) {
			updateAssignedAdventure(id, values)
				.catch(() => {})
				.finally(() => setSubmitting(false));
		} else {
			addAssignedAdventure(values)
				.then(added => onDone(added))
				.catch(() => {})
				.finally(() => setSubmitting(false));
		}
	}

	const onDelete = () => {
		ConfirmationDialog({
			title: 'Are you sure you want to delete this assigned adventure?',
			onConfirm: () => {
				deleteAssignedAdventure(id).then(() => onDone(assignedAdventure));
			}
		});
	}

	return loading ? <PageLoader /> : (
		<Form
			className="adventure-form" onFinish={onSubmit} layout="vertical"
			initialValues={isEdit ? assignedAdventure : {
				total_games_played: 1,
				completed_games: 1
			}}
		>
			<Form.Item name="adventure" label="Adventure" rules={InputRules.required()}>
				<AdventuresInput placeholder="Select adventure" />
			</Form.Item>

			<Form.Item name="student" label="Student" rules={InputRules.required()}>
				<StudentsInput placeholder="Select students" />
			</Form.Item>

			<Form.Item name="teacher" label="Teacher" rules={InputRules.required()}>
				<TeachersInput placeholder="Select teacher" />
			</Form.Item>

			<Form.Item name="school" label="School" rules={InputRules.required()}>
				<SchoolsInput placeholder="Select school" />
			</Form.Item>

			<Form.Item name="total_games_played" label="Total games played">
				<InputNumber min={1} />
			</Form.Item>

			<Form.Item name="completed_games" label="Total games played">
				<InputNumber min={1} />
			</Form.Item>

			<Form.Item name="learning_aim" label="Learning aim">
				<LearningAimInput placeholder="Select learning aim" />
			</Form.Item>

			<div className="form-buttons">
				{!isEdit ? <div /> : (
					<Button
						type="text" size="large" loading={loading} onClick={onDelete} className="danger"
						disabled={submitting}
					>
						Delete
					</Button>
				)}
				<Button
					type="primary" htmlType="submit" size="large" loading={submitting} className="wide"
					disabled={submitting}
				>
					{isEdit ? "Update this " : "Add"} Adventure
				</Button>
			</div>
		</Form>
	)
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	getAssignedAdventure: (...args) => dispatch(getAssignedAdventure(...args)),
	addAssignedAdventure: (...args) => dispatch(addAssignedAdventure(...args)),
	updateAssignedAdventure: (...args) => dispatch(updateAssignedAdventure(...args)),
	deleteAssignedAdventure: (...args) => dispatch(deleteAssignedAdventure(...args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AssignedAdventureForm);
