import Page from "../../components/layout/Page";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Dropdown, Menu } from "antd";
import Links from "../../navigation/Links";
import { DownOutlined } from "@ant-design/icons";
import TableView from "../../components/TableView";
import { deleteAssignedAdventure, getAssignedAdventures } from "../../redux/actions";
import AssignedAdventureDialog from "./AssignedAdventureDialog";

const TableColumns = ({ onItemClick, onDelete } = {}) => [{
	title: 'Id',
	dataIndex: 'id',
	fixed: 'left',
	width: 92,
}, {
	title: 'Name',
	dataIndex: 'title',
	ellipsis: true
}, {
	title: 'Action',
	key: 'action',
	width: 130,
	fixed: 'right',
	render: (word) => (
		<span className="table-action-buttons">
	      <Link className="text-button mr-10" to={Links.Word(word)}>View</Link>
	      <Dropdown trigger={['click']} className="sidebar-dropdown" overlay={(
		      <Menu className="actions-menu">
			      <Menu.Item className="negative" onClick={() => onDelete(word)}>
				      Delete
			      </Menu.Item>
		      </Menu>
	      )}>
	          <span className="text-button ant-dropdown-link">More <DownOutlined /></span>
	      </Dropdown>
	  </span>
	)
}];

function AssignedAdventures({ getAssignedAdventures, deleteAssignedAdventure }) {
	return (
		<Page title="Assigned Adventures">
			<TableView
				title="Assigned Adventures"
				columns={TableColumns}
				getItems={getAssignedAdventures}
				deleteItem={deleteAssignedAdventure}
				addButton={{ text: "Assign Adventure" }}
				Dialog={AssignedAdventureDialog}
				getLink={s => s ? Links.AdventureAssigned(s) : Links.ADVENTURES_ASSIGNED}
			/>
		</Page>
	)
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
	getAssignedAdventures: (...args) => dispatch(getAssignedAdventures(...args)),
	deleteAssignedAdventure: (...args) => dispatch(deleteAssignedAdventure(...args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AssignedAdventures);
