import { connect } from "react-redux";
import { Button } from "antd";
import { useState } from "react";
import { PasswordDialog, AuthDialog, PlayerDataDialog } from "./Dialogs";

function Dashboard() {
  const [dialogVisible, setDialogVisible] = useState(null);
  const [authDialogVisible, setAuthDialogVisible] = useState(null);
  const [playerDataUrlVisible, setPlayerDataUrlVisible] = useState(null);

  return (
    <>
      <div className="margin-button">
        <Button
          size="large"
          type="primary"
          onClick={() => setDialogVisible(true)}
        >
          Change Users Password
        </Button>
      </div>
      <div className="margin-button">
        <Button
          size="large"
          type="primary"
          onClick={() => setPlayerDataUrlVisible(true)}
        >
          S3 Player data URL
        </Button>
      </div>
      <div className="margin-button">
        <Button
          size="large"
          type="primary"
          onClick={() => setAuthDialogVisible(true)}
        >
          Get Token
        </Button>

        <PasswordDialog
          visible={dialogVisible}
          hide={() => setDialogVisible(false)}
        />
        <AuthDialog
          visible={authDialogVisible}
          hide={() => setAuthDialogVisible(false)}
        />

        <PlayerDataDialog
          visible={playerDataUrlVisible}
          hide={() => setPlayerDataUrlVisible(false)}
        />
      </div>
    </>
  );
}

export default connect()(Dashboard);
