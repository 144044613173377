import SimpleTableView from "../../components/SimpleTableView";
import Page from "../../components/layout/Page";
import { Button, Select, Typography } from "antd";
import TagsInput from "../../components/form/TagsInput";
import { getTagAnalytics } from "../../redux/analytics/actions";
import { connect } from "react-redux";
import Util from "../../util";
import { withRouter } from "react-router";
import { generateYears } from "../../util/FormData";

const TableColumns = () => [
  {
    title: "Word",
    dataIndex: "translation",
    fixed: "left",
  },
  {
    title: "Read Count",
    dataIndex: "read_count",
    width: 130,
  },
];

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const years = generateYears();

function Analytics({ getTagAnalytics }) {
  return (
    <Page title="Tags Analytics">
      <SimpleTableView
        title="Tags Analytics"
        columns={TableColumns}
        getItems={getTagAnalytics}
        renderFilters={(values, onChange, onSearch) => (
          <>
            <Typography variant="h4" component="h4">
              Get all the word count analytics
            </Typography>
            <Select
              labelInValue
              size="large"
              style={{ minWidth: 160 }}
              placeholder="for"
              value={
                values.is_exclude === undefined
                  ? undefined
                  : { value: values.is_exclude }
              }
              onChange={(s) => onChange({ is_exclude: s?.value })}
              allowClear
            >
              <Select.Option value={false}>for</Select.Option>
              <Select.Option value={true}>other than</Select.Option>
            </Select>
            <TagsInput
              value={values.tags}
              onChange={(tags) => onChange({ tags })}
            />
            <Typography variant="h4" component="h4">
              from
            </Typography>
            <Select
              labelInValue
              size="large"
              style={{ minWidth: 160 }}
              placeholder="Month"
              value={
                values.month === undefined ? undefined : { value: values.month }
              }
              onChange={(s) => onChange({ month: s?.value })}
              allowClear
            >
              {months.map((value, index) => {
                return <Select.Option value={index + 1}>{value}</Select.Option>;
              })}
            </Select>
            <Select
              labelInValue
              size="large"
              style={{ minWidth: 160 }}
              placeholder="Year"
              value={
                values.year === undefined ? undefined : { value: values.year }
              }
              onChange={(s) => onChange({ year: s?.value })}
              allowClear
            >
              {years.map((value, index) => {
                return <Select.Option value={value}>{value}</Select.Option>;
              })}
            </Select>
            <Button
              size="large"
              style={{ marginLeft: 10 }}
              onClick={onSearch}
              disabled={Util.isEmpty(values)}
            >
              Get Analytics
            </Button>
          </>
        )}
        parseFilters={(filters) => {
          let tagsList = "";
          for (let i = 0; i < filters.tags?.length; i++) {
            tagsList += filters.tags[i].id + ",";
          }

          return {
            ...filters,
            tags: tagsList,
          };
        }}
      />
    </Page>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getTagAnalytics: (...args) => dispatch(getTagAnalytics(...args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Analytics));
