import { connect } from "react-redux";
import { useEffect, useRef, useState } from "react";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import PageLoader from "../../components/PageLoader";
import { Button, Form, Input, InputNumber, Radio } from "antd";
import InputRules from "../../util/InputRules";
import Language from "../../data/Language";
import {
  addSubscription,
  deleteSubscription,
  updateSubscription,
  getSubscription,
  getSchoolOwner,
} from "../../redux/subscription/actions";
import moment from "moment";
import SchoolInput from "../../components/form/SchoolInput";
import { getFormattedDate } from "../../util/FormData";
import { getParsedDate } from "../../util/FormData";

function parseSubscription(subscription) {
  subscription.start_date = getParsedDate(subscription.start_date);
  subscription.validity_date = getParsedDate(subscription.validity_date);
  return subscription;
}

function formatSubscription(form) {
  form.start_date = getFormattedDate(form.start_date);
  form.validity_date = getFormattedDate(form.validity_date);

  return form;
}

function SubscriptionForm({
  id,
  item,
  addSubscription,
  updateSubscription,
  getSubscription,
  getSchoolOwner,
  deleteSubscription,
  onDone,
  onCancel,
  submitting,
  setSubmitting,
}) {
  const today = moment();
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  const formRef = useRef();
  const isEdit = !!id;

  const [subscription, setSubscription] = useState(
    item ? parseSubscription(item) : null
  );

  useEffect(() => {
    if (!id || item) return;

    setLoading(true);
    getSubscription(id)
      .then((subscription) =>
        subscription
          ? setSubscription(parseSubscription(subscription))
          : onCancel()
      )
      .finally(() => setLoading(false));
  }, [item, onCancel, getSubscription, id]);

  function onSubmit(values) {
    getSchoolOwner(values.school_name)
      .then((schoolOwner) => {
        values.user = schoolOwner;
        values = formatSubscription(values);

        setSubmitting(true);
        if (isEdit) {
          updateSubscription(id, values)
            .then((updated) => onDone(updated))
            .catch(() => {})
            .finally(() => setSubmitting(false));
        } else {
          addSubscription(values)
            .then((added) => onDone(added))
            .catch(() => {})
            .finally(() => setSubmitting(false));
        }
      })
      .finally(() => setLoading(false));
  }

  const onDelete = () => {
    ConfirmationDialog({
      title: "Are you sure you want to delete this subscription?",
      onConfirm: () => {
        deleteSubscription(id).then(() => onDone(subscription));
      },
    });
  };

  return loading ? (
    <PageLoader />
  ) : (
    <Form
      ref={formRef}
      className="adventure-form"
      onFinish={onSubmit}
      layout="vertical"
      initialValues={
        isEdit
          ? subscription
          : {
              is_public: true,
              is_paid: false,
              total_games: 4,
              language_from: Language.ENGLISH.id,
              language_to: Language.JAPANESE.id,
            }
      }
    >
      <Form.Item
        label="School Name"
        name="school_name"
        rules={InputRules.required("Please input school name")}
      >
        <SchoolInput placeholder="Select school name" />
      </Form.Item>

      <Form.Item style={{ marginBottom: 0 }}>
        <Form.Item
          label="Start Date"
          name="start_date"
          style={{
            display: "inline-block",
            width: "max-width",
            marginRight: 20,
          }}
        >
          <Input
            type="date"
            value={startDate}
            onChange={(date) => setStartDate(date)}
          />
        </Form.Item>

        <span
          style={{
            display: "inline-block",
            width: "24px",
            lineHeight: "32px",
            textAlign: "center",
          }}
        />
        <Form.Item
          label="End Date"
          name="validity_date"
          style={{
            display: "inline-block",
            width: "max-width",
          }}
        >
          <Input
            type="date"
            value={endDate}
            onChange={(date) => setEndDate(date)}
          />
        </Form.Item>
      </Form.Item>
      <Form.Item style={{ marginBottom: 0 }}>
        <Form.Item
          label="Subscription Type"
          name="subscription_type"
          style={{
            display: "inline-block",
            width: "max-width",
            marginRight: 20,
          }}
        >
          <Radio.Group buttonStyle="solid">
            <Radio.Button value={1}>Gamerize Basic</Radio.Button>
            <Radio.Button value={2}>Gamerize Pro</Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          label="Payment type"
          name="payment_type"
          style={{ display: "inline-block", width: "max-width" }}
        >
          <Radio.Group buttonStyle="solid">
            <Radio.Button value={1}>Stripe</Radio.Button>
            <Radio.Button value={2}>Bank Transfer</Radio.Button>
            <Radio.Button value={3}>Trial Period</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Form.Item>

      <Form.Item name="seats" label="Seats">
        <InputNumber min={1} />
      </Form.Item>

      <Form.Item
        label="API Key Type"
        name="api_key_type"
        style={{ display: "inline-block", width: "max-width" }}
      >
        <Radio.Group buttonStyle="solid">
          <Radio.Button value={1}>Per User</Radio.Button>
          <Radio.Button value={2}>Per Request</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <div className="form-buttons">
        {!isEdit ? (
          <div />
        ) : (
          <Button
            type="text"
            size="large"
            loading={loading}
            onClick={onDelete}
            className="danger"
            disabled={submitting}
          >
            Delete
          </Button>
        )}
        <Button
          type="primary"
          htmlType="submit"
          size="large"
          loading={submitting}
          className="wide"
          disabled={submitting}
        >
          {isEdit ? "Update this " : "Add"} Subscripton
        </Button>
      </div>
    </Form>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getSubscription: (...args) => dispatch(getSubscription(...args)),
  addSubscription: (...args) => dispatch(addSubscription(...args)),
  updateSubscription: (...args) => dispatch(updateSubscription(...args)),
  getSchoolOwner: (...args) => dispatch(getSchoolOwner(...args)),
  deleteSubscription: (...args) => dispatch(deleteSubscription(...args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionForm);
