import AsyncSelect from "./AsyncSelect";
import { connect } from "react-redux";
import { searchSchool } from "../../redux/subscription/actions";

function SchoolInput({
  searchSchools,
  getTag,
  mode,
  allowNew,
  placeholder,
  ...props
}) {
  return (
    <AsyncSelect
      {...props}
      multiple={false}
      getItems={searchSchools}
      labelField="school"
      valueField="school"
      placeholder={placeholder || "Search school"}
      allowNew={false}
    />
  );
}

const mapDispatchToProps = (dispatch) => ({
  searchSchools: (...args) => dispatch(searchSchool(...args)),
});

export default connect(null, mapDispatchToProps)(SchoolInput);
