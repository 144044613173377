import React from 'react';
import Sidebar from '../Sidebar';
import { Layout } from "antd";
import Navbar from "../Navbar";

function AuthLayout(props) {
	return (
		<Layout className="app-layout">
			<Sidebar />
			<Layout>
				<Navbar />
				<Layout.Content>
					{props.children}
				</Layout.Content>
			</Layout>
		</Layout>
	);
}

export default AuthLayout;
