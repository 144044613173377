import SchoolService from "../../services/SchoolService";

export const getSchools = () => () => new Promise(resolve => {
	SchoolService.getAll()
		.then(r => resolve(r))
		.catch(() => resolve(null));
});

export const getTeachers = () => () => new Promise(resolve => {
	SchoolService.getTeachers()
		.then(r => resolve(r))
		.catch(() => resolve(null));
});

export const getStudents = () => () => new Promise(resolve => {
	SchoolService.getStudents()
		.then(r => resolve(r))
		.catch(() => resolve(null));
});
