import Logger from "../../data/Logger";
import Notifications from "../../util/Notifications";
import WordService from "../../services/WordService";
import flatten from "lodash/flatten";
import Util from "../../util";
import SentenceService from "../../services/SentenceService";

export const getWord = (id) => () =>
  new Promise((resolve) => {
    (Array.isArray(id)
      ? Promise.all(id.map((i) => WordService.get(i)))
      : WordService.get(id)
    )
      .then((r) => resolve(r))
      .catch(() => resolve(false));
  });

export const getNextPage = (next) => () =>
  new Promise((resolve) => {
    WordService.getNextPage(next)
      .then((r) => resolve(r))
      .catch(() => resolve(false));
  });

export const getWordTranslation = (id) => () =>
  new Promise((resolve) => {
    WordService.getTranslation(id)
      .then((r) => resolve(r))
      .catch(() => resolve(false));
  });

export const addWord = (data) => () =>
  new Promise((resolve, reject) => {
    const word = Util.removeBy(
      {
        image: data.image,
        tags: data.tags,
      },
      "@DELETE@"
    );

    const createWordTranslations = (wordId) =>
      data.translations?.length
        ? Promise.all(
            data.translations.map((t) => WordService.addTranslation(wordId, t))
          )
        : Promise.resolve();

    const createSentences = (wordId) =>
      data.sentences?.length
        ? Promise.all(
            data.sentences.map((sentence) =>
              SentenceService.add(wordId, { image: sentence.image }).then(
                (added) =>
                  sentence.translations?.length &&
                  Promise.all(
                    sentence.translations.map((s) =>
                      SentenceService.addTranslation(added.id, s)
                    )
                  )
              )
            )
          )
        : Promise.resolve();

    WordService.add(word)
      .then(async (added) => {
        const result = await Promise.all([
          createWordTranslations(added.id),
          createSentences(added.id),
        ]);

        return { word: added, result };
      })
      .then((r) => {
        Notifications.success("Word added successfully");
        resolve(r?.word);
      })
      .catch((e) => {
        Logger.exception(e);
        reject(e);
      });
  });

export const addWordTranslation = (id, data) => () =>
  new Promise((resolve, reject) => {
    WordService.addTranslation(data)
      .then((r) => {
        Notifications.success("Word translation added successfully");
        resolve(r);
      })
      .catch((e) => {
        Logger.exception(e);
        reject(e);
      });
  });

export const updateWord = (id, data) => () =>
  new Promise((resolve, reject) => {
    const word = Util.removeBy(
      {
        id,
        image: typeof data.image === "string" ? "@DELETE@" : data.image,
        tags: data.tags,
      },
      "@DELETE@"
    );

    const wordTranslations = data.translations?.map((t) =>
      Util.removeBy(
        {
          ...t,
          audio: typeof t.audio === "string" ? "@DELETE@" : t.audio,
        },
        "@DELETE@"
      )
    );

    const existingSentences = data.sentences.filter((s) => !!s.id);
    const sentences = existingSentences?.map((s) =>
      Util.removeBy(
        {
          id: s.id,
          word: id,
          image: typeof s.image === "string" ? "@DELETE@" : s.image,
        },
        "@DELETE@"
      )
    );

    const newSentences = data.sentences.filter((s) => !s.id);

    const sentenceTranslations =
      existingSentences?.length &&
      flatten(
        existingSentences.map((s) =>
          s.translations.map((t) =>
            Util.removeBy(
              {
                ...t,
                sentence: s.id,
                audio: typeof t.audio === "string" ? "@DELETE@" : t.audio,
              },
              "@DELETE@"
            )
          )
        )
      );

    Promise.all(
      [
        WordService.update(id, word),
        wordTranslations?.length
          ? Promise.all(
              wordTranslations.map((t) =>
                t.id
                  ? WordService.updateTranslation(t.id, t)
                  : WordService.addTranslation(id, t)
              )
            )
          : Promise.resolve(),
        sentences?.length &&
          Promise.all(sentences.map((s) => SentenceService.update(s.id, s))),
        newSentences?.length &&
          Promise.all(newSentences.map((s) => SentenceService.add(id, s))),
        sentenceTranslations && sentenceTranslations?.length
          ? Promise.all(
              sentenceTranslations.map((t) =>
                t.id
                  ? SentenceService.updateTranslation(t.id, t)
                  : SentenceService.addTranslation(t.sentence, t)
              )
            )
          : Promise.resolve(),
      ].filter(Boolean)
    )
      .then((r) => {
        Notifications.success("Updated word");
        resolve(r);
      })
      .catch((e) => {
        Logger.exception(e);
        reject(e);
      });
  });

export const updateWordTranslation = (id, data) => () =>
  new Promise((resolve, reject) => {
    WordService.updateTranslation(id, data)
      .then((r) => {
        Notifications.success("Updated word translation");
        resolve(r);
      })
      .catch((e) => {
        Logger.exception(e);
        reject(e);
      });
  });

export const deleteWord = (id) => () =>
  new Promise((resolve) => {
    Notifications.async(WordService.delete(id), {
      loadingMessage: `Deleting word ${id}`,
      successMessage: "Deleted word successfully",
      errorMessage: "Failed to delete the word",
    })
      .then(() => resolve(true))
      .catch((e) => {
        Logger.exception(e);
        resolve(false);
      });
  });

  export const cloneWord = (id) => () =>
  new Promise((resolve) => {
    Notifications.message(`Cloning word ${id}`)
    WordService.clone(id).then((response)=>{
        Notifications.success("Word cloned Successfully")
        resolve(response);
    }).catch((error)=>{
        Notifications.error("Failed to clone the word")
        Logger.exception(error);
        resolve(false);
    });
  });

export const deleteWordTranslation = (id) => () =>
  new Promise((resolve) => {
    Notifications.async(WordService.deleteTranslation(id), {
      loadingMessage: `Deleting word translation ${id}`,
      successMessage: "Deleted word translation successfully",
      errorMessage: "Failed to delete the word translation",
    })
      .then(() => resolve(true))
      .catch((e) => {
        Logger.exception(e);
        resolve(false);
      });
  });

export const getWords = (filters) => () =>
  new Promise((resolve) => {
    if (typeof filters === "string") filters = { search: filters };
    WordService.paginate(filters)
      .then((r) => resolve(r))
      .catch(() => {
        Notifications.error("Failed to get words");
        resolve(false);
      });

  });

export const searchWords = (input, filters) => () =>
  new Promise((resolve) => {
    WordService.search({ search: input, ...(filters || {}) })
      .then((r) => resolve(r))
      .catch(() => {
        Notifications.error("Failed to get words");
        resolve(false);
      });
  });

export const getWordTranslations = (filters) => () =>
  new Promise((resolve) => {
    WordService.paginateTranslations(filters)
      .then((r) => resolve(r))
      .catch(() => {
        Notifications.error("Failed to get translated words");
        resolve(false);
      });
  });

export const bulkWordUpload = (file) => () =>
  new Promise((resolve, reject) => {
    WordService.bulkUpload(file)
      .then(() => resolve(true))
      .catch((e) => {
        Logger.exception(e);
        reject(e);
      });
  });
