import { http } from "../util/http";
import Util from "../util";

export default class QnaService {
  static paginate(filters) {
    return http.get(
      `/qnas/data-entry/qna-activity/?${Util.toQuery(filters)}`
    );
  }

  static search(input) {
    return http.get(`/qnas/data-entry/qna-activity/?search=${Util.toQuery(input)}`);
  }

  static get(id) {
    return http.get(`/qnas/data-entry/qna-activity/${id}/`);
  }

  static getNextPage(next) {
    return http.get(`/qnas/data-entry/qna-activity/?${next}`);
  }

  static add(adventure) {
    return http.post("/qnas/data-entry/qna-activity/", adventure);
  }

  static update(id, data) {
    return http.patch(`/qnas/data-entry/qna-activity/${id}/`, data);
  }

  static delete(id) {
    return http.delete(`/qnas/data-entry/qna-activity/${id}/`);
  }
}
