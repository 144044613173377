function NumberValidator(rule, value) {
  let min = rule.min;
  let max = rule.max;
  const message = rule.message;
  if (min !== undefined) min = Number(min);
  if (max !== undefined) max = Number(max);
  try {
    const object = Number(value);
    if (min !== undefined && object < min) throw new Error(message);
    if (max !== undefined && object > max) throw new Error(message);
    if (isNaN(object)) throw new Error(message);
    return Promise.resolve();
  } catch (err) {
    return Promise.reject(err);
  }
}

function ArrayLengthValidator(rule, value) {
  let min = rule.min;
  let max = rule.max;
  let required = rule.required;
  const message = rule.message;
  if (min !== undefined) min = Number(min);
  if (max !== undefined) max = Number(max);
  try {
    const object = value?.length;
    if (!object && required) throw new Error("Required");
    if (min !== undefined && object < min) throw new Error(message);
    if (max !== undefined && object > max) throw new Error(message);
    if (isNaN(object) && message) throw new Error(message);
    return Promise.resolve();
  } catch (err) {
    return Promise.reject(err);
  }
}

const InputRules = {
  required: (msg) => [{ required: true, message: msg || "Required" }],
  username: [{ required: true, message: "Please enter your username." }],
  password: [{ required: true, message: "Please enter your password!" }],
  number: ({ required, message, min, max }) =>
    [
      required && InputRules.required(message),
      {
        min,
        max,
        message: max
          ? `Enter valid value (${min}-${max})`
          : `Value should be more than ${min}`,
        validator: NumberValidator,
      },
    ].filter(Boolean),
  arrayLength: ({ required, message, min, max }) => [
    {
      min,
      required,
      max,
      message: `Select between ${min} to  ${max} words`,
      validator: ArrayLengthValidator,
    },
  ],
};

export default InputRules;
