import Dialog from "../../components/Dialog";
import { Descriptions } from "antd";
import Util from "../../util";
import { getUnityLogURL } from "../../redux/actions";

export default function UnityLogDialog({ visible, hide, item, ...props }) {
  const downloadLogs = () => {
    getUnityLogURL(item?.id).then((downloadUrl) => {
      window.open(downloadUrl?.url);
    });
  };

  return (
    <Dialog title={"View Unity Logs"} visible={visible} onCancel={hide}>
      <Descriptions title="User Info">
        <Descriptions.Item label="Log ID">{item?.id}</Descriptions.Item>
        <Descriptions.Item label="UserName">
          {item?.user_details?.username}
        </Descriptions.Item>
        <Descriptions.Item label="Name">
          {item?.user_details?.firstName} {item?.user_details?.firstName}
        </Descriptions.Item>
        <Descriptions.Item label="Date">
          {Util.formatDate(item?.date_created)}
        </Descriptions.Item>
        <Descriptions.Item label="OS Version">
          {item?.os_version}
        </Descriptions.Item>
        <Descriptions.Item label="Device Details">
          {item?.device_details}
        </Descriptions.Item>
        <Descriptions.Item label="Message">{item?.message}</Descriptions.Item>
      </Descriptions>
      <button
        type="primary"
        size="large"
        className="wide"
        onClick={downloadLogs}
      >
        Download Logs
      </button>
    </Dialog>
  );
}
