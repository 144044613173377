import { http } from "../util/http";
import Util from "../util";

export default class PlayerDataBackupService {
  static paginate(filters) {
    return http.get(`/users/admin/player_data/?${Util.toQuery(filters)}`);
  }

  static async search(input) {
    return http.get(`/users/admin/player_data/?search=${input}`);
  }

  static get(id) {
    return http.get(`/users/admin/player_data/${id}/`);
  }

  static getBackups() {
    return http.get("/users/admin/player_data/");
  }

  static getBackup(id) {
    return http.get(`/users/admin/player_data/${id}`);
  }
}
