import { useState } from 'react';
import Dialog from '../../components/Dialog';
import BundleForm from './BundleForm';

export default function BundleDialog({ visible, hide, item, preloaded, ...props }) {
  const [loading, setLoading] = useState(false);

  return (
    <Dialog
      title={item ? 'Update Activity Bundle' : 'Create Activity Bundle'}
      visible={visible}
      onCancel={hide}
      closable={!loading}
    >
      <BundleForm
        {...props}
        id={item?.id}
        item={preloaded ? item : undefined}
        submitting={loading}
        setSubmitting={l => setLoading(l)}
        onCancel={hide}
      />
    </Dialog>
  );
}
