import { Input } from "antd";
import { useEffect, useState } from "react";
import ReactAudioPlayer from "react-audio-player";
import Notifications from "../../util/Notifications";

export default function AudioInput({ value, onChange, multiple, disabled }) {
  // eslint-disable-next-line no-unused-vars
  const [audio, setAudio] = useState(null);
  const [source] = useState(typeof value === "string" ? value : null);

  useEffect(() => typeof value !== "string" && setAudio(value), [value]);

  const _onChange = (e) => {
    const file = multiple
      ? e.target.files
      : e.target.files?.length
      ? e.target.files[0]
      : null;
      
    if (file == null) return;

    const isLtM = file?.size / 1024 / 1024 < 0.8;
    if (!isLtM) {
      Notifications.error("Sound file must smaller than 800 KB!");
    }
    if (isLtM) {
      setAudio(file);
      onChange(file);
    }
  };

  return (
    <div>
      <Input
        onChange={_onChange}
        type="file"
        accept="audio/*"
        multiple={multiple}
        disabled={disabled}
      />
      {source && (
        <ReactAudioPlayer src={source} autoPlay={false} controls={true} />
      )}
    </div>
  );
}
