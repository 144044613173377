import { connect } from "react-redux";
import { bulkWordUpload } from "../../redux/actions";
import { useState } from "react";
import { Button, Form, Input } from "antd";
import Notifications from "../../util/Notifications";
import Util from "../../util";

const MULTIPLE = false;

function BulkWordsForm({
	                       id,
	                       bulkWordUpload,
	                       onDone,
	                       submitting,
	                       setSubmitting
                       }) {
	const [file, setFile] = useState(null);

	const onSubmit = () => {
		if (!file) return;

		setSubmitting(true);
		bulkWordUpload(file)
			.then(added => {
				Notifications.success("Words added successfully.")
				onDone(added);
			})
			.catch(e => Notifications.error(`Error ${Util.getResponseStatus(e)}: Failed to upload file.`))
			.finally(() => setSubmitting(false));
	}

	const _onChange = (e) => {
		const files = MULTIPLE ? e.target.files : e.target.files?.length ? e.target.files[0] : null;
		setFile(files);
	}

	return (
		<Form className="bulk-word-upload">
			<p>Choose a CSV file to upload.</p>
			<Input
				onChange={_onChange}
				type="file"
				accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
				multiple={MULTIPLE}
			/>

			<div className="form-buttons" style={{ marginTop: 40 }}>
				<div />
				<Button
					type="primary" size="large" loading={submitting} className="wide"
					disabled={submitting || !file} onClick={onSubmit}
				>
					Upload
				</Button>
			</div>
		</Form>
	)
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	bulkWordUpload: (...args) => dispatch(bulkWordUpload(...args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BulkWordsForm);
