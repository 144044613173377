import Page from "../../components/layout/Page";
import { connect } from "react-redux";
import { Dropdown, Menu } from "antd";
import Links from "../../navigation/Links";
import { DownOutlined } from "@ant-design/icons";
import TableView from "../../components/TableView";
import { deleteWordTranslation, getWordTranslations } from "../../redux/actions";
import WordTranslationDialog from "./WordTranslationDialog";
import Language from "../../data/Language";

const TableColumns = ({ onItemClick, onDelete } = {}) => [{
	title: 'Word',
	dataIndex: 'word',
	fixed: 'left',
	width: 92,
	sorter: (a, b) => b - a,
}, {
	title: 'Translation',
	dataIndex: 'translation',
	fixed: 'left',
}, {
	title: 'Language',
	fixed: 'left',
	render: (item) => Object.values(Language).find(l => l.id === item.language)?.name,
}, {
	title: 'Action',
	key: 'action',
	width: 130,
	fixed: 'right',
	render: (word) => (
		<span className="table-action-buttons">
	      <div className="text-button mr-10" onClick={() => onItemClick(word)}>View</div>
	      <Dropdown trigger={['click']} className="table-more-button" overlay={(
		      <Menu className="actions-menu">
			      <Menu.Item className="negative" onClick={() => onDelete(word)}>
				      Delete
			      </Menu.Item>
		      </Menu>
	      )}>
	          <span className="text-button ant-dropdown-link">More <DownOutlined /></span>
	      </Dropdown>
	  </span>
	)
}];

function WordTranslations({ getWordTranslations, deleteWordTranslation }) {
	return (
		<Page title="Word Translations">
			<TableView
				title="Word Translations"
				columns={TableColumns}
				getItems={getWordTranslations}
				deleteItem={deleteWordTranslation}
				addButton={{ text: "Add Word Translation" }}
				Dialog={WordTranslationDialog}
				getLink={s => s ? Links.WordTranslation(s) : Links.WORD_TRANSLATIONS}
			/>
		</Page>
	)
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
	getWordTranslations: (...args) => dispatch(getWordTranslations(...args)),
	deleteWordTranslation: (...args) => dispatch(deleteWordTranslation(...args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WordTranslations);
