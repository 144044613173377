import { applyMiddleware, createStore } from 'redux';
import reducers from './reducers';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import Constants from '../util/Constants';

const middlewares = [thunk];

if (!Constants.IS_PRODUCTION) {
	middlewares.push(
		createLogger({
			duration: false,
			timestamp: true,
			level: Constants.IS_PRODUCTION ? 'error' : 'info',
			diff: !Constants.IS_PRODUCTION,
			collapsed: (getState, action, logEntry) => !logEntry.error,
			actionTransformer: (action) => {
				if (action.type === 'BATCHING_REDUCER.BATCH') {
					action.payload.type = action.payload.map((next) => next.type).join(' => ');
					return action.payload;
				}

				return action;
			},
		})
	);
}

const persistConfig = {
	key: 'root',
	storage,
	whitelist: ['Auth', 'Profile'],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = createStore(persistedReducer, {}, applyMiddleware(...middlewares));
const persistor = persistStore(store);

export { store, persistor };
