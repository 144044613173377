import Data from "../../data";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { logoutUser } from "../../redux/auth/actions";
import { Avatar, Dropdown, Layout, Menu } from "antd";
import { UserOutlined } from '@ant-design/icons';

function Navbar({ user, logoutUser, history }) {
	const renderUser = () => (
		<Dropdown
			className="profile-dropdown"
			placement="bottomRight"
			trigger={['click']}
			overlay={
				<Menu>
					<Menu.Item danger onClick={() => logoutUser(history)}>
						Logout
					</Menu.Item>
				</Menu>
			}
		>
			<div className="valigned clickable">
				<div
					className="body-small semibold heading-font"
					style={{ marginLeft: 10, marginRight: 10 }}
				>
					{user.name || user.firstName ? `${user.firstName} ${user.lastName}` : ''}
				</div>
				<Avatar
					src={user.image} icon={<UserOutlined />} className="cursor-pointer"
					size={{ xs: 24, sm: 32, md: 40, lg: 40, xl: 40, xxl: 40 }}
				/>
			</div>
		</Dropdown>
	)

	return (
		<Layout.Header>
			<div />
			{renderUser()}
		</Layout.Header>
	)
}

const mapStatetoProps = (state) => ({
	user: Data.getUser(state),
});

const mapDispatchToProps = (dispatch) => ({
	logoutUser: (...args) => dispatch(logoutUser(...args))
});

export default connect(mapStatetoProps, mapDispatchToProps)(withRouter(Navbar));
