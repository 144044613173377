import { connect } from "react-redux";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import Notifications from "../../util/Notifications";
import InformationDialog from "../../components/InformationDialog";
import { Button, Form, Input } from "antd";
import InputRules from "../../util/InputRules";
import { http } from "../../util/http";
import ReactJson from "react-json-view";

function ReportForm() {
  const onSubmit = async (values) => {
    const username = values.username;
    const new_password = values.new_password;
    ConfirmationDialog({
      title: "Are you sure you want to change the password?",
      onConfirm: async () => {
        try {
          const response = await http.post("admin/changepassword/", {
            username,
            new_password,
          });
          if (response) {
            Notifications.success("Password Changed Successfully");
          }
        } catch (e) {
          const JsonResponse = JSON.parse(e.request.responseText);
          InformationDialog({
            title: "Oops! Error",
            message: <ReactJson src={JsonResponse} />,
          });
        }
      },
    });
  };

  return (
    <Form className="tag-form" onFinish={onSubmit} layout="vertical">
      <Form.Item name="username" rules={InputRules.required("Enter Name")}>
        <Input placeholder="Enter User Name" />
      </Form.Item>
      <Form.Item
        name="new_password"
        rules={InputRules.required("Enter Password")}
      >
        <Input placeholder="Enter Passowrd" />
      </Form.Item>

      <div className="form-buttons">
        <div />
        <Button type="primary" htmlType="submit" size="large" className="wide">
          Change Password
        </Button>
      </div>
    </Form>
  );
}

export default connect()(ReportForm);
