import Page from "../../components/layout/Page";
import { connect } from "react-redux";
import { Tag, Image, Select, Button } from "antd";
import InformationDialog from "../../components/InformationDialog";
import Notifications from "../../util/Notifications";
import { CloseCircleOutlined } from "@ant-design/icons";
import Util from "../../util";
import {
  getTransferRequests,
  approveTransferRequest,
  rejectTransferRequest,
} from "../../redux/bank-transfer/actions";
import SimpleTableView from "../../components/SimpleTableView";
import { useState } from "react";

function BankTransfer({
  getTransferRequests,
  approveTransferRequest,
  rejectTransferRequest,
}) {
  const [refresh, setRefresh] = useState(0);

  const TableColumns = ({ onItemClick, onDelete } = {}) => [
    {
      title: "School Name",
      dataIndex: "school_name",
      ellipsis: true,
    },
    {
      title: "Amount",
      name: "amount",
      dataIndex: "amount",
      render: (value) => (
        <Tag>
          <span>&#165;</span>
          {value}
        </Tag>
      ),
    },
    {
      title: "Subscription Type",
      dataIndex: "subscription_type",
      render: (value) => (
        <Tag>{value === "Gamerize Pro" ? "Pro" : "Basic"}</Tag>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Transaction Proof",
      name: "transaction_proof",
      render: (value) =>
        !value.transaction_proof ? (
          <CloseCircleOutlined />
        ) : (
          <Image
            src={value.transaction_proof}
            height={100}
            width={100}
            style={{ objectFit: "contain" }}
          />
        ),
    },
    {
      title: "Action",
      key: "action",
      render: (request) =>
        request.status == "Pending" || request.status == "Funds Transferred" ? (
          <span className="table-action-buttons">
            <div
              className="text-button mr-10"
              onClick={() => {
                approveTransferRequest(request.id).then((r) => {
                  console.log(r);
                  if (
                    r["message"] == "Already subscribed with different package"
                  ) {
                    InformationDialog({
                      title: "Please update Customer Subscription",
                      message:
                        "The school is already subscribed & the new request does not match with the old subscription. The system is confused. Please update customer subscirption.",
                    });
                  } else {
                    Notifications.success("Request Approved");
                  }
                  setRefresh(refresh + 1);
                });
              }}
            >
              Accept
            </div>
            <div
              className="text-button mr-10"
              onClick={() => {
                rejectTransferRequest(request.id).then((r) => {
                  setRefresh(refresh + 1);
                });
              }}
            >
              Reject
            </div>
          </span>
        ) : (
          <div></div>
        ),
    },
  ];

  return (
    <Page title="Bank Transfer Requests">
      <SimpleTableView
        title="Bank Transfer Requests"
        columns={TableColumns}
        getItems={getTransferRequests}
        refresh={refresh}
        isAnalyticTable={false}
        isSearchable={true}
        deleteItem={rejectTransferRequest}
        searchLabel="Search School Name"
        renderFilters={(values, onChange, onSearch) => (
          <>
            <Select
              labelInValue
              size="large"
              style={{ minWidth: 160 }}
              placeholder="Status"
              value={
                values.status === undefined
                  ? { value: 2 }
                  : { value: values.status }
              }
              onChange={(s) => onChange({ status: s?.value })}
              allowClear
            >
              <Select.Option value={1}>Pending</Select.Option>
              <Select.Option value={2}>Funds Transferred</Select.Option>
              <Select.Option value={3}>Approved</Select.Option>
              <Select.Option value={4}>Rejected</Select.Option>
            </Select>
            <Select
              labelInValue
              size="large"
              style={{ minWidth: 160 }}
              placeholder="Order"
              value={
                values.ordering === undefined
                  ? undefined
                  : { value: values.ordering }
              }
              onChange={(s) => onChange({ ordering: s?.value })}
              allowClear
            >
              <Select.Option value={"id"}>Ascending</Select.Option>
              <Select.Option value={"-id"}>Descending</Select.Option>
            </Select>

            <Button
              size="large"
              style={{ marginLeft: 10 }}
              onClick={onSearch}
              disabled={Util.isEmpty(values)}
            >
              Filter
            </Button>
          </>
        )}
        parseFilters={(filters) => ({
          ...filters,
          tags: filters.tags?.map((t) => t.id),
        })}
      />
    </Page>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getTransferRequests: (...args) => dispatch(getTransferRequests(...args)),
  approveTransferRequest: (...args) =>
    dispatch(approveTransferRequest(...args)),
  rejectTransferRequest: (...args) => dispatch(rejectTransferRequest(...args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BankTransfer);
