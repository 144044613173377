import { connect } from "react-redux";
import {
	addSentenceTranslation,
	deleteSentenceTranslation,
	getSentenceTranslation,
	updateSentenceTranslation
} from "../../redux/actions";
import { useEffect, useState } from "react";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import PageLoader from "../../components/PageLoader";
import { Button, Form, Input } from "antd";
import InputRules from "../../util/InputRules";
import AudioInput from "../../components/form/AudioInput";
import LanguageInput from "../../components/form/LanguageInput";
import SentencesInput from "../../components/form/SentencesInput";

function parseSentence(sentence) {
	return sentence;
}

function formatSentence(form) {
	return form;
}

function SentenceTranslationForm({
	                                 id,
	                                 item,
	                                 addSentenceTranslation,
	                                 deleteSentenceTranslation,
	                                 getSentenceTranslation,
	                                 updateSentenceTranslation,
	                                 onDone,
	                                 onCancel,
	                                 submitting,
	                                 setSubmitting
                                 }) {
	const [loading, setLoading] = useState(false);
	const isEdit = !!id;

	const [sentence, setSentence] = useState(item ? parseSentence(item) : null);

	useEffect(() => {
		if (!id || item) return;

		setLoading(true);
		getSentenceTranslation(id)
			.then(data => data ? setSentence(parseSentence(data)) : onCancel())
			.finally(() => setLoading(false))
	}, [item, onCancel, getSentenceTranslation, id]);

	const onSubmit = (values) => {
		values = formatSentence(values);

		setSubmitting(true);
		if (isEdit) {
			updateSentenceTranslation(id, values)
				.catch(() => {})
				.finally(() => setSubmitting(false));
		} else {
			addSentenceTranslation(values)
				.then(added => onDone(added))
				.catch(() => {})
				.finally(() => setSubmitting(false));
		}
	}

	const onDelete = () => {
		ConfirmationDialog({
			title: 'Are you sure you want to delete this sentence translation?',
			onConfirm: () => {
				deleteSentenceTranslation(id).then(() => onDone(sentence));
			}
		});
	}

	return loading ? <PageLoader /> : (
		<Form
			className="sentence-form" onFinish={onSubmit} layout="vertical"
			initialValues={isEdit ? sentence : {}}
		>
			<Form.Item
				label="Sentence"
				name="sentence"
				rules={InputRules.required()}
			>
				<SentencesInput />
			</Form.Item>
			<Form.Item
				label="Translation"
				name="translation"
				rules={InputRules.required("Please input translation")}
			>
				<Input.TextArea placeholder="Sentence translation" />
			</Form.Item>
			<Form.Item
				label="Alternate translation"
				name="alternative_translation"
			>
				<Input.TextArea placeholder="Alternate sentence translation" />
			</Form.Item>
			<Form.Item label="Audio file" name="audio">
				<AudioInput />
			</Form.Item>
			<LanguageInput label="Language" name="language" required={true} />

			<div className="form-buttons">
				{!isEdit ? <div /> : (
					<Button
						type="text" size="large" loading={loading} onClick={onDelete} className="danger"
						disabled={submitting}
					>
						Delete
					</Button>
				)}
				<Button
					type="primary" htmlType="submit" size="large" loading={submitting} className="wide"
					disabled={submitting}
				>
					{isEdit ? "Update this " : "Add"} Sentence
				</Button>
			</div>
		</Form>
	)
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	getSentenceTranslation: (...args) => dispatch(getSentenceTranslation(...args)),
	addSentenceTranslation: (...args) => dispatch(addSentenceTranslation(...args)),
	updateSentenceTranslation: (...args) => dispatch(updateSentenceTranslation(...args)),
	deleteSentenceTranslation: (...args) => dispatch(deleteSentenceTranslation(...args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SentenceTranslationForm);
