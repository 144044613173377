import { connect } from "react-redux";
import {
  addSentence,
  deleteSentence,
  getSentence,
  updateSentence,
} from "../../redux/actions";
import { useEffect, useState } from "react";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import PageLoader from "../../components/PageLoader";
import { Button, Form, Input } from "antd";
import InputRules from "../../util/InputRules";
import ImageInput from "../../components/form/ImageInput";
import AudioInput from "../../components/form/AudioInput";
import LanguageInput from "../../components/form/LanguageInput";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import Language from "../../data/Language";
import WordsInput from "../../components/form/WordsInput";

const DYNAMIC_SENTENCE_TRANSLATIONS = false;

function parseSentence(sentence) {
  return sentence;
}

function formatSentence(form) {
  return form;
}

function isFieldMendatory(index) {
  if (index === 0) {
    return InputRules.required("Please input translation");
  }
}

export function SentenceTranslationItem({
  name,
  required = true,
  min = 1,
  disabled,
  onAddClick,
  onRemoveClick,
}) {
  return (
    <Form.List
      name={name}
      rules={
        required
          ? [
              {
                validator: async (_, names) => {
                  if (!names || names.length < min) {
                    return Promise.reject(
                      new Error("At least 1 sentence translation")
                    );
                  }
                },
              },
            ]
          : undefined
      }
    >
      {(fields, { add, remove }, { errors }) => (
        <>
          <div className="form-list-items-wrapper">
            {fields.map((field, index) => (
              <Form.Item
                required={false}
                key={field.key}
                className="word-translations-input-list form-list-items"
              >
                <Form.Item
                  label={index === 0 && "Translation"}
                  name={[index, "translation"]}
                  validateTrigger={["onChange", "onBlur"]}
                  rules={isFieldMendatory(index)}
                >
                  <Input.TextArea
                    placeholder="Sentence translation"
                    style={{ width: "60%" }}
                    disabled={disabled}
                  />
                </Form.Item>
                <Form.Item
                  label={index === 0 && "Alternate translation"}
                  name={[index, "alternative_translation"]}
                  validateTrigger={["onChange", "onBlur"]}
                >
                  <Input.TextArea
                    placeholder="Alternate sentence translation"
                    style={{ width: "60%" }}
                    disabled={disabled}
                  />
                </Form.Item>
                <Form.Item
                  label={index === 0 && "Audio file"}
                  name={[index, "audio"]}
                >
                  <AudioInput disabled={disabled} />
                </Form.Item>
                <LanguageInput
                  label={index === 0 && "Language"}
                  name={[index, "language"]}
                  required={true}
                  disabled={disabled || !DYNAMIC_SENTENCE_TRANSLATIONS}
                />

                {DYNAMIC_SENTENCE_TRANSLATIONS && fields.length > 1 ? (
                  <MinusCircleOutlined
                    className="dynamic-delete-button"
                    onClick={
                      disabled
                        ? () => onRemoveClick(field)
                        : () => remove(field.name)
                    }
                  />
                ) : null}
              </Form.Item>
            ))}
          </div>
          {DYNAMIC_SENTENCE_TRANSLATIONS && (
            <Form.Item>
              <Button onClick={disabled ? onAddClick : () => add()} type="text">
                <PlusOutlined /> Add another translated sentence
              </Button>
            </Form.Item>
          )}
        </>
      )}
    </Form.List>
  );
}

function SentenceForm({
  id,
  item,
  addSentence,
  deleteSentence,
  getSentence,
  updateSentence,
  onDone,
  onCancel,
  submitting,
  setSubmitting,
}) {
  const [loading, setLoading] = useState(false);
  const isEdit = !!id;

  const [sentence, setSentence] = useState(item ? parseSentence(item) : null);

  useEffect(() => {
    if (!id || item) return;

    setLoading(true);
    getSentence(id)
      .then((data) => (data ? setSentence(parseSentence(data)) : onCancel()))
      .finally(() => setLoading(false));
  }, [item, onCancel, getSentence, id]);

  const onSubmit = (values) => {
    values = formatSentence(values);

    setSubmitting(true);
    if (isEdit) {
      updateSentence(id, values)
        .catch(() => {})
        .finally(() => setSubmitting(false));
    } else {
      addSentence(values)
        .then((added) => onDone(added))
        .catch(() => {})
        .finally(() => setSubmitting(false));
    }
  };

  const onDelete = () => {
    ConfirmationDialog({
      title: "Are you sure you want to delete this sentence?",
      onConfirm: () => {
        deleteSentence(id).then(() => onDone(sentence));
      },
    });
  };

  return loading ? (
    <PageLoader />
  ) : (
    <Form
      className="sentence-form"
      onFinish={onSubmit}
      layout="vertical"
      initialValues={
        isEdit
          ? sentence
          : {
              translations: [
                { language: Language.ENGLISH.id },
                { language: Language.JAPANESE.id },
              ],
            }
      }
    >
      <Form.Item name="image">
        <ImageInput className="word-image-input" />
      </Form.Item>
      <SentenceTranslationItem name="translations" min={2} required />
      <Form.Item name="word" rules={InputRules.required()}>
        <WordsInput
          placeholder="Select word"
          multiple={false}
          disabled={item?.word}
        />
      </Form.Item>

      <div className="form-buttons">
        {!isEdit ? (
          <div />
        ) : (
          <Button
            type="text"
            size="large"
            loading={loading}
            onClick={onDelete}
            className="danger"
            disabled={submitting}
          >
            Delete
          </Button>
        )}
        <Button
          type="primary"
          htmlType="submit"
          size="large"
          loading={submitting}
          className="wide"
          disabled={submitting}
        >
          {isEdit ? "Update this " : "Add"} Sentence
        </Button>
      </div>
    </Form>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getSentence: (...args) => dispatch(getSentence(...args)),
  addSentence: (...args) => dispatch(addSentence(...args)),
  updateSentence: (...args) => dispatch(updateSentence(...args)),
  deleteSentence: (...args) => dispatch(deleteSentence(...args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SentenceForm);
