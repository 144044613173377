import Logger from "../../data/Logger";
import UnityLogService from "../../services/UnityLogService";
import Notifications from "../../util/Notifications";

export const getUnityLog = (id) => () =>
  new Promise((resolve) => {
    (Array.isArray(id)
      ? Promise.all(id.map((i) => UnityLogService.get(i)))
      : UnityLogService.get(id)
    )
      .then((r) => resolve(r))
      .catch(() => resolve(false));
  });

  export const getUnityLogURL = (id) =>  {
    return new Promise((resolve) => {
      UnityLogService.getDownloadURL(id)
      .then((r) => resolve(r))
      .catch(() => resolve(false));
    })
  }

export const searchUnityLogs = (input) => () =>
  new Promise((resolve) => {
    UnityLogService.search(input)
      .then((r) => resolve(r?.results))
      .catch((e) => {
        Logger.exception(e);
        resolve(false);
      });
  });

export const getUnityLogs = (filters) => () =>
  new Promise((resolve) => {
    UnityLogService.paginate(filters)
      .then((r) => resolve(r))
      .catch(() => {
        Notifications.error("Failed to get unity logs");
        resolve(false);
      });
  });
