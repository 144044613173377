import { LOGOUT_USER } from "../auth/constants";

const INIT_STATE = {};

const UnityLogs = (state = INIT_STATE, action) => {
	switch (action.type) {
		case LOGOUT_USER:
			return state;

		default:
			return state;
	}
};

export default UnityLogs;
