import AsyncSelect from "./AsyncSelect";
import { getTag, searchTags } from "../../redux/actions";
import { connect } from "react-redux";

function TagsInput({ searchTags, getTag, mode, allowNew, placeholder, ...props }) {
	return (
		<AsyncSelect
			{...props}
			multiple={true}
			mode={mode || "tags"}
			getItems={searchTags}
			getValues={getTag}
			labelField="name"
			valueField="id"
			placeholder={placeholder || "Search tags"}
			allowNew={allowNew}
		/>
	);
}

const mapDispatchToProps = (dispatch) => ({
	searchTags: (...args) => dispatch(searchTags(...args)),
	getTag: (...args) => dispatch(getTag(...args)),
});

export default connect(null, mapDispatchToProps)(TagsInput);
