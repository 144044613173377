import { http } from "../util/http";
import Util from "../util";

export default class AnalyticService {
  static getTagAnalytics(filters) {
    return http.get(
      `analytics/stats/words-read-stats/?${Util.toQuery(filters)}`
    );
  }
}
