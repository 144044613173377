import Logger from "../../data/Logger";
import BundleService from "../../services/ActivityBundleService";
import Notifications from "../../util/Notifications";

export const getBundle = (id) => () =>
  new Promise((resolve) => {
    BundleService.get(id)
      .then((r) => resolve(r))
      .catch(() => resolve(false));
  });

export const addBundle = (data) => () =>
  new Promise((resolve, reject) => {
    BundleService.add(data)
      .then((r) => {
        Notifications.success("Bundle added successfully");
        resolve(r);
      })
      .catch((e) => {
        Logger.exception(e);
        reject(e);
      });
  });

export const updateBundle = (id, data) => () =>
  new Promise((resolve, reject) => {
    BundleService.update(id, data)
      .then((r) => {
        Notifications.success("Updated bundle");
        resolve(r);
      })
      .catch((e) => {
        Logger.exception(e);
        reject(e);
      });
  });

export const deleteBundle = (id) => () =>
  new Promise((resolve) => {
    Notifications.async(BundleService.delete(id), {
      loadingMessage: `Deleting bundle ${id}`,
      successMessage: "Deleted bundle successfully",
      errorMessage: "Failed to delete the bundle",
    })
      .then(() => resolve(true))
      .catch((e) => {
        Logger.exception(e);
        resolve(false);
      });
  });

export const getBundles = (filters) => () =>
  new Promise((resolve) => {
    BundleService.paginate(filters)
      .then((r) => resolve(r))
      .catch(() => {
        Notifications.error("Failed to get bundles");
        resolve(false);
      });
  });

export const clearCache = () => () =>
  new Promise((resolve) => {
    Notifications.async(BundleService.clearCache(), {
      loadingMessage: `Clearing Cache`,
      successMessage: "Cache cleared successfully",
      errorMessage: "Failed to clear the cache",
    })
      .then(() => resolve(true))
      .catch((e) => {
        Logger.exception(e);
        resolve(false);
      });
  });
