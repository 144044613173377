import Data from "../../data";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Layout, Menu } from "antd";
import Variables from "../../assets/styles/variables";
import SidebarLinks from "../../navigation/SidebarLinks";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { memo } from "react";

function Sidebar({ user, history }) {
  return (
    <Layout.Sider width={Variables.SidebarWidth}>
      <div className="sidebar-header">
        <p>
          GAMERIZE<span style={{ fontSize: 16, fontWeight: 400 }}>.admin</span>
        </p>
      </div>
      <Menu theme="dark" mode="inline">
        {SidebarLinks.map((item, index) =>
          item.separator ? (
            <div
              className="divider"
              style={{ margin: "15px auto" }}
              key={index}
            />
          ) : (
            <Menu.Item
              key={index}
              className={classnames({
                active: item.path === history.location.pathname,
              })}
            >
              <Link to={item.path}>
                {item.icon && (
                  <img className="icon" src={item.icon} alt="icon" />
                )}
                <span>{item.name}</span>
              </Link>
            </Menu.Item>
          )
        )}
      </Menu>
    </Layout.Sider>
  );
}

const mapStatetoProps = (state) => ({
  user: Data.getUser(state),
});

export default memo(connect(mapStatetoProps)(withRouter(Sidebar)));
