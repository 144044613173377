import Links from "./Links";

const SidebarLinks = [
  {
    icon: null,
    name: "Dashboard",
    path: Links.DASHBOARD,
  },
  {
    icon: null,
    name: "Adventures",
    path: Links.ADVENTURES,
  },
  {
    icon: null,
    name: "Bundles",
    path: Links.BUNDLES,
  },
  {
    icon: null,
    name: "Words",
    path: Links.WORDS,
  },
  {
    icon: null,
    name: "Tags",
    path: Links.TAGS,
  },
  {
    icon: null,
    name: "Tags Analytics",
    path: Links.ANALYTICS,
  },
  {
    icon: null,
    name: "Subscription Logs",
    path: Links.SUBSCRIPTION_LOGS,
  },
  {
    icon: null,
    name: "Customer Subscription",
    path: Links.SUBSCRIPTIONS_PASSWORD,
  },
  {
    icon: null,
    name: "Manual Bank Transfer",
    path: Links.BANK_TRANSFERS_PASSWORD,
  },
  {
    icon: null,
    name: "Export Reports",
    path: Links.EXPORT_REPORTS,
  },
  {
    icon: null,
    name: "Tag Update",
    path: Links.TAG_UPDATE,
  },
  {
    icon: null,
    name: "Student Rewards",
    path: Links.STUDENT_REWARDS,
  },
  {
    icon: null,
    name: "Students Registration",
    path: Links.STUDENTS_REGISTRATION
  },
  {
    icon: null,
    name: "Unity Logs",
    path: Links.UNITY_LOGS,
  },
  {
    icon: null,
    name: "Player Data Backups",
    path: Links.PLAYER_DATA_BACKUPS,
  },
  {
    icon: null,
    name: "QnA",
    path: Links.QNAS,
  },
  {
    icon: null,
    name: "QnA Activity",
    path: Links.QNA_ACVITIES,
  },
  {
    icon: null,
    name: "QnA Activity Bundles",
    path: Links.ACTIVITY_BUNDLES,
  },

];

export default SidebarLinks;
