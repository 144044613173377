import { http } from "../util/http";
import Util from "../util";
import { toFormData } from "../util/FormData";

export default class BundleService {
  static paginate(filters) {
    return http.get(`/qnas/data-entry/qna-activity-bundle/?${Util.toQuery(filters)}`);
  }

  static get(id) {
    return http.get(`/qnas/data-entry/qna-activity-bundle/${id}/`);
  }

  static add(bundle) {
    return http.post(
      "/qnas/data-entry/qna-activity-bundle/",
      toFormData(bundle, { brackets: false })
    );
  }

  static update(id, data) {
    if (typeof data["cover_image"] == 'string') {
      delete data["cover_image"];
    }
    return http.patch(
      `/qnas/data-entry/qna-activity-bundle/${id}/`,
      toFormData(data, { brackets: false })
    );
  }

  static delete(id) {
    return http.delete(`/qnas/data-entry/qna-activity-bundle/${id}/`);
  }

  static clearCache() {
    return http.get(`/clear_cache/`);
  }
}
