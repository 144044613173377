import Logger from "../../data/Logger";
import StudentRewardService from "../../services/studentRewardService";
import Notifications from "../../util/Notifications";

export const searchStudents = (input) => () => {
  return new Promise((resolve) => {
    StudentRewardService.search(input)
      .then((r) => resolve(r?.results))
      .catch((e) => {
        Logger.exception(e);
        resolve(false);
      });
  });
};

export const getRewardStudents = (filters) => () =>
  new Promise((resolve) => {
    StudentRewardService.paginate(filters)
      .then((r) => resolve(r))
      .catch(() => {
        Notifications.error("Failed to get unity logs");
        resolve(false);
      });
  });

export const createReward = (data) => () =>
  new Promise((resolve, reject) => {
    StudentRewardService.create(data)
      .then((r) => {
        Notifications.success("Reward added successfully");
        resolve(r);
      })
      .catch((e) => {
        Logger.exception(e);
        reject(e);
      });
  });
