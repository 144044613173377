import { useState, useEffect } from 'react';
import { Descriptions, Modal } from 'antd';
import Dialog from '../../components/PlayerDataBackupDialog';
import Util from '../../util';
import { http } from '../../util/http';
import Notifications from '../../util/Notifications';

export default function PlayerDataBackupDialog({ visible, hide, item, ...props }) {
  const [response, setResponse] = useState(null);
  const [awsDataDialog, setAwsDataDialog] = useState(false);
  const [messageTitle, setMessageTitle] = useState('');
  const [messageBody, setMessageBody] = useState('');
  const [confirmationMsg, setConfirmationMsg] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [playerDialog, setPlayerDialog] = useState(false);

  let items = response?.player_data?.customizable_items;
  useEffect(() => {
    if (item === null || item?.id === undefined) {
      items = null;
      setResponse('');
      setAwsDataDialog(false);
      setMessageTitle('');
      setMessageBody('');
      return;
    }
    async function fetchData() {
      let res;
      try {
        res = await http.get(`users/admin/player_data/${item?.id}`);
        setResponse(res);
      } catch {
        Notifications.error('Failed to get Player Data Backup');
      }
    }
    if (!isModalVisible) {
      fetchData();
      setPlayerDialog(true);
    }
  }, [item?.id]);

  const GetPlayerDataUrl = async username => {
    try {
      const res = await http.post('users/student/profile/player_data_urls/', {
        username,
      });
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');

      const raw = JSON.stringify(response?.player_data);

      const requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
      };

      fetch(res.upload_url, requestOptions)
        .then(response => {
          setIsModalVisible(false);
          setConfirmationMsg(true);
          setMessageTitle('Success Dialog');
          setMessageBody('Data has been uploaded Successfully');
        })
        .then(result => console.log(result))
        .catch(error => {
          console.log(error);
          setMessageTitle('Error Dialog');
          setMessageBody('There is some issue in uploading Data');
        });
    } catch (error) {
      Notifications.error(error.response.statusText);
    }
  };

  const restoreConfirmationHandler = () => {
    GetPlayerDataUrl(response.user.username);
    setPlayerDialog(false);
    setIsModalVisible(false);
    items = null;
    setResponse('');
  };

  const restoreConfirmationCancelHandler = () => {
    setIsModalVisible(false);
    setPlayerDialog(false);
    items = null;
    setResponse('');
  };

  const HandleConfirmationComplete = () => {
    setConfirmationMsg(false);
  };

  const handleConfirmationCancel = () => {
    setConfirmationMsg(false);
  };
  return (
    <>
      {isModalVisible && (
        <Modal
          title="Confirmation Dialog"
          visible={isModalVisible}
          okText="Yes"
          onOk={restoreConfirmationHandler}
          onCancel={restoreConfirmationCancelHandler}
          closable={false}
        >
          <p>Are you sure you want to Restore Account?</p>
        </Modal>
      )}

      {
        <Modal
          title={messageTitle}
          visible={confirmationMsg}
          onOk={HandleConfirmationComplete}
          onCancel={handleConfirmationCancel}
          cancelButtonProps={{ style: { display: 'none' } }}
        >
          <p>{messageBody}</p>
        </Modal>
      }
      {!isModalVisible && playerDialog && (
        <Dialog
          title="View Player Data Backup"
          visible={visible}
          onCancel={hide}
          getPlayerData={GetPlayerDataUrl}
          userData={response}
          flag={!awsDataDialog}
          Descriptions={messageBody}
          setConfirmationMsg={() => setIsModalVisible(true)}
        >
          {awsDataDialog === false && (
            <Descriptions title="User Info">
              <Descriptions.Item label="Log ID">{item?.id}</Descriptions.Item>
              <Descriptions.Item label="Date">
                {Util.formatDate(item?.date_created)}
              </Descriptions.Item>
              <Descriptions.Item label="Name">{items?.name}</Descriptions.Item>
              <Descriptions.Item label="Gender">
                {items?.gender === 1 ? 'Male' : 'Female'}
              </Descriptions.Item>
              <Descriptions.Item label="Xp">{items?.xp}</Descriptions.Item>
              <Descriptions.Item label="food">
                {items?.town_map_data?.food}
              </Descriptions.Item>
              <Descriptions.Item label="wood">
                {item?.user_details?.firstName} {items?.town_map_data.wood}
              </Descriptions.Item>
              <Descriptions.Item label="metal">
                {items?.town_map_data?.metal}
              </Descriptions.Item>
              <Descriptions.Item label="stone">
                {items?.town_map_data.stone}
              </Descriptions.Item>
              <Descriptions.Item label="Gold">{items?.coins}</Descriptions.Item>
              <Descriptions.Item label="Level">{items?.level}</Descriptions.Item>
              <Descriptions.Item label="Buildings Data count">
                {items?.town_map_data?.buildings_data?.length || null}
              </Descriptions.Item>
            </Descriptions>
          )}
        </Dialog>
      )}
    </>
  );
}
