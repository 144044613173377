import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

export default function ConfirmationDialog({ title, message, type = 'danger', onConfirm, onCancel }) {
	Modal.confirm({
		title: title,
		icon: <ExclamationCircleOutlined />,
		content: message,
		okText: 'Yes',
		okType: type,
		cancelText: 'No',
		onOk: onConfirm,
		onCancel: onCancel,
		centered: true
	});
}
