import { connect } from "react-redux";
import { exportReport } from "../../redux/export-reports/actions";
import { useEffect, useState } from "react";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import PageLoader from "../../components/PageLoader";
import { Button, Form, Input, Select } from "antd";
import InputRules from "../../util/InputRules";
import Language from "../../data/Language";
import { generateYears } from "../../util/FormData";

function parseTag(tag) {
  return tag;
}

function formatForm(form) {
  form.type = form.type.value;
  return form;
}

function ReportForm({
  id,
  generateReport,
  onDone,
  onCancel,
  submitting,
  setSubmitting,
}) {
  const [loading, setLoading] = useState(false);

  const onSubmit = (values) => {
    values = formatForm(values);

    setSubmitting(true);
    generateReport(values)
      .then((added) => onDone(added))
      .catch(() => {})
      .finally(() => setSubmitting(false));
  };

  return loading ? (
    <PageLoader />
  ) : (
    <Form className="tag-form" onFinish={onSubmit} layout="vertical">
      <Form.Item name="name" rules={InputRules.required("Enter Name")}>
        <Input placeholder="Name" />
      </Form.Item>

      <Form.Item name="type" rules={InputRules.required("Enter Type")}>
        <Select
          labelInValue
          size="large"
          style={{ minWidth: 160 }}
          placeholder="Type"
          allowClear
        >
          <Select.Option value={1}>Simple words export</Select.Option>
          <Select.Option value={2}>Export words with content</Select.Option>
          <Select.Option value={3}>Students data export</Select.Option>
          <Select.Option value={4}>Teachers data export</Select.Option>
        </Select>
      </Form.Item>

      <div className="form-buttons">
        <div />
        <Button
          type="primary"
          htmlType="submit"
          size="large"
          loading={submitting}
          className="wide"
          disabled={submitting}
        >
          Generate Report
        </Button>
      </div>
    </Form>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  generateReport: (...args) => dispatch(exportReport(...args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportForm);
