import { connect } from "react-redux";
import AsyncSelect from "./AsyncSelect";
import { getSchools } from "../../redux/actions";

function SchoolsInput({ getSchools, ...props }) {
  return (
    <AsyncSelect
      {...props}
      getItems={getSchools}
      labelField="name"
      valueField="id"
    />
  );
}

const mapDispatchToProps = (dispatch) => ({
  getSchools: (...args) => dispatch(getSchools(...args)),
});

export default connect(null, mapDispatchToProps)(SchoolsInput);
