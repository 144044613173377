import { connect } from "react-redux";
import { useState } from "react";
import PasswordDialog from "../../components/PasswordDialog";

function BankTransferPassword() {
  const [dialogVisible, setDialogVisible] = useState(true);

  return (
    <PasswordDialog
      visible={dialogVisible}
      hide={() => setDialogVisible(false)}
      component="bank-transfer"
    />
  );
}

export default connect()(BankTransferPassword);
