import { connect } from "react-redux";
import InformationDialog from "../../components/InformationDialog";
import { Button, Form, Input } from "antd";
import InputRules from "../../util/InputRules";
import { useHistory } from "react-router-dom";

function ReportForm(params) {
  const finalPassword = "123";
  const history = useHistory();

  const onSubmit = async (values) => {
    const password = values.password;
    const found = finalPassword.match(password);
    if (found && params.component === "bank-transfer") {
      history.push(`/bank-transfer`);
    } else if (found && params.component === "subscription") {
      history.push(`/customer-subscription`);
    } else {
      InformationDialog({
        title: "Incorrect Password. Try Again!",
      });
    }
  };

  return (
    <Form className="tag-form" onFinish={onSubmit} layout="vertical">
      <Form.Item name="password" rules={InputRules.required("Enter Password")}>
        <Input type="password" placeholder="Enter Passowrd" />
      </Form.Item>

      <div className="form-buttons">
        <div />
        <Button type="primary" htmlType="submit" size="large" className="wide">
          Enter
        </Button>
      </div>
    </Form>
  );
}

export default connect()(ReportForm);
