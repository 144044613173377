import { http } from "../util/http";
import Util from "../util";

export default class SubscriptionLogService {
  static paginate(filters) {
    return http.get(
      `/users/admin/subscriptions/logs/?${Util.toQuery(filters)}`
    );
  }

  static async search(input) {
    return http.get(`/users/admin/subscriptions/logs?search=${input || ""}`);
  }
}
