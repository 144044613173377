import Page from "../../components/layout/Page";
import { Link } from "react-router-dom";
import { Button, Dropdown, Image, Menu, Select, Tag } from "antd";
import Links from "../../navigation/Links";
import {
  ClearOutlined,
  CloseCircleOutlined,
  DownOutlined,
  FileExcelOutlined,
} from "@ant-design/icons";
import TableView from "../../components/TableView";
import Data from "../../data";
import TagsInput from "../../components/form/TagsInput";
import { deleteWord, getWords } from "../../redux/words/actions";
import { connect } from "react-redux";
import Util from "../../util";
import { useState } from "react";
import BulkWordsDialog from "./BulkWordsDialog";
import { withRouter } from "react-router";

const TableColumns = ({ filters, onDelete } = {}) => [
  {
    title: "Id",
    dataIndex: "id",
    fixed: "left",
    width: 82,
  },
  {
    title: "Word",
    dataIndex: "name",
    ellipsis: true,
    fixed: "left",
    width: 130,
  },
  {
    title: "Sentence",
    dataIndex: "sentence",
    ellipsis: true,
    fixed: "left",
    width: 250,
  },
  {
    title: "Tags",
    dataIndex: "tags",
    ellipsis: true,
    fixed: "left",
    filters: filters?.tags?.map((t) => ({ value: t.id, text: t.name })),
    filterMultiple: true,
    onFilter: (value, record) => !!record.tags?.find((t) => t.id === value),
    render: (value) =>
      value?.map((v) => v.name).map((s, i) => <Tag key={i}>{s}</Tag>),
  },
  {
    title: "Image",
    name: "has_image",
    width: 80,
    filters: [
      {
        text: "Has Image",
        value: true,
      },
      {
        text: "No Image",
        value: false,
      },
    ],
    filterMultiple: false,
    onFilter: (value, record) => (value ? !!record.image : !record.image),
    render: (value) =>
      !value.image ? (
        <CloseCircleOutlined />
      ) : (
        <Image
          src={value.image}
          height={50}
          width={50}
          style={{ objectFit: "contain" }}
        />
      ),
  },
  {
    title: "Action",
    key: "action",
    width: 160,
    fixed: "right",
    render: (word) => (
      <span className="table-action-buttons">
        <Link
          className="text-button view"
          style={{ color: "white" }}
          to={Links.Word(word)}
        >
          View
        </Link>
        <Dropdown
          trigger={["click"]}
          className="table-more-button"
          overlay={
            <Menu className="actions-menu">
              <Menu.Item
                className="negative delete"
                onClick={() => onDelete(word)}
              >
                Delete
              </Menu.Item>
            </Menu>
          }
        >
          <span className="text-button ant-dropdown-link">
            More <DownOutlined />
          </span>
        </Dropdown>
      </span>
    ),
  },
];

function Words({ getWords, deleteWord, history }) {
  const [bulkUploadDialog, setBulkUploadDialog] = useState(false);

  return (
    <Page title="Words">
      <TableView
        title="Words"
        columns={TableColumns}
        getItems={getWords}
        deleteItem={deleteWord}
        addButton={{
          text: "Add Word",
          link: Links.ADD_WORD,
        }}
        searchLabel="Search Word"
        extraTableButtons={
          <Button
            size="large"
            type="outlined"
            onClick={() => setBulkUploadDialog(true)}
            key="11"
          >
            <FileExcelOutlined /> Bulk Upload
          </Button>
        }
        mapItem={Data.mapWord}
        renderFilters={(values, onChange, onSearch, onReset) => (
          <>
            <Select
              labelInValue
              size="large"
              style={{ minWidth: 160 }}
              placeholder="Sentence image"
              value={
                values.has_sentence_image === undefined
                  ? undefined
                  : { value: values.has_sentence_image }
              }
              onChange={(s) => onChange({ has_sentence_image: s?.value })}
              allowClear
            >
              <Select.Option value={false}>Sentence Image</Select.Option>
              <Select.Option value={true}>No Sentence Image</Select.Option>
            </Select>
            <Select
              labelInValue
              size="large"
              style={{ minWidth: 160 }}
              placeholder="Sentence audio"
              value={
                values.has_sentence_audio === undefined
                  ? undefined
                  : { value: values.has_sentence_audio }
              }
              onChange={(s) => onChange({ has_sentence_audio: s?.value })}
              allowClear
            >
              <Select.Option value={true}>Sentence Audio</Select.Option>
              <Select.Option value={false}>No Sentence Audio</Select.Option>
            </Select>
            <Select
              labelInValue
              size="large"
              style={{ minWidth: 100 }}
              placeholder="Audio"
              value={
                values.has_audio === undefined
                  ? undefined
                  : { value: values.has_audio }
              }
              onChange={(s) => onChange({ has_audio: s?.value })}
              allowClear
            >
              <Select.Option value={true}>With Audio</Select.Option>
              <Select.Option value={false}>No Audio</Select.Option>
            </Select>
            <TagsInput
              value={values.tags}
              onChange={(tags) => onChange({ tags })}
            />
            <Button
              size="large"
              style={{ marginLeft: 10 }}
              onClick={onReset}
              icon={<ClearOutlined />}
              disabled={Util.isEmpty(values)}
            />
            <Button
              size="large"
              style={{ marginLeft: 10 }}
              onClick={onSearch}
              disabled={Util.isEmpty(values)}
            >
              Filter
            </Button>
          </>
        )}
        parseFilters={(filters) => {
          let tagsList = "";
          for (let i = 0; i < filters.tags?.length; i++) {
            tagsList += filters.tags[i].id + ",";
          }

          return {
            ...filters,
            tags: tagsList,
          };
        }}
      />

      <BulkWordsDialog
        visible={bulkUploadDialog}
        hide={() => setBulkUploadDialog(false)}
        onDone={() => {
          setBulkUploadDialog(false);
          history.go(0);
        }}
      />
    </Page>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getWords: (...args) => dispatch(getWords(...args)),
  deleteWord: (...args) => dispatch(deleteWord(...args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Words));
