import { http } from "../util/http";
import Util from "../util";
import { toFormData } from "../util/FormData";

export default class SentenceService {
	static paginate(filters) {
		return http.get(`/dictionary/data-entry/sentence/?${Util.toQuery(filters)}`)
	}

	static paginateTranslations(filters) {
		return http.get(`/dictionary/data-entry/translated-sentence/?${Util.toQuery(filters)}`)
	}

	static search(input) {
		return http.get(`/dictionary/data-entry/sentence?search=${input || ''}`);
	}

	static get(id) {
		return http.get(`/dictionary/data-entry/sentence/${id}/`);
	}

	static getTranslation(id) {
		return http.get(`/dictionary/data-entry/translated-sentence/${id}/`);
	}

	static add(word, data) {
		return http.post('/dictionary/data-entry/sentence/', toFormData({ word, ...data }))
	}

	static addTranslation(sentence, data) {
		return http.post(`/dictionary/data-entry/translated-sentence/`, toFormData({ sentence, ...data }))
	}

	static update(id, data) {
		return http.patch(`/dictionary/data-entry/sentence/${id}/`, toFormData(data));
	}

	static updateTranslation(id, data) {
		return http.patch(`/dictionary/data-entry/translated-sentence/${id}/`, toFormData(data));
	}

	static delete(id) {
		return http.delete(`/dictionary/data-entry/sentence/${id}/`);
	}

	static deleteTranslation(id) {
		return http.delete(`/dictionary/data-entry/translated-sentence/${id}/`);
	}
}
