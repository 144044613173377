import SubscriptionLogService from "../../services/subscriptionLogsService";
import Notifications from "../../util/Notifications";
import Logger from "../../data/Logger";

export const getSubscriptionLogs = (filters) => () =>
  new Promise((resolve) => {
    SubscriptionLogService.paginate(filters)
      .then((r) => resolve(r))
      .catch(() => {
        Notifications.error("Failed to get Subscription Logs");
        resolve(false);
      });
  });

export const searchSubscriptionLogs = (input) => () =>
  new Promise((resolve) => {
    SubscriptionLogService.search(input)
      .then((r) => resolve(r?.results))
      .catch((e) => {
        Logger.exception(e);
        resolve(false);
      });
  });
