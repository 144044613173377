import { Select, Spin } from "antd";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import debounce from "lodash/debounce";
import ReactTags from "react-tag-autocomplete";
import Util from "../../util";

export default function AsyncSelect({
	                                    placeholder,
	                                    getItems,
	                                    getValues,
	                                    mode,
	                                    labelField,
	                                    valueField,
	                                    labelFormat,
	                                    searchable = true,
	                                    value,
	                                    mapItem,
	                                    multiple,
	                                    disabled,
	                                    allowNew,
	                                    ...props
                                    }) {
	const [items, setItems] = useState([]);
	const [fetching, setFetching] = useState(false);
	const tagsFetchRef = useRef(0);
	const useTagsComponent = mode === 'tags';

	const parseOptions = useCallback((newOptions) => {
		if (!newOptions) return;
		newOptions = mapItem ? newOptions?.map(mapItem) : newOptions;

		if (!labelField && !valueField) {
			setItems(newOptions?.map(s => ({ label: labelFormat ? labelFormat(s) : s, value: s })));
		} else if (useTagsComponent) {
			setItems(newOptions?.map(s => ({
				name: labelFormat ? labelFormat(s[labelField], s) : s[labelField],
				id: s[valueField]
			})))
		} else {
			setItems(newOptions?.map(s => ({
				label: labelFormat ? labelFormat(s[labelField], s) : s[labelField],
				value: s[valueField]
			})));
		}
	}, [useTagsComponent, mapItem, valueField, labelFormat, labelField, setItems]);

	const onInput = useMemo(() => {
		const loadOptions = (value) => {
			tagsFetchRef.current += 1;
			const fetchId = tagsFetchRef.current;

			setItems([]);
			setFetching(true);

			getItems(value).then(newOptions => {
				if (fetchId !== tagsFetchRef.current) {
					// for fetch callback order
					return;
				}

				parseOptions(newOptions);
				setFetching(false);
			});
		};

		return debounce(loadOptions, 100);
	}, [parseOptions, getItems]);

	useEffect(() => {
		if (!getValues || (multiple ? !value?.length : !value)) return;

		setFetching(true);
		getValues(multiple
			? Util.isObjectArray(value) ? value?.map(v => valueField ? v[valueField] : v) : value
			: [value])
			.then(s => parseOptions(s))
			.finally(() => setFetching(false));
		// eslint-disable-next-line
	}, []);

	useEffect(() => onInput(), [onInput]);

	return useTagsComponent ? (
		<ReactTags
			tags={value}
			suggestions={items}
			allowNew={allowNew}
			allowBackspace
			disabled={disabled}
			placeholderText={placeholder}
			onInput={!disabled && searchable ? onInput : undefined}
			onDelete={i => {
				if (disabled) return;
				const tags = value?.slice(0);
				tags?.splice(i, 1);
				props.onChange(tags);
			}}
			onAddition={tag => {
				if (disabled || value?.findIndex(v => tag.id ? v.id === tag.id || v.name === tag.name : v.name === tag.name) >= 0) {
					return;
				}
				const tags = [].concat(value || [], tag);
				props.onChange(tags);
			}}
		/>
	) : (
		<Select
			mode={mode}
			placeholder={placeholder}
			onSearch={searchable ? onInput : undefined}
			loading={fetching}
			showSearch={searchable}
			notFoundContent={fetching ? <Spin size="small" /> : null}
			options={items}
			value={value}
			disabled={disabled}
			{...props}
		/>
	)
}
