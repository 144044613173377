import LanguageService from "../../services/LanguageService";

export const getLanguages = () => () => new Promise(resolve => {
	LanguageService.getLanguages()
		.then(r => resolve(r))
		.catch(() => resolve([]))
});

export const getLearningAims = () => () => new Promise(resolve => {
	resolve(["Spelling the Word", "Writing the Sentence", "Saying the Word", "Saying the Sentence"])
});
