import { connect } from "react-redux";
import Data from "./data";
import { useEffect, useState } from "react";
import PageLoader from "./components/PageLoader";
import { Toaster } from 'react-hot-toast';
import Routes from "./navigation";

const STATE_LOADING = -2;
const STATE_LOADED = 1;

function App({ isLoggedIn, user, isLoaded }) {
	const [initStatus, setInitStatus] = useState(STATE_LOADING);

	useEffect(() => {
		if (isLoaded) setInitStatus(STATE_LOADED);
	}, [isLoaded])

	return (
		<>
			{isLoaded && initStatus > 0 ? (
				<Routes />
			) : (
				<PageLoader />
			)}

			<Toaster position="bottom-right" reverseOrder={false} />
		</>
	);
}

const mapStateToProps = (state) => ({
	isLoaded: state.App.isLoadingComplete,
	isLoggedIn: Data.getLoggedIn(state)
});

export default connect(mapStateToProps)(App);
