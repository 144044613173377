import { http } from "../util/http";
import Util from "../util";

export default class TagService {
  static paginate(filters) {
    return http.get(`/dictionary/data-entry/tags/?${Util.toQuery(filters)}`);
  }

  static async search(input) {
    return http.get(`/dictionary/data-entry/tags/?search=${input}`);
  }

  static get(id) {
    return http.get(`/dictionary/data-entry/tags/${id}/`);
  }

  static add(tag) {
    return http.post("/dictionary/data-entry/tags/", tag);
  }

  static update(id, data) {
    return http.patch(`/dictionary/data-entry/tags/${id}/`, data);
  }

  static delete(id) {
    return http.delete(`/dictionary/data-entry/tags/${id}/`);
  }

  static bulkUpdate(query_tags, add_tags, remove_tags) {
    return http.post(`/dictionary/bulk_update/tags/`, {
      query_tags,
      add_tags,
      remove_tags,
    });
  }
}
