import { connect } from 'react-redux';
import { Dropdown, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import moment from 'moment';
import Page from '../../components/layout/Page';
import Links from '../../navigation/Links';
import TableView from '../../components/TableView';
import { deleteQna, getQna } from '../../redux/qna-activity/actions';
import AdventureDialog from './AdventureDialog';
import Util from '../../util';

const TableColumns = ({ onItemClick, onDelete } = {}) => [
  {
    title: 'Id',
    dataIndex: 'id',
    fixed: 'left',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    ellipsis: true,
  },

  {
    name: 'date',
    title: 'Date',
    defaultSortOrder: 'descend',
    sorter: (a, b) => moment(a.date_created).unix() - moment(b.date_created).unix(),
    render: item => Util.formatDate(item.date_created),
  },
  {
    title: 'Action',
    key: 'action',
    width: 180,
    fixed: 'right',
    render: word => (
      <span className="table-action-buttons">
        <div className="text-button mr-10" onClick={() => onItemClick(word)}>
          View
        </div>
        <Dropdown
          trigger={['click']}
          className="table-more-button cursor-pointer"
          overlay={
            <Menu className="actions-menu">
              <Menu.Item className="negative" onClick={() => onDelete(word)}>
                Delete
              </Menu.Item>
            </Menu>
          }
        >
          <span className="text-button ant-dropdown-link">
            More <DownOutlined />
          </span>
        </Dropdown>
      </span>
    ),
  },
];

function Adventures({ getQna, deleteQna }) {
  return (
    <Page title="QnA Activity">
      <TableView
        title="QnA Activity"
        columns={TableColumns}
        getItems={getQna}
        deleteItem={deleteQna}
        addButton={{ text: 'Add QnA Activity' }}
        Dialog={AdventureDialog}
        getLink={s => (s ? Links.Qna_acitivity(s) : Links.QNA_ACVITIES)}
      />
    </Page>
  );
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  getQna: (...args) => dispatch(getQna(...args)),
  deleteQna: (...args) => dispatch(deleteQna(...args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Adventures);
