import Dialog from "../../components/Dialog";
import SubscriptionForm from "./SubscriptionForm";
import { useState } from "react";

export default function SubscriptionDialog({
  visible,
  hide,
  item,
  preloaded,
  ...props
}) {
  const [loading, setLoading] = useState(false);

  return (
    <Dialog
      title={item ? "Update Subscription" : "Create Subscription"}
      visible={visible}
      onCancel={hide}
      closable={!loading}
    >
      <SubscriptionForm
        {...props}
        id={item?.id}
        item={preloaded ? item : undefined}
        submitting={loading}
        setSubmitting={(l) => setLoading(l)}
        onCancel={hide}
      />
    </Dialog>
  );
}
