import Notifications from "../../util/Notifications";
import Logger from "../../data/Logger";
import BankTransferService from "../../services/BankTransferService";

export const getTransferRequests = (filters) => () =>
  new Promise((resolve) => {
    if (filters.status === undefined) filters.status = 2;
    BankTransferService.getRequests(filters)
      .then((r) => {
        resolve(r);
      })
      .catch(() => {
        Notifications.error("Failed to get Bank Transfer Requests");
        resolve(false);
      });
  });

export const approveTransferRequest = (id) => () =>
  new Promise((resolve, reject) => {
    BankTransferService.approveRequest(id)
      .then((r) => {
        Notifications.success("Request Approved");
        resolve(r);
      })
      .catch((e) => {
        Logger.exception(e);
        reject(e);
      });
  });

export const rejectTransferRequest = (id) => () =>
  new Promise((resolve, reject) => {
    BankTransferService.rejectRequest(id)
      .then((r) => {
        Notifications.success("Request Rejected");
        resolve(r);
      })
      .catch((e) => {
        Logger.exception(e);
        reject(e);
      });
  });
