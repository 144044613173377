import Page from "../../components/layout/Page";
import { connect } from "react-redux";
import { getAllExports } from "../../redux/export-reports/actions";
import TableView from "../../components/TableView";
import ReportDialog from "./ReportDialog";
import Links from "../../navigation/Links";
import Util from "../../util";
import { Typography } from "antd";

const TableColumns = ({ onItemClick, onDelete } = {}) => [
  {
    title: "Id",
    dataIndex: "id",
    ellipsis: true,
  },
  {
    title: "Name",
    dataIndex: "name",
    ellipsis: true,
  },
  {
    title: "Type",
    dataIndex: "export_type",
  },
  {
    title: "Date created",
    render: (item) => Util.formatDate(item.date_created),
  },
  {
    title: "Date updated",
    render: (item) => Util.formatDate(item.date_updated),
  },
  {
    title: "File",
    dataIndex: "csv_file",
    ellipsis: true,
  },
  {
    title: "Action",
    key: "action",
    fixed: "right",
    render: (item) => (
      <span className="table-action-buttons">
        <a href={item.csv_file}>
          <div className="text-button mr-10">Download</div>
        </a>
      </span>
    ),
  },
];

function ExportReports({ getAllExportsData }) {
  return (
    <Page title="Export Reports">
      <TableView
        title="Export Reports"
        columns={TableColumns}
        getItems={getAllExportsData}
        isAnalyticTable={false}
        isSearchable={false}
        Dialog={ReportDialog}
        addButton={{ text: "Add Report" }}
        getLink={(s) => (s ? Links.ExportReports(s) : Links.EXPORT_REPORTS)}
        isSearchable={false}
        isCheckable={false}
      />
    </Page>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getAllExportsData: (...args) => dispatch(getAllExports(...args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExportReports);
