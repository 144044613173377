import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

export default function InformationDialog({ title, message, type = 'danger', onConfirm, onCancel }) {
	Modal.info({
		title: title,
		icon: <ExclamationCircleOutlined />,
		content: message,
		okText: 'ok',
		okType: type,
		onOk: onConfirm,
		centered: true
	});
}
