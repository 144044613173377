import PlayerDataBackupService from "../../services/PlayerDataBackupService";
import Notifications from "../../util/Notifications";

export const getPlayerDataBackups = (filters) => () =>
  new Promise((resolve) => {
    PlayerDataBackupService.paginate(filters)
      .then((r) => resolve(r))
      .catch(() => {
        Notifications.error("Failed to get Player Data Backups");
        resolve(false);
      });
  });

export const getPlayerDataBackup = (filters) => () =>
  new Promise((resolve) => {
    PlayerDataBackupService.getBackup(filters)
      .then((r) => resolve(r))
      .catch(() => {
        Notifications.error("Failed to get Player Data Backup");
        resolve(false);
      });
  });
