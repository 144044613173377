import { combineReducers } from 'redux';
import Auth from './auth/reducers';
import App from './app/reducers';
import Words from './words/reducers';
import Sentences from './sentences/reducers';
import Tags from './tags/reducers';
import Adventures from './adventures/reducers';
import Bundles from './bundles/reducers';
import Schools from './schools/reducers';
import UnityLogs from './unity-logs/reducers';

export default combineReducers({
	App,
	Auth,
	Words,
	Sentences,
	Tags,
	Adventures,
	Bundles,
	Schools,
	UnityLogs
});
