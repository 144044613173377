import { useParams, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Button, Form, Input, PageHeader, Select } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { MinusCircleOutlined } from '@ant-design/icons';
import Page from '../../components/layout/Page';
import { http } from '../../util/http';
import { toFormData } from '../../util/FormData';

import {
  addWord,
  deleteWord,
  getWord,
  updateWord,
  cloneWord,
} from '../../redux/qna/actions';
import PageLoader from '../../components/PageLoader';
import Language from '../../data/Language';
import InputRules from '../../util/InputRules';
import ImageInput from '../../components/form/ImageInput';
import AudioInput from '../../components/form/AudioInput';
import Links from '../../navigation/Links';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import LanguageInput from '../../components/form/LanguageInput';
import TagsInput from '../../components/form/TagsInput';
import Data from '../../data';
import Notifications from '../../util/Notifications';

const { Option } = Select;

const INITIAL_WORDS = [
  { language: Language.ENGLISH.id },
  { language: Language.JAPANESE.id },
];
const INITIAL_SENTENCES = {
  translations: [{ language: Language.ENGLISH.id }, { language: Language.JAPANESE.id }],
};

const DYNAMIC_WORD_TRANSLATIONS = false;
const DYNAMIC_SENTENCES = false;

function parseWord(word) {
  return { ...Data.mapWord(word) };
}

function formatWord(form) {
  form.tags = form.tags?.map(t => t.name);
  return form;
}

function isFieldMendatory(index) {
  if (index === 0) {
    return InputRules.required('Please input translation');
  }
}

export function WordTranslationItem({
  name,
  required,
  disabled,
  min = 1,
  onAddClick,
  onRemoveClick,
}) {
  return (
    <Form.List
      name={name}
      rules={
        required
          ? [
              {
                validator: async (_, names) => {
                  if (!names || names.length < min) {
                    return Promise.reject(new Error('At least 1 word translations'));
                  }
                },
              },
            ]
          : undefined
      }
    >
      {(fields, { add, remove }, { errors }) => (
        <>
          <div className="form-list-items-wrapper">
            {fields.map((field, index) => (
              <Form.Item
                required={false}
                key={field.key}
                className="word-translations-input-list form-list-items"
              >
                <Form.Item
                  label={index === 0 && 'Translation'}
                  name={[index, 'translation']}
                  validateTrigger={['onChange', 'onBlur']}
                  rules={isFieldMendatory(index)}
                >
                  <Input
                    placeholder="Translation"
                    style={{ width: '60%' }}
                    disabled={disabled}
                  />
                </Form.Item>
                <Form.Item
                  label={index === 0 && 'Alternate translation'}
                  name={[index, 'alternative_translation']}
                  validateTrigger={['onChange', 'onBlur']}
                >
                  <Input
                    placeholder="Alternate translation"
                    style={{ width: '60%' }}
                    disabled={disabled}
                  />
                </Form.Item>
                <Form.Item label={'Audio file'} name={'audio'}>
                  <AudioInput disabled={disabled} />
                </Form.Item>
                <LanguageInput
                  label={index === 0 && 'Language'}
                  name={[index, 'language']}
                  required
                  disabled={disabled || !DYNAMIC_WORD_TRANSLATIONS}
                />

                {DYNAMIC_WORD_TRANSLATIONS && fields.length > 1 ? (
                  <MinusCircleOutlined
                    className="dynamic-delete-button"
                    onClick={
                      disabled ? () => onRemoveClick(field) : () => remove(field.name)
                    }
                  />
                ) : null}
              </Form.Item>
            ))}
          </div>
          
        </>
      )}
    </Form.List>
  );
}

function QnaWordForm({ addWord, getWord, updateWord, deleteWord, cloneWord, history }) {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  /** Word Form Data */
  const [word, setWord] = useState(null);
  const formRef = useRef();
  const isEdit = !!id;
  const individualEdit = isEdit && false;

  useEffect(() => {
    if (!id) return;

    setLoading(true);
    getWord(id)
      .then(word => (word ? setWord(parseWord(word)) : history.push(Links._404)))
      .finally(() => setLoading(false));
    // eslint-disable-next-line
  }, [getWord, id]);

  const submitWord = values => {
    setSubmitting(true);
    if (isEdit) {
      if (values.tags?.length > 0) {
        addWord(values)
          .then(res => {
            const ans = res.tags?.map(t => t.id);
            values.tags = ans;
            Notifications.success(`Updated ${word.name} successfully`);
            http.patch(`/qnas/data-entry/qnas/${id}/`, toFormData(values));
            setTimeout(() => {
              history.push(Links.QNAS);
            }, 700);
          })
          .catch(e => {})
          .finally(() => setSubmitting(false));
      } else {
        values.tags = []
        http.patch(`/qnas/data-entry/qnas/${id}/`, toFormData(values));

        setTimeout(() => {
          history.push(Links.QNAS);
        }, 700);
      }
    } else if (values.tags) {
      addWord(values)
        .then(added => {
          const tagsList = added.tags.map(data => data.id);
          values.tags = tagsList;
          http.post('/qnas/data-entry/qnas/', toFormData(values));

          setTimeout(() => {
            history.push(Links.QNAS);
          }, 700);
        })
        .catch(e => {})
        .finally(() => setSubmitting(false));
    } else {
      values.tags = []
      http.post('/qnas/data-entry/qnas/', toFormData(values));
      setTimeout(() => {
        history.push(Links.QNAS);
      }, 700);
    }
  };

  const onSubmit = values => {
    values = formatWord(values);
    submitWord(values);
  };

  const onDelete = () => {
    ConfirmationDialog({
      title: 'Are you sure you want to delete this Qna?',
      onConfirm: () => {
        deleteWord(id).then(() => history.push(Links.QNAS));
      },
    });
  };

  function renderImageInput() {
    return (
      <>
        <section>
          {renderTagsInput()}
          <Form.Item label="Qna Image" name="image">
            <ImageInput className="word-image-input" />
          </Form.Item>
        </section>
      </>
    );
  }

  function renderTagsInput() {
    return (
      <section className="words-tags">
        <div className="form-section-title">Tags</div>
        <Form.Item name="tags">
          <TagsInput mode="tags" placeholder="Select tags for Qna" allowNew />
        </Form.Item>
      </section>
    );
  }

  return loading ? (
    <PageLoader />
  ) : (
    <Page
      title={
        isEdit
          ? word?.name
            ? `${word?.name} | Update Word`
            : `Update Word - ${word?.id}`
          : 'Create Word'
      }
    >
      <PageHeader title={isEdit ? 'Update QnA' : 'Add QnA'} />

      <div />
      <Form
        className="word-form"
        onFinish={onSubmit}
        layout="vertical"
        initialValues={
          isEdit
            ? word
            : {
                translations: INITIAL_WORDS,
                sentences: [INITIAL_SENTENCES],
              }
        }
      >
        <Form.Item
          label="Question"
          name="question"
          rules={InputRules.required('Please input name')}
        >
          <Input placeholder="Question" />
        </Form.Item>

        <Form.Item
          label="Answer"
          name="answer"
          rules={InputRules.required('Please input name')}
        >
          <Input placeholder="Answer" />
        </Form.Item>
        <Form.Item
          label="Qna Type"
          name="qna_type"
          style={{
            display: 'inline-block',
            width: 'max-width',
          }}
        >
          <Select defaultValue="standard" labelField="Adventure Type" searchable={false}>
            <Option value="standard">Standard</Option>
            <Option value="big_image">Big Image</Option>
          </Select>
        </Form.Item>

        <div>{renderImageInput()}</div>

        <Form.Item label="Audio file" name={['audio']}>
          <AudioInput disabled={individualEdit} />
        </Form.Item>
        <div className="button-group valigned space-between">
          {!isEdit ? (
            <div />
          ) : (
            <Button
              type="text"
              size="large"
              loading={loading}
              onClick={onDelete}
              className="danger"
              disabled={submitting}
            >
              Delete this QnA
            </Button>
          )}
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            loading={submitting}
            className="wide"
            disabled={submitting}
          >
            {isEdit ? 'Update this ' : 'Add'} QnA
          </Button>
        </div>
      </Form>
    </Page>
  );
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  getWord: (...args) => dispatch(getWord(...args)),
  addWord: (...args) => dispatch(addWord(...args)),
  updateWord: (...args) => dispatch(updateWord(...args)),
  deleteWord: (...args) => dispatch(deleteWord(...args)),
  cloneWord: (...args) => dispatch(cloneWord(...args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(QnaWordForm));
