import { useEffect, useState } from "react";
import Notifications from "../../util/Notifications";
import { Upload } from "antd";
import Util from "../../util";
import { PlusOutlined } from "@ant-design/icons";

export default function ImageInput({ value, onChange, className, disabled }) {
  const [image, setImage] = useState(null);

  useEffect(() => typeof value !== "string" && setImage(value), [value]);

  const handleImage = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) Notifications.error("You can only upload JPG/PNG file!");
    const isLtM = file.size / 1024 / 1024 < 0.8;
    if (!isLtM) Notifications.error("Image must smaller than 800 KB!");
    if (isJpgOrPng && isLtM) {
      setImage(file);
      onChange(file);
    }
    return false;
  };

  const onImageLoad = ({ target: img }) => {
    if (img.neaturalWidth > 512 || img.naturalWidth > 512) {
      Notifications.error("Invalid Dimensions, should not exceed 512 x 512");
      setImage(null);
      onChange(null);
    }
  };
  
  return (
    <Upload
      name="image"
      listType="picture-card"
      showUploadList={false}
      beforeUpload={handleImage}
      disabled={disabled}
    >
      {image || value ? (
        <img
          onLoad={onImageLoad}
          src={image ? Util.getFileSrc(image) : value}
          alt="img"
          className={className}
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      ) : (
        <div className={className}>
          <PlusOutlined />
          <div style={{ marginTop: 8 }}>Select Image</div>
        </div>
      )}
    </Upload>
  );
}
