import Logger from "../../data/Logger";
import AdventureService from "../../services/AdventureService";
import Notifications from "../../util/Notifications";

export const getAdventure = (id) => () =>
  new Promise((resolve) => {
    (Array.isArray(id)
      ? Promise.all(id.map((i) => AdventureService.get(i)))
      : AdventureService.get(id)
    )
      .then((r) => resolve(r))
      .catch(() => resolve(false));
  });

export const getNextPageAdventures = (next) => () =>
  new Promise((resolve) => {
    AdventureService.getNextPage(next)
      .then((r) => resolve(r))
      .catch(() => resolve(false));
  });

export const addAdventure = (data) => () =>
  new Promise((resolve, reject) => {
    AdventureService.add(data)
      .then((r) => {
        Notifications.success("Adventure added successfully");
        resolve(r);
      })
      .catch((e) => {
        Logger.exception(e);
        reject(e);
      });
  });

export const updateAdventure = (id, data) => () =>
  new Promise((resolve, reject) => {
    AdventureService.update(id, data)
      .then((r) => {
        Notifications.success("Updated adventure");
        resolve(r);
      })
      .catch((e) => {
        Logger.exception(e);
        reject(e);
      });
  });

export const deleteAdventure = (id) => () =>
  new Promise((resolve) => {
    Notifications.async(AdventureService.delete(id), {
      loadingMessage: `Deleting adventure ${id}`,
      successMessage: "Deleted adventure successfully",
      errorMessage: "Failed to delete the adventure",
    })
      .then(() => resolve(true))
      .catch((e) => {
        Logger.exception(e);
        resolve(false);
      });
  });

export const getAdventures = (filters) => () =>
  new Promise((resolve) => {
    
    AdventureService.paginate(filters)
      .then((r) => resolve(r))
      .catch(() => {
        Notifications.error("Failed to get adventures");
        resolve(false);
      });
  });

export const searchAdventures = (input) => () =>
  new Promise((resolve) => {
    AdventureService.search(input)
      .then((r) => resolve(r))
      .catch(() => {
        Notifications.error("Failed to get adventures");
        resolve(false);
      });
  });

/**
 *  Adventure Assigned
 */
export const getAssignedAdventure = (id) => () =>
  new Promise((resolve) => {
    AdventureService.get(id)
      .then((r) => resolve(r))
      .catch(() => resolve(false));
  });

export const addAssignedAdventure = (data) => () =>
  new Promise((resolve, reject) => {
    AdventureService.add(data)
      .then((r) => {
        Notifications.success("Adventure added successfully");
        resolve(r);
      })
      .catch((e) => {
        Logger.exception(e);
        reject(e);
      });
  });

export const updateAssignedAdventure = (id, data) => () =>
  new Promise((resolve, reject) => {
    AdventureService.update(id, data)
      .then((r) => {
        Notifications.success("Updated adventure");
        resolve(r);
      })
      .catch((e) => {
        Logger.exception(e);
        reject(e);
      });
  });

export const deleteAssignedAdventure = (id) => () =>
  new Promise((resolve) => {
    Notifications.async(AdventureService.delete(id), {
      loadingMessage: `Deleting adventure ${id}`,
      successMessage: "Deleted adventure successfully",
      errorMessage: "Failed to delete the adventure",
    })
      .then(() => resolve(true))
      .catch((e) => {
        Logger.exception(e);
        resolve(false);
      });
  });

export const getAssignedAdventures = (filters) => () =>
  new Promise((resolve) => {
    AdventureService.paginate(filters)
      .then((r) => resolve(r))
      .catch(() => {
        Notifications.error("Failed to get adventures");
        resolve(false);
      });
  });
