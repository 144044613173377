import Page from "../../components/layout/Page";
import { connect } from "react-redux";
import { Dropdown, Menu, Tag } from "antd";
import Links from "../../navigation/Links";
import TableView from "../../components/TableView";
import SubscriptionDialog from "./SubscriptionDialog";
import { DownOutlined } from "@ant-design/icons";
import Util from "../../util";
import {
  deleteSubscription,
  getSubscriptions,
} from "../../redux/subscription/actions";

const TableColumns = ({ onItemClick, onDelete } = {}) => [
  {
    title: "Id",
    dataIndex: "id",
    fixed: "left",
    width: 92,
  },
  {
    title: "School Name",
    dataIndex: "school_name",
    ellipsis: true,
  },
  {
    title: "Start Date",
    render: (item) => Util.formatDate(item.start_date),
  },
  {
    title: "End Date",
    render: (item) => Util.formatDate(item.validity_date),
  },
  {
    title: "Subscription Type",
    dataIndex: "subscription_type",
    render: (value) => <Tag>{value === 2 ? "Pro" : "Basic"}</Tag>,
  },
  {
    title: "Seats",
    dataIndex: "seats",
  },
  {
    title: "Payment Type",
    dataIndex: "payment_type",
    render: (value) => (
      <Tag>
        {value === 3
          ? "Trial Period"
          : value === 2
          ? "Bank Transfer"
          : "Stripe"}
      </Tag>
    ),
  },
  {
    title: "Action",
    key: "action",
    width: 180,
    fixed: "right",
    render: (subscription) => (
      <span className="table-action-buttons">
        <div
          className="text-button mr-10"
          onClick={() => onItemClick(subscription)}
        >
          View
        </div>
        <Dropdown
          trigger={["click"]}
          className="table-more-button cursor-pointer"
          overlay={
            <Menu className="actions-menu">
              <Menu.Item
                className="negative"
                onClick={() => onDelete(subscription)}
              >
                Delete
              </Menu.Item>
            </Menu>
          }
        >
          <span className="text-button ant-dropdown-link">
            More <DownOutlined />
          </span>
        </Dropdown>
      </span>
    ),
  },
];

function CustomerSubscription({ getSubscriptions, deleteSubscription }) {
  return (
    <Page title="Customer Subscription">
      <TableView
        title="Subscription"
        columns={TableColumns}
        getItems={getSubscriptions}
        deleteItem={deleteSubscription}
        addButton={{ text: "Add Subscription" }}
        Dialog={SubscriptionDialog}
        searchLabel="Search School Name"
        getLink={(s) =>
          s ? Links.CustomerSubscription(s) : Links.CUSTOMER_SUBSCRIPTIONS
        }
      />
    </Page>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getSubscriptions: (...args) => dispatch(getSubscriptions(...args)),
  deleteSubscription: (...args) => dispatch(deleteSubscription(...args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerSubscription);
