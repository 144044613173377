import Page from "../../components/layout/Page";
import { connect } from "react-redux";
import { Dropdown, Menu, Tag } from "antd";
import Links from "../../navigation/Links";
import { DownOutlined } from "@ant-design/icons";
import TableView from "../../components/TableView";
import { deleteAdventure, getAdventures } from "../../redux/adventures/actions";
import AdventureDialog from "./AdventureDialog";
import Util from "../../util";
import moment from "moment";

const TableColumns = ({ onItemClick, onDelete } = {}) => [
  {
    title: "Id",
    dataIndex: "id",
    fixed: "left",
    width: 92,
  },
  {
    title: "Name",
    dataIndex: "name",
    ellipsis: true,
  },
  {
    title: "Learning Aim",
    dataIndex: "learning_aim",
    render: (value) =>
      value === 1
        ? "Spelling the Word"
        : value === 2
        ? "Write the Sentence"
        : value === 3
        ? "Saying the Word"
        : value === 4
        ? "Say the Sentence"
        : "No Learning Aim",
  },
  {
    title: "Price",
    dataIndex: "is_paid",
    width: 80,
    filters: [
      {
        text: "PAID",
        value: true,
      },
      {
        text: "FREE",
        value: false,
      },
    ],
    filterMultiple: false,
    onFilter: (value, record) => record.status === value,
    render: (value) => <Tag>{value ? "PAID" : "FREE"}</Tag>,
  },
  {
    name: "date",
    title: "Date",
    defaultSortOrder: "descend",
    sorter: (a, b) =>
      moment(a.date_created).unix() - moment(b.date_created).unix(),
    render: (item) => Util.formatDate(item.date_created),
  },
  {
    title: "Action",
    key: "action",
    width: 180,
    fixed: "right",
    render: (word) => (
      <span className="table-action-buttons">
        <div className="text-button mr-10" onClick={() => onItemClick(word)}>
          View
        </div>
        <Dropdown
          trigger={["click"]}
          className="table-more-button cursor-pointer"
          overlay={
            <Menu className="actions-menu">
              <Menu.Item className="negative" onClick={() => onDelete(word)}>
                Delete
              </Menu.Item>
            </Menu>
          }
        >
          <span className="text-button ant-dropdown-link">
            More <DownOutlined />
          </span>
        </Dropdown>
      </span>
    ),
  },
];

function Adventures({ getAdventures, deleteAdventure }) {
  return (
    <Page title="Adventures">
      <TableView
        title="Adventures"
        columns={TableColumns}
        getItems={getAdventures}
        deleteItem={deleteAdventure}
        addButton={{ text: "Add Adventure" }}
        Dialog={AdventureDialog}
        getLink={(s) => (s ? Links.Adventure(s) : Links.ADVENTURES)}
      />
    </Page>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getAdventures: (...args) => dispatch(getAdventures(...args)),
  deleteAdventure: (...args) => dispatch(deleteAdventure(...args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Adventures);
