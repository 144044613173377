const Links = {
  DASHBOARD: "/",
  LOGIN: "/login",

  WORDS: "/words",
  ADD_WORD: "/words/add",
  WORD: "/words/:id",
  Word: ({ id }) => `/words/${id}`,

  WORD_TRANSLATIONS: "/word-translations",
  WORD_TRANSLATION: "/word-translations/:id",
  WordTranslation: ({ id }) => `/word-translations/${id}`,

  SENTENCES: "/sentences",
  SENTENCE: "/sentences/:id",
  Sentence: ({ id }) => `/sentences/${id}`,

  SENTENCE_TRANSLATIONS: "/sentence-translations",
  SENTENCE_TRANSLATION: "/sentence-translations/:id",
  SentenceTranslation: (id) => `/sentence-translations/${id}`,

  ADVENTURES: "/adventures",
  ADVENTURE: "/adventures/:id",
  Adventure: ({ id }) => `/adventures/${id}`,

  ADVENTURES_ASSIGNED: "/adventures-assigned",
  ADVENTURE_ASSIGNED: "/adventures-assigned/:id",
  AdventureAssigned: ({ id }) => `/adventures-assigned/${id}`,

  ACTIVITY_BUNDLES: "/activity_bundles",
  ACTIVITY_BUNDLE: "/activity_bundles/:id",
  Activity_bundle: ({ id }) => `/activity_bundles/${id}`,

  BUNDLES: "/bundles",
  BUNDLE: "/bundles/:id",
  Bundle: ({ id }) => `/bundles/${id}`,

  TAGS: "/tags",
  TAG: "/tags/:id",
  Tag: ({ id }) => `/tags/${id}`,

  ANALYTICS: "/analytics",

  SUBSCRIPTION_LOGS: "/subscription-logs",
  Subscription_logs: ({ id }) => `/subscription-logs/${id}`,

  CUSTOMER_SUBSCRIPTIONS: "/customer-subscription",
  SUBSCRIPTIONS_PASSWORD: "/customer-subscription-password",
  CUSTOMER_SUBSCRIPTION: "/customer-subscription/:id",
  CustomerSubscription: ({ id }) => `/customer-subscription/${id}`,

  BANK_TRANSFERS: "/bank-transfer",
  BANK_TRANSFERS_PASSWORD: "/bank-transfer-password",

  EXPORT_REPORTS: "/export-reports",
  EXPORT_REPORT: "/export-reports/:id",
  ExportReport: ({ id }) => `/export-reports/${id}`,

  TAG_UPDATE: "/tag-update",

  STUDENT_REWARDS: "/student-rewards",
  student_rewards: ({ id }) => `/student-rewards/${id}`,
  STUDENTS_REGISTRATION: "/students-registration",
  UNITY_LOGS: "/unity-logs",
  UNITY_LOG: "/unity-logs/:id",
  UnityLog: ({ id }) => `/unity-logs/${id}`,

  PLAYER_DATA_BACKUPS: "/player-data-backups",
  PLAYER_DATA_BACKUP: "/player-data-backups/:id",
  PlayerDataBackup: ({ id }) => `/player-data-backups/${id}`,

  QNA_ACVITIES: "/qna-activity",
  QNA_ACTIVITY: "/qna-activity/:id",
  Qna_acitivity: ({ id }) => `/qna-activity/${id}`,

  QNAS: "/qnas",
  ADD_QNA: "/qnas/add",
  QNA: "/qnas/:id",
  Qna: ({ id }) => `/qnas/${id}`,

  _404: `/404`,
};

export default Links;
