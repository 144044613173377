import * as queryString from "query-string";
import moment from "moment";
import isEmpty from "lodash/isEmpty";

function getUnique(arr, index) {
  const unique = arr
    .map((e) => e[index])

    // store the keys of the unique objects
    .map((e, i, final) => final.indexOf(e) === i && i)

    // eliminate the dead keys & store unique objects
    .filter((e) => arr[e])
    .map((e) => arr[e]);

  return unique;
}

const Util = {
  isEmpty,

  formatDateTime: (date) => moment(date).calendar(),
  formatDate: (date) => moment(date).format("DD MMM, YYYY"),
  shortFormatedDateTime: (date) => moment(date).format("h:mma DD,MM,yyyy"),

  /** Axios */
  getResponseStatus: (error) =>
    error && error.response && error.response.status,
  isStatus: (error, status) =>
    error && error.response && error.response.status === status,
  errorMessage: (error) => error?.response?.data?.message,

  getFileSrc: (file) => URL.createObjectURL(file),
  setFormValue: (formRef, body) => {
    formRef?.current.setFieldsValue(body)
  },
  setFormErrors: (formRef, error) => {
    if (!formRef || !Util.isStatus(error, 400)) return;
    formRef = formRef.current || formRef;
  },

  query: (location) =>
    location
      ? queryString.parse(location.search, { ignoreQueryPrefix: false })
      : {},
  toQuery: (obj) =>
    obj
      ? queryString.stringify(obj, { skipEmptyString: true, skipNull: true })
      : "",

  mergeArrays(arr1, arr2, field = "id") {
    const arr = [...arr2, ...arr1];
    const items = {};
    for (const item of arr) {
      items[item[field]] = item;
    }
    return Object.values(getUnique(arr, "id"));
  },

  updateLocation(path, history) {
    if (window.history.replaceState) {
      window.history.pushState({}, null, path);
    } else {
      history?.push(path);
    }
  },

  isObjectArray: (arr) => arr?.length > 0 && typeof arr[0] === "object",
  removeBy: (obj, compare) => {
    for (const key of Object.keys(obj)) {
      if (obj[key] === compare) {
        delete obj[key];
      }
    }
    return obj;
  },
  objectWithoutKeys(object, keys) {
    return Object.keys(object).reduce((newObject, key) => {
      if (!keys.includes(key)) newObject[key] = object[key];
      return newObject;
    }, {});
  },
};

export default Util;
