import InputRules from "../../util/InputRules";
import { Form } from "antd";
import { connect } from "react-redux";
import { getLanguages } from "../../redux/actions";
import AsyncSelect from "./AsyncSelect";

function LanguageInput({ label, name, disabled, index, required, getLanguages, ...props }) {
	return (
		<Form.Item
			label={label}
			name={name}
			rules={required ? InputRules.required("Please select language") : null}
			{...props}
		>
			<AsyncSelect
				getItems={getLanguages} disabled={disabled}
				valueField="id" labelField="name" searchable={false}
			/>
		</Form.Item>
	)
}

const mapDispatchToProps = (dispatch) => ({
	getLanguages: (...args) => dispatch(getLanguages(...args)),
});

export default connect(null, mapDispatchToProps)(LanguageInput);
