import Dialog from "../../components/Dialog";
import { useState } from "react";
import ReportForm from "./ReportForm";

export default function ReportDialog({ visible, hide, item, ...props }) {
  const [loading, setLoading] = useState(false);

  return (
    <Dialog
      title={"Generate Report"}
      visible={visible}
      onCancel={hide}
      closable={!loading}
    >
      <ReportForm
        {...props}
        id={item?.id}
        submitting={loading}
        setSubmitting={(l) => setLoading(l)}
        onCancel={hide}
      />
    </Dialog>
  );
}
