import Logger from "../../data/Logger";
import TagService from "../../services/TagService";
import Notifications from "../../util/Notifications";

export const getTag = (id) => () =>
  new Promise((resolve) => {
    (Array.isArray(id)
      ? Promise.all(id.map((i) => TagService.get(i)))
      : TagService.get(id)
    )
      .then((r) => resolve(r))
      .catch(() => resolve(false));
  });

export const addTag = (data) => () =>
  new Promise((resolve, reject) => {
    TagService.add(data)
      .then((r) => {
        Notifications.success("Tag added successfully");
        resolve(r);
      })
      .catch((e) => {
        Notifications.error("Failed to add the tag");
        Logger.exception(e);
        reject(e);
      });
  });

export const updateTag = (id, data) => () =>
  new Promise((resolve, reject) => {
    TagService.update(id, data)
      .then((r) => {
        Notifications.success("Updated tag");
        resolve(r);
      })
      .catch((e) => {
        Notifications.error("Failed to update the tag");
        Logger.exception(e);
        reject(e);
      });
  });

export const deleteTag = (id) => () =>
  new Promise((resolve) => {
    Notifications.async(TagService.delete(id), {
      loadingMessage: `Deleting tag ${id}`,
      successMessage: "Deleted tag successfully",
      errorMessage: "Failed to delete the tag",
    })
      .then(() => resolve(true))
      .catch((e) => {
        Logger.exception(e);
        resolve(false);
      });
  });

export const searchTags = (input) => () =>
  new Promise((resolve) => {
    TagService.search(input)
      .then((r) => resolve(r?.results))
      .catch((e) => {
        Logger.exception(e);
        resolve(false);
      });
  });

export const getTags = (filters) => () =>
  new Promise((resolve) => {
    TagService.paginate(filters)
      .then((r) => resolve(r))
      .catch(() => {
        Notifications.error("Failed to get tags");
        resolve(false);
      });
  });
