import toast from 'react-hot-toast';

const style = {};

const DEFAULT_DURATION = 5000;

export default class Notifications {
	static message(message, { dismiss, icon, duration = DEFAULT_DURATION } = {}) {
		if (dismiss) {
			toast(
				(t) => (
					<span>
						{message}
						{dismiss && <button onClick={() => toast.dismiss(t.id)}>Dismiss</button>}
					</span>
				),
				duration,
				icon
			);
		} else {
			toast(message, { icon, duration, style });
		}
	}

	static success = (message) => toast.success(message, { style, duration: DEFAULT_DURATION });

	static error = (message) => toast.error(message, { style, duration: DEFAULT_DURATION });

	static async = (promise, { loadingMessage = 'Please wait...', successMessage, errorMessage } = {}) =>
		toast
			.promise(promise, {
				loading: loadingMessage,
				success: successMessage ? <b>{successMessage}</b> : undefined,
				error: errorMessage ? <b>{errorMessage}</b> : null,
			})
			.catch(() => {});
}
