import axios from 'axios';
import AuthService from '../services/AuthService';

const instance = axios.create({
	baseURL: process.env.REACT_APP_SERVER_URL,
	headers: { 'Content-Type': 'application/json' },
	withCredentials: false,
	xsrfCookieName: 'XSRF-TOKEN', // `xsrfCookieName` is the name of the cookie to use as a value for xsrf token
	xsrfHeaderName: 'X-XSRF-TOKEN', // `xsrfHeaderName` is the name of the http header that carries the xsrf token value
});

instance.interceptors.request.use(async (request) => {
	const token = AuthService.getToken();
	if (token) request.headers.Authorization = `JWT ${token}`;
	return request;
});
instance.interceptors.response.use((response) => response.data);

export const http = instance;
