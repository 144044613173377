import Page from "../../components/layout/Page";
import { Link } from "react-router-dom";
import Links from "../../navigation/Links";
import TableView from "../../components/TableView";
import { getRewardStudents } from "../../redux/actions";
import { connect } from "react-redux";
import StudentRewardDialog from "./StudentRewardDialog";
const TableColumns = ({ onItemClick } = {}) => [
  {
    title: "Id",
    dataIndex: "user_id",
    fixed: "left",
    width: 100,
  },
  {
    title: "Username",
    fixed: "left",
    dataIndex: "username",
  },
  {
    title: "Name",

    render: (value) => {
      return `${value?.first_name} ${value?.last_name}`;
    },
  },
  {
    title: "Email",
    dataIndex: "email",
  },
  {
    title: "Action",
    key: "action",
    width: 160,
    fixed: "right",
    render: (studentReward) => (
      <span className="table-action-buttons">
        <Link
          className="text-button view"
          style={{ color: "white" }}
          onClick={() => onItemClick(studentReward)}
        >
          Give Reward
        </Link>
      </span>
    ),
  },
];

function StudentRewards({ getStudents }) {
  return (
    <Page title="Student Rewards">
      <TableView
        title="Student Rewards"
        columns={TableColumns}
        getItems={getStudents}
        Dialog={StudentRewardDialog}
        addButton={false}
        getLink={(s) => (s ? Links.student_rewards(s) : Links.STUDENT_REWARDS)}
        isCheckable={false}
        showTotal={true}
      />
    </Page>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getStudents: (...args) => dispatch(getRewardStudents(...args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StudentRewards);
