import { Spin } from 'antd';
import React from 'react';

export default function Loader() {
	return (
		<div className="loader">
			<Spin size="large" />
		</div>
	);
}
