import { http } from "../util/http";
import { toFormData } from "../util/FormData";
import Util from "../util";

export default class WordService {
  static paginate(filters) {
    return http.get(`/dictionary/data-entry/words/?${Util.toQuery(filters)}`);
  }

  static paginateTranslations(filters) {
    return http.get(
      `/dictionary/data-entry/translated-word/?${Util.toQuery(filters)}`
    );
  }

  static search(filters) {
    return http.get(`/dictionary/data-entry/words/?${Util.toQuery(filters)}`);
  }

  static getNextPage(next) {
    return http.get(`/dictionary/data-entry/words/?${next}`);
  }

  static get(id) {
    return http.get(`/dictionary/data-entry/words/${id}/`);
  }

  static getTranslation(id) {
    return http.get(`/dictionary/data-entry/translated-word/${id}/`);
  }

  static add(data) {
    return http.post(
      "/dictionary/data-entry/words/",
      toFormData(data, { brackets: false })
    );
  }

  static addTranslation(word, data) {
    return http.post(
      `/dictionary/data-entry/translated-word/`,
      toFormData({ word, ...data })
    );
  }

  static update(id, data) {
    return http.patch(`/dictionary/data-entry/words/${id}/`, toFormData(data));
  }

  static updateTranslation(id, data) {
    return http.patch(
      `dictionary/data-entry/translated-word/${id}/`,
      toFormData(data)
    );
  }

  static delete(id) {
    return http.delete(`/dictionary/data-entry/words/${id}/`);
  }

  static clone(id) {
    return http.post(`/dictionary/data-entry/words/${id}/clone/`);
  }

  static deleteTranslation(id) {
    return http.delete(`/dictionary/data-entry/translated-word/${id}/`);
  }

  static bulkUpload(file) {
    return http.post(
      "/dictionary/populate/",
      toFormData({ uploaded_file: file })
    );
  }
}
