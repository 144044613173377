import Notifications from "../../util/Notifications";
import Logger from "../../data/Logger";
import ExportReportService from "../../services/ExportReportService";

export const getAllExports = () => () =>
  new Promise((resolve) => {
    ExportReportService.getAllExports()
      .then((r) => {
        resolve(r);
      })
      .catch(() => {
        Notifications.error("Failed to get exports data");
        resolve(false);
      });
  });

export const exportReport =
  ({ type, name }) =>
  () =>
    new Promise((resolve) => {
      ExportReportService.exportReport(type, name)
        .then(() => {
          Notifications.success("Report generated successfully");
          resolve(true);
        })
        .catch((e) => {
          Logger.exception(e);
          resolve(e);
        });
    });
