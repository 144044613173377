import { connect } from "react-redux";
import { useState } from "react";
import PasswordDialog from "../../components/PasswordDialog";

function SubscriptionPassword() {
  const [dialogVisible, setDialogVisible] = useState(true);

  return (
    <PasswordDialog
      visible={dialogVisible}
      hide={() => setDialogVisible(false)}
      component="subscription"
    />
  );
}

export default connect()(SubscriptionPassword);
