import { useCallback, useEffect, useState } from "react";
import { Button, Checkbox, Empty, Input, Spin } from "antd";
import Logger from "../../data/Logger";
import debounce from "lodash/debounce";
import Util from "../../util";
import Constants from "../../util/Constants";

export default function Multiselect({
  value,
  onChange,
  options,
  getValues,
  getItems,
  labelField = "name",
  valueField = "id",
  mapItem,
  disabled,
  renderFilters,
  parseFilters,
  getNextPage,
}) {
  const parseOptions = useCallback(
    (options) => {
      return (
        options?.map((option) => ({
          ...option,
          value: option[valueField],
          label: option[labelField],
        })) || []
      );
    },
    [valueField, labelField]
  );

  const [selected, setSelected] = useState([]);
  const [search, setSearch] = useState("");
  const [items, setItems] = useState(() => parseOptions(options));
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({});
  const [skipFetch, setSkipFetch] = useState(true);
  const [next, setNext] = useState(() => parseOptions(options));

  useEffect(() => setItems(parseOptions(options)), [parseOptions, options]);

  /** Fetch values on component load only */
  useEffect(() => {
    if (!getItems) return;

    // Merging pre-selected and new
    Promise.all([
      getItems(),
      value?.length > 0
        ? !Util.isObjectArray(value)
          ? getValues(value)
          : Promise.resolve(value)
        : Promise.resolve([]),
    ])
      .then(([o, values]) => {
        if (mapItem) {
          o.results = o.results.map(mapItem);
          values = values.map(mapItem);
        }

        setNext(o.next.split("?")[1]);
        setItems(parseOptions(Util.mergeArrays(o.results, values)));
        setSelected(values);
      })
      .catch(() => Logger.info("Failed to get options"))
      .finally(() => setLoading(false));

    // eslint-disable-next-line
  }, [getValues, getItems, parseOptions]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchItems = useCallback(
    debounce(({ input, searchFilters } = {}) => {
      setLoading(true);
      getItems(
        input !== "" && !input ? search : input,
        parseFilters
          ? parseFilters(searchFilters || filters)
          : searchFilters || filters,
        parseFilters
          ? parseFilters(searchFilters || filters)
          : searchFilters || filters
      )
        .then((o) => {
          setItems(
            parseOptions(
              Util.mergeArrays(
                mapItem ? o.results.map(mapItem) : o.results,
                selected
              )
            )
          );
          setNext(o.next.split("?")[1]);
        })
        .catch(() => Logger.info(`Search failed. Unknown error occurred.`))
        .finally(() => setLoading(false));
    }, Constants.DEBOUNCE_VALUE),
    [filters, selected]
  );

  // eslint-disable-next-line
  useEffect(() => {
    if (skipFetch) setSkipFetch(false);
    if (!skipFetch) {
      fetchItems({ searchFilters: filters });
    }
  }, [filters]);

  const onSelectionChange = (s) => {
    setSelected((selected) => {
      const newSelected = [];
      for (const item of [...(selected || []), ...items]) {
        if (s.includes(item.id) && !newSelected.find((a) => a.id === item.id)) {
          newSelected.push(item);
        }
      }
      return newSelected;
    });
    onChange(s);
  };

  const onSearchChange = (e) => {
    setSearch(e.target.value);
    fetchItems({ input: e.target.value });
  };

  const onLoadMore = () => {
    const allItems = items;
    getNextPage(next)
      .then((o) => {
        setItems(
          parseOptions(
            Util.mergeArrays(
              mapItem ? o.results.map(mapItem) : o.results,
              allItems
            )
          )
        );
        setNext(o.next.split("?")[1]);
      })
      .catch(() => Logger.info(`Search failed. Unknown error occurred.`))
      .finally(() => setLoading(false));

    allItems.concat();
  };

  return (
    <>
      {renderFilters &&
        renderFilters(filters, (filters) =>
          setFilters((f) => ({ ...f, ...filters }))
        )}
      <div>
        <Input
          value={search}
          onChange={onSearchChange}
          placeholder="Search..."
          allowClear
          disabled={disabled}
        />
        <Spin spinning={loading}>
          {!loading && !items?.length ? (
            <Empty
              description="No data found"
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              className="multiselect"
            />
          ) : (
            <Checkbox.Group
              className="multiselect"
              options={items}
              onChange={onSelectionChange}
              value={Util.isObjectArray(value) ? value.map((v) => v.id) : value}
              disabled={disabled}
            />
          )}
        </Spin>
        <Button
          onClick={onLoadMore}
          style={{ marginLeft: "40%", marginRight: "40%", width: "20%" }}
        >
          Load More
        </Button>
      </div>
    </>
  );
}
