import { useParams, withRouter } from "react-router";
import { connect } from "react-redux";
import Page from "../../components/layout/Page";
import { Button, Form, Input, PageHeader } from "antd";
import { useEffect, useState } from "react";
import {
  EditOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { addWord, deleteWord, getWord, updateWord, cloneWord } from "../../redux/actions";
import PageLoader from "../../components/PageLoader";
import Language from "../../data/Language";
import InputRules from "../../util/InputRules";
import ImageInput from "../../components/form/ImageInput";
import AudioInput from "../../components/form/AudioInput";
import Links from "../../navigation/Links";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import LanguageInput from "../../components/form/LanguageInput";
import TagsInput from "../../components/form/TagsInput";
import { SentenceTranslationItem } from "../sentences/SentenceForm";
import Data from "../../data";
import Notifications from "../../util/Notifications";

const SENTENCE_INITIAL_VALUE = { translations: [{}] };
const INITIAL_WORDS = [
  { language: Language.ENGLISH.id },
  { language: Language.JAPANESE.id },
];
const INITIAL_SENTENCES = {
  translations: [
    { language: Language.ENGLISH.id },
    { language: Language.JAPANESE.id },
  ],
};

const DYNAMIC_WORD_TRANSLATIONS = false;
const DYNAMIC_SENTENCES = false;

function parseWord(word) {
  return { ...Data.mapWord(word) };
}

function formatWord(form) {
  form.tags = form.tags?.map((t) => t.name);
  return form;
}

function isFieldMendatory(index) {
  if (index === 0) {
    return InputRules.required("Please input translation");
  }
}

export function WordTranslationItem({
  name,
  required,
  disabled,
  min = 1,
  onAddClick,
  onRemoveClick,
}) {
  return (
    <Form.List
      name={name}
      rules={
        required
          ? [
              {
                validator: async (_, names) => {
                  if (!names || names.length < min) {
                    return Promise.reject(
                      new Error("At least 1 word translations")
                    );
                  }
                },
              },
            ]
          : undefined
      }
    >
      {(fields, { add, remove }, { errors }) => (
        <>
          <div className="form-list-items-wrapper">
            {fields.map((field, index) => (
              <Form.Item
                required={false}
                key={field.key}
                className="word-translations-input-list form-list-items"
              >
                <Form.Item
                  label={index === 0 && "Translation"}
                  name={[index, "translation"]}
                  validateTrigger={["onChange", "onBlur"]}
                  rules={isFieldMendatory(index)}
                >
                  <Input
                    placeholder="Translation"
                    style={{ width: "60%" }}
                    disabled={disabled}
                  />
                </Form.Item>
                <Form.Item
                  label={index === 0 && "Alternate translation"}
                  name={[index, "alternative_translation"]}
                  validateTrigger={["onChange", "onBlur"]}
                >
                  <Input
                    placeholder="Alternate translation"
                    style={{ width: "60%" }}
                    disabled={disabled}
                  />
                </Form.Item>

                {console.log([index, "audio"],'sas')}
                <Form.Item
                  label={index === 0 && "Audio file"}
                  name={[index, "audio"]}
                >
                  <AudioInput disabled={disabled} />
                </Form.Item>
                <LanguageInput
                  label={index === 0 && "Language"}
                  name={[index, "language"]}
                  required={true}
                  disabled={disabled || !DYNAMIC_WORD_TRANSLATIONS}
                />

                {DYNAMIC_WORD_TRANSLATIONS && fields.length > 1 ? (
                  <MinusCircleOutlined
                    className="dynamic-delete-button"
                    onClick={
                      disabled
                        ? () => onRemoveClick(field)
                        : () => remove(field.name)
                    }
                  />
                ) : null}
              </Form.Item>
            ))}
          </div>
          {DYNAMIC_WORD_TRANSLATIONS && (
            <Form.Item>
              <Button onClick={disabled ? onAddClick : () => add()} type="text">
                <PlusOutlined /> Add another translated word
              </Button>
            </Form.Item>
          )}
        </>
      )}
    </Form.List>
  );
}

function WordForm({ addWord, getWord, updateWord, deleteWord, cloneWord, history }) {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  /** Word Form Data */
  const [word, setWord] = useState(null);
  const isEdit = !!id;
  const individualEdit = isEdit && false;

  useEffect(() => {
    if (!id) return;

    setLoading(true);
    getWord(id)
      .then((word) =>
        word ? setWord(parseWord(word)) : history.push(Links._404)
      )
      .finally(() => setLoading(false));
    // eslint-disable-next-line
  }, [getWord, id]);

  const submitWord = (values) => {
    console.log("values",values)
    setSubmitting(true);
    if (isEdit) {
      updateWord(id, values)
        .then(() => {
          Notifications.success(`Updated ${word.name} successfully`);
          history.go(0);
        })
        .catch((e) => {})
        .finally(() => setSubmitting(false));
    } else {
      addWord(values)
        .then((added) => history.push(Links.Word(added)))
        .catch((e) => {})
        .finally(() => setSubmitting(false));
    }
  };

  const onSubmit = (values) => {
    values = formatWord(values);
    if (!values.image) {
      ConfirmationDialog({
        title: isEdit
          ? "Are you sure you want to update this word without image?"
          : "Are you sure you want to add this word without image?",
        onConfirm: () => {
          submitWord(values);
        },
      });
    } else {
      submitWord(values);
    }
  };

  const onDelete = () => {
    ConfirmationDialog({
      title: "Are you sure you want to delete this word?",
      onConfirm: () => {
        deleteWord(id).then(() => history.push(Links.WORDS));
      },
    });
  };

  function renderImageInput() {
    return (
      <section className="word-image">
        {renderTagsInput()}
        <Form.Item label="Word Image" name="image">
          <ImageInput className="word-image-input" />
        </Form.Item>
      </section>
    );
  }

  function renderTagsInput() {
    return (
      <section className="words-tags">
        <div className="form-section-title">Tags</div>
        <Form.Item name="tags">
          <TagsInput mode="tags" placeholder="Select tags for word" allowNew />
        </Form.Item>
      </section>
    );
  }

  function renderWordTranslationsInput() {
    return (
      <section className="words-translations">
        <div className="form-section-title">Translated Words</div>
        <WordTranslationItem
          name="translations"
          required={!individualEdit}
          min={2}
          disabled={individualEdit}
        />
      </section>
    );
  }

  function renderSentenceTranslationsInput() {
    return (
      <section className="sentence-translations">
        <div className="form-section-title">Translated Sentences</div>
        <Form.List name="sentences">
          {(
            parentFields,
            { add: parentAdd, remove: parentRemove },
            { parentErrors }
          ) => (
            <>
              <div className="form-list-items-horizontal-wrapper">
                {parentFields.map((parentField, parentIndex) => (
                  <Form.Item
                    required={false}
                    key={parentField.key}
                    className="form-list-items-horizontal"
                  >
                    <Form.Item name={[parentIndex, "image"]}>
                      <ImageInput
                        className="word-image-input"
                        disabled={individualEdit}
                      />
                    </Form.Item>
                    <SentenceTranslationItem
                      name={[parentIndex, "translations"]}
                      required={!individualEdit}
                      disabled={individualEdit}
                    />

                    <div className="valigned">
                      {DYNAMIC_SENTENCES && parentFields.length > 1 ? (
                        <Button
                          onClick={() => parentRemove(parentField.name)}
                          className="cursor-pointer danger"
                          danger
                          style={{ marginTop: -20, marginBottom: 10 }}
                        >
                          <MinusCircleOutlined /> Remove sentence above
                        </Button>
                      ) : null}
                      {individualEdit && (
                        <Button
                          onClick={() => {}}
                          className="cursor-pointer primary"
                          type="text"
                          style={{
                            marginTop: -20,
                            marginBottom: 10,
                            color: "green",
                          }}
                        >
                          <EditOutlined /> Edit sentence above
                        </Button>
                      )}
                    </div>
                  </Form.Item>
                ))}
              </div>
              {DYNAMIC_SENTENCES && (
                <Form.Item>
                  <Button
                    onClick={() => parentAdd(SENTENCE_INITIAL_VALUE)}
                    type="text"
                  >
                    <PlusOutlined /> Add another sentence
                  </Button>
                </Form.Item>
              )}
            </>
          )}
        </Form.List>
      </section>
    );
  }

  return loading ? (
    <PageLoader />
  ) : (
    <Page
      title={
        isEdit
          ? word?.name
            ? `${word?.name} | Update Word`
            : `Update Word - ${word?.id}`
          : "Create Word"
      }
    >
      <PageHeader title={isEdit ? "Update Word" : "Add Word"} />
      <Button 
      type="Button"
      size="large"
      onClick={(e) => {
        cloneWord(word.id).then(
          (result) => {
            if(result['word_id']) {
              let url = `${window.location.origin}/words/${result['word_id']}`;
              window.open(url, '_blank').focus();
            }
          }
        );
      }}>
        clone word
      </Button>
      <div></div>
      <Form
        className="word-form"
        onFinish={onSubmit}
        layout="vertical"
        initialValues={
          isEdit
            ? word
            : {
                translations: INITIAL_WORDS,
                sentences: [INITIAL_SENTENCES],
              }
        }
      >
        {renderImageInput()}
        {renderWordTranslationsInput()}
        {renderSentenceTranslationsInput()}

        <div className="button-group valigned space-between">
          {!isEdit ? (
            <div />
          ) : (
            <Button
              type="text"
              size="large"
              loading={loading}
              onClick={onDelete}
              className="danger"
              disabled={submitting}
            >
              Delete this Word
            </Button>
          )}
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            loading={submitting}
            className="wide"
            disabled={submitting}
          >
            {isEdit ? "Update this " : "Add"} Word
          </Button>
        </div>
      </Form>
    </Page>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getWord: (...args) => dispatch(getWord(...args)),
  addWord: (...args) => dispatch(addWord(...args)),
  updateWord: (...args) => dispatch(updateWord(...args)),
  deleteWord: (...args) => dispatch(deleteWord(...args)),
  cloneWord: (...args) => dispatch(cloneWord(...args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(WordForm));
