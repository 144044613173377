import { http } from "../util/http";
import Util from "../util";

export default class SubscriptionService {
  static getSubscriptions(filters) {
    return http.get(`/users/admin/subscriptions/?${Util.toQuery(filters)}`);
  }
  static deleteSubscription(id) {
    return http.delete(`users/admin/subscriptions/${id}/`);
  }
  static addSubscription(subscription) {
    return http.post("users/admin/subscriptions/", subscription);
  }
  static updateSubscription(id, data) {
    return http.patch(`users/admin/subscriptions/${id}/`, data);
  }
  static getSubscription(id) {
    return http.get(`users/admin/subscriptions/${id}/`);
  }
  static searchSchool(input) {
    return http.get(`users/admin/school_owners/?search=${input}`);
  }
}
