import { LOGIN_USER, LOGOUT_USER, } from './constants';
import LocalStorage from "../../data/LocalStorage";
import Constants from "../../util/Constants";

const INIT_STATE = {
	user: undefined,
	isLoggedIn: false
};

const Auth = (state = INIT_STATE, action) => {
	switch (action.type) {
		case LOGOUT_USER:
			LocalStorage.remove(Constants.KEY_TOKEN);
			return { ...state, ...INIT_STATE };

		case LOGIN_USER:
			return {
				...state,
				user: action.user,
				isLoggedIn: true
			};
		default:
			return state;
	}
};

export default Auth;
