import Language from "./Language";

const Data = {
  /**
   * Data Manipulations
   */
  mapWord: (word) => ({
    ...word,
    name:
      word.to_word ||
      word.translations?.find((t) => t.language === Language.ENGLISH.id)
        ?.translation,
    sentence: word.sentences?.length
      ? word.sentences[0].translations?.find(
          (t) => t.language === Language.ENGLISH.id
        )?.translation
      : word.to_sentence,
  }),
  mapWordItem: (word) => {
    word = Data.mapWord(word);
    return {
      ...word,
      name: `${word.id} — ${word.name} ${
        word.sentence ? `<<${word.sentence}>>` : ""
      }`,
    };
  },

  /**
   * Redux Getters
   */
  getLoggedIn: (state) => !!state.Auth.user,
  getUser: (state) => state.Auth.user,
};

export default Data;
