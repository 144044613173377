import React from 'react';
import { CSVLink } from 'react-csv';

const ExportCSV = (props) => {
    const { data } = props;
    const headers = [
      { label: "email", key: "email" },
      { label: "username", key: "username" },
      { label: "password", key: "password" },
      { label: "FirstName", key: "FirstName" },
      { label: "LastName", key: "LastName" },
      { label: "TeacherCode", key: "TeacherCode" },
      { label: "InstituteCode", key: "InstituteCode" },
      {label: "Status", key: "status" },
      {label: "Comments", key: ""}
      ];
return (
  <>
    {data?.length ?
    <>
        <h4 style={{marginTop:20,marginBottom:10}}>Export to CSV</h4>
      <CSVLink
        headers={headers}
        data={data}
        filename="results.csv"
        target="_blank"
      >
        Export
      </CSVLink>
      </>
      :
      <>
      </>
    }
    </>
);
}

export default ExportCSV;