const Constants = {
	/** Local Storage Keys */
	KEY_TOKEN: "token",

	/** App Constants */
	IS_PRODUCTION: process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging',
	LOGS_ENABLED: process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'staging',

	DEBOUNCE_VALUE: 350
}

export default Constants;
