import { connect } from "react-redux";
import { addTag, deleteTag, getTag, updateTag } from "../../redux/actions";
import { useEffect, useState } from "react";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import PageLoader from "../../components/PageLoader";
import { Button, Form, Input } from "antd";
import InputRules from "../../util/InputRules";
import Language from "../../data/Language";

function parseTag(tag) {
  return tag;
}

function formatTag(form) {
  return form;
}

function TagForm({
  id,
  addTag,
  deleteTag,
  getTag,
  updateTag,
  onDone,
  onCancel,
  submitting,
  setSubmitting,
}) {
  const [loading, setLoading] = useState(false);
  const isEdit = !!id;

  const [tag, setTag] = useState(null);

  useEffect(() => {
    if (!id) return;

    setLoading(true);
    getTag(id)
      .then((data) => (data ? setTag(parseTag(data)) : onCancel()))
      .finally(() => setLoading(false));
  }, [onCancel, getTag, id]);

  const onSubmit = (values) => {
    values = formatTag(values);

    setSubmitting(true);
    if (isEdit) {
      updateTag(id, values)
        .then((updated) => onDone(updated))
        .catch(() => {})
        .finally(() => setSubmitting(false));
    } else {
      addTag(values)
        .then((added) => onDone(added))
        .catch(() => {})
        .finally(() => setSubmitting(false));
    }
  };

  const onDelete = () => {
    ConfirmationDialog({
      title: "Are you sure you want to delete this tag?",
      onConfirm: () => {
        deleteTag(id).then(() => onDone(tag));
      },
    });
  };

  return loading ? (
    <PageLoader />
  ) : (
    <Form
      className="tag-form"
      onFinish={onSubmit}
      layout="vertical"
      initialValues={
        isEdit
          ? tag
          : {
              translations: [
                { language: Language.ENGLISH.id },
                { language: Language.JAPANESE.id },
              ],
            }
      }
    >
      <Form.Item name="name" rules={InputRules.required("Enter name")}>
        <Input />
      </Form.Item>

      <div className="form-buttons">
        {!isEdit ? (
          <div />
        ) : (
          <Button
            type="text"
            size="large"
            loading={loading}
            onClick={onDelete}
            className="danger"
            disabled={submitting}
          >
            Delete
          </Button>
        )}
        <Button
          type="primary"
          htmlType="submit"
          size="large"
          loading={submitting}
          className="wide"
          disabled={submitting}
        >
          {isEdit ? "Update this " : "Add"} Tag
        </Button>
      </div>
    </Form>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getTag: (...args) => dispatch(getTag(...args)),
  addTag: (...args) => dispatch(addTag(...args)),
  updateTag: (...args) => dispatch(updateTag(...args)),
  deleteTag: (...args) => dispatch(deleteTag(...args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TagForm);
