import Logger from "../../data/Logger";
import QnaService from "../../services/QnaActivity";
import Notifications from "../../util/Notifications";

export const getAdventure = (id) => () =>
  new Promise((resolve) => {
    (Array.isArray(id)
      ? Promise.all(id.map((i) => QnaService.get(i)))
      : QnaService.get(id)
    )
      .then((r) => resolve(r))
      .catch(() => resolve(false));
  });

export const getNextPageAdventures = (next) => () =>
  new Promise((resolve) => {
    QnaService.getNextPage(next)
      .then((r) => resolve(r))
      .catch(() => resolve(false));
  });

export const addQna = (data) => () =>
  new Promise((resolve, reject) => {
    QnaService.add(data)
      .then((r) => {
        Notifications.success("QnA Activity added successfully");
        resolve(r);
      })
      .catch((e) => {
        Logger.exception(e);
        reject(e);
      });
  });

export const updateQna = (id, data) => () =>
  new Promise((resolve, reject) => {
    QnaService.update(id, data)
      .then((r) => {
        Notifications.success("Updated QnA Activity");
        resolve(r);
      })
      .catch((e) => {
        Logger.exception(e);
        reject(e);
      });
  });

export const deleteQna = (id) => () =>
  new Promise((resolve) => {
    Notifications.async(QnaService.delete(id), {
      loadingMessage: `Deleting QnA Activity ${id}`,
      successMessage: "Deleted QnA Activity successfully",
      errorMessage: "Failed to delete the QnA Activity",
    })
      .then(() => resolve(true))
      .catch((e) => {
        Logger.exception(e);
        resolve(false);
      });
  });

export const getQna = (filters) => () =>
  new Promise((resolve) => {
    QnaService.paginate(filters)
      .then((r) => resolve(r))
      .catch(() => {
        Notifications.error("Failed to get QnA Activity");
        resolve(false);
      });
  });

export const searchAdventures = (input) => () =>
  new Promise((resolve) => {
    QnaService.search(input)
      .then((r) => resolve(r))
      .catch(() => {
        Notifications.error("Failed to get QnA Activity");
        resolve(false);
      });
  });

/**
 *  Adventure Assigned
 */
export const getAssignedAdventure = (id) => () =>
  new Promise((resolve) => {
    QnaService.get(id)
      .then((r) => resolve(r))
      .catch(() => resolve(false));
  });

export const addAssignedAdventure = (data) => () =>
  new Promise((resolve, reject) => {
    QnaService.add(data)
      .then((r) => {
        Notifications.success("QnA Activity added successfully");
        resolve(r);
      })
      .catch((e) => {
        Logger.exception(e);
        reject(e);
      });
  });

export const updateAssignedAdventure = (id, data) => () =>
  new Promise((resolve, reject) => {
    QnaService.update(id, data)
      .then((r) => {
        Notifications.success("Updated QnA Activity");
        resolve(r);
      })
      .catch((e) => {
        Logger.exception(e);
        reject(e);
      });
  });

export const deleteAssignedAdventure = (id) => () =>
  new Promise((resolve) => {
    Notifications.async(QnaService.delete(id), {
      loadingMessage: `Deleting adventure ${id}`,
      successMessage: "Deleted adventure successfully",
      errorMessage: "Failed to delete the QnA Activity",
    })
      .then(() => resolve(true))
      .catch((e) => {
        Logger.exception(e);
        resolve(false);
      });
  });

export const getAssignedAdventures = (filters) => () =>
  new Promise((resolve) => {
    QnaService.paginate(filters)
      .then((r) => resolve(r))
      .catch(() => {
        Notifications.error("Failed to get QnA Activity");
        resolve(false);
      });
  });
