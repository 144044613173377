import Page from "../../components/layout/Page";
import { Link } from "react-router-dom";
import { Dropdown, Menu } from "antd";
import Links from "../../navigation/Links";
import { DownOutlined } from "@ant-design/icons";
import TableView from "../../components/TableView";
import { getUnityLogURL, getUnityLogs } from "../../redux/actions";
import { connect } from "react-redux";
import Util from "../../util";
import { useState } from "react";
import { withRouter } from "react-router";
import UnityLogDialog from "./UnityLogDialog";

const TableColumns = ({ onItemClick } = {}) => [
  {
    title: "Id",
    dataIndex: "id",
    fixed: "left",
    width: 80,
  },
  {
    title: "UserName",
    dataIndex: "user_details",
    ellipsis: true,
    fixed: "left",
    width: 130,
    render: (value) => {
      return value.username;
    },
  },
  {
    title: "Date",
    dataIndex: "date_created",
    ellipsis: true,
    fixed: "left",
    width: 150,
    render: (item) => {
      return Util.formatDate(item);
    },
  },
  {
    title: "OS Ver",
    dataIndex: "os_version",
    ellipsis: true,
    fixed: "left",
    width: 200,
  },
  {
    title: "build Ver",
    dataIndex: "build_version",
    ellipsis: true,
    width: 100,
  },
  {
    title: "Message",
    dataIndex: "message",
    ellipsis: true,
    fixed: "left",
  },
  {
    title: "Action",
    key: "action",
    width: 160,
    fixed: "right",
    render: (unityLog) => (
      <span className="table-action-buttons">
        <Link
          className="text-button view"
          style={{ color: "white" }}
          onClick={() => onItemClick(unityLog)}
        >
          View
        </Link>
        <Dropdown
          trigger={["click"]}
          className="table-more-button"
          overlay={
            <Menu className="actions-menu">
              <Menu.Item
                className="negative delete"
                onClick={() => {
                  getUnityLogURL(unityLog?.id).then((downloadUrl) => {
                    window.open(downloadUrl?.url);
                  });
                }}
              >
                Download Logs
              </Menu.Item>
            </Menu>
          }
        >
          <span className="text-button ant-dropdown-link">
            More <DownOutlined />
          </span>
        </Dropdown>
      </span>
    ),
  },
];

function UnityLogs({ getLogs, history }) {
  const [unityLogDialog, setUnityLogDialog] = useState(false);

  return (
    <Page title="Unity App Logs">
      <TableView
        title="Unity Logs"
        columns={TableColumns}
        getItems={getLogs}
        addButton={false}
        Dialog={UnityLogDialog}
        getLink={(s) => (s ? Links.UnityLog(s) : Links.UNITY_LOGS)}
        isSearchable={false}
        showTotal={true}
        isCheckable={false}
      />

      <UnityLogDialog
        visible={unityLogDialog}
        hide={() => setUnityLogDialog(false)}
        onDone={() => {
          setUnityLogDialog(false);
          history.go(0);
        }}
      />
    </Page>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getLogs: (...args) => dispatch(getUnityLogs(...args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UnityLogs));
