import React from 'react';
import { Modal, Button } from 'antd';

export default function Dialog({
  title,
  visible,
  onCancel,
  children,
  size,
  reset = true,
  className = '',
  closable = true,
  flag,
  Descriptions,
  setConfirmationMsg,
}) {
  return (
    <>
      <Modal
        title={title}
        visible={visible}
        onCancel={closable ? onCancel : undefined}
        footer={null}
        centered
        wrapClassName={`${size ? ` modal-${size}` : ''} ${className}`}
        destroyOnClose={reset}
        keyboard={closable}
        width={800}
      >
        {children}
        {flag && (
          <Button
            style={{ position: 'relative', marginTop: '5%', left: '86%' }}
            className=""
            type="primary"
            onClick={setConfirmationMsg}
          >
            Restore
          </Button>
        )}
      </Modal>
    </>
  );
}
