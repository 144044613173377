import { connect } from "react-redux";
import {
	addWordTranslation,
	deleteWordTranslation,
	getWordTranslation,
	updateWordTranslation
} from "../../redux/actions";
import { useEffect, useState } from "react";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import PageLoader from "../../components/PageLoader";
import { Button, Form, Input } from "antd";
import InputRules from "../../util/InputRules";
import AudioInput from "../../components/form/AudioInput";
import LanguageInput from "../../components/form/LanguageInput";
import WordsInput from "../../components/form/WordsInput";

function parseWord(word) {
	return word;
}

function formatWord(form) {
	return form;
}

function WordTranslationForm({
	                             id,
	                             item,
	                             addWordTranslation,
	                             deleteWordTranslation,
	                             getWordTranslation,
	                             updateWordTranslation,
	                             onDone,
	                             onCancel,
	                             submitting,
	                             setSubmitting
                             }) {
	const [loading, setLoading] = useState(false);
	const isEdit = !!id;

	const [word, setWord] = useState(item ? parseWord(item) : null);

	useEffect(() => {
		if (!id || item) return;

		setLoading(true);
		getWordTranslation(id)
			.then(data => data ? setWord(parseWord(data)) : onCancel())
			.finally(() => setLoading(false))
	}, [item, onCancel, getWordTranslation, id]);

	const onSubmit = (values) => {
		values = formatWord(values);

		setSubmitting(true);
		if (isEdit) {
			updateWordTranslation(id, values)
				.catch(() => {})
				.finally(() => setSubmitting(false));
		} else {
			addWordTranslation(values)
				.then(added => onDone(added))
				.catch(() => {})
				.finally(() => setSubmitting(false));
		}
	}

	const onDelete = () => {
		ConfirmationDialog({
			title: 'Are you sure you want to delete this word translation?',
			onConfirm: () => {
				deleteWordTranslation(id).then(() => onDone(word));
			}
		});
	}

	return loading ? <PageLoader /> : (
		<Form
			className="word-form" onFinish={onSubmit} layout="vertical"
			initialValues={isEdit ? word : {}}
		>
			<Form.Item label="Word" name="word" rules={InputRules.required()}>
				<WordsInput multiple={false} />
			</Form.Item>
			<Form.Item
				label="Translation" name="translation"
				rules={InputRules.required("Please input translation")}
			>
				<Input placeholder="Word translation" />
			</Form.Item>
			<Form.Item label="Alternate translation" name="alternative_translation">
				<Input placeholder="Alternate word translation" />
			</Form.Item>
			<Form.Item label="Audio file" name="audio">
				<AudioInput />
			</Form.Item>
			<LanguageInput label="Language" name="language" required={true} />

			<div className="form-buttons">
				{!isEdit ? <div /> : (
					<Button
						type="text" size="large" loading={loading} onClick={onDelete} className="danger"
						disabled={submitting}
					>
						Delete
					</Button>
				)}
				<Button
					type="primary" htmlType="submit" size="large" loading={submitting} className="wide"
					disabled={submitting}
				>
					{isEdit ? "Update this " : "Add"} Word
				</Button>
			</div>
		</Form>
	)
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	getWordTranslation: (...args) => dispatch(getWordTranslation(...args)),
	addWordTranslation: (...args) => dispatch(addWordTranslation(...args)),
	updateWordTranslation: (...args) => dispatch(updateWordTranslation(...args)),
	deleteWordTranslation: (...args) => dispatch(deleteWordTranslation(...args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WordTranslationForm);
