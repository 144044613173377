import Page from "../../components/layout/Page";
import { connect } from "react-redux";
import { Dropdown, Menu } from "antd";
import Links from "../../navigation/Links";
import { DownOutlined } from "@ant-design/icons";
import TableView from "../../components/TableView";
import { deleteSentenceTranslation, getSentenceTranslations } from "../../redux/actions";
import SentenceTranslationDialog from "./SentenceTranslationDialog";
import Language from "../../data/Language";

const TableColumns = ({ onItemClick, onDelete } = {}) => [{
	title: 'Sentence',
	dataIndex: 'sentence',
	fixed: 'left',
	width: 100
}, {
	title: 'Language',
	fixed: 'left',
	render: (item) => Object.values(Language).find(l => l.id === item.language)?.name,
}, {
	title: 'Action',
	key: 'action',
	width: 130,
	fixed: 'right',
	render: (word) => (
		<span className="table-action-buttons">
	      <div className="text-button mr-10" onClick={() => onItemClick(word)}>View</div>
	      <Dropdown trigger={['click']} className="table-more-button" overlay={(
		      <Menu className="actions-menu">
			      <Menu.Item className="negative" onClick={() => onDelete(word)}>
				      Delete
			      </Menu.Item>
		      </Menu>
	      )}>
	          <span className="text-button ant-dropdown-link">More <DownOutlined /></span>
	      </Dropdown>
	  </span>
	)
}];

function SentenceTranslations({ getSentenceTranslations, deleteSentenceTranslation }) {
	return (
		<Page title="Sentence Translations">
			<TableView
				title="Sentence Translations"
				columns={TableColumns}
				getItems={getSentenceTranslations}
				deleteItem={deleteSentenceTranslation}
				addButton={{ text: "Add Sentence Translation" }}
				Dialog={SentenceTranslationDialog}
				getLink={s => s ? Links.SentenceTranslation(s) : Links.SENTENCE_TRANSLATIONS}
			/>
		</Page>
	)
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
	getSentenceTranslations: (...args) => dispatch(getSentenceTranslations(...args)),
	deleteSentenceTranslation: (...args) => dispatch(deleteSentenceTranslation(...args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SentenceTranslations);
