import AnalyticService from "../../services/AnalyticService";
import Notifications from "../../util/Notifications";

export const getTagAnalytics = (filters) => () =>
  new Promise((resolve) => {
    AnalyticService.getTagAnalytics(filters)
      .then((r) => {
        resolve(r);
      })
      .catch(() => {
        Notifications.error("Failed to get Analytics");
        resolve(false);
      });
  });
