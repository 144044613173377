import Dialog from "../../components/Dialog";
import { useState } from "react";
import SentenceForm from "./SentenceForm";

export default function SentenceDialog({ visible, hide, item, preloaded, ...props }) {
	const [loading, setLoading] = useState(false);

	return (
		<Dialog
			title={item ? "Update Sentence" : "Add Sentence"} size="xlarge"
			visible={visible} onCancel={hide} closable={!loading}
		>
			<SentenceForm
				{...props} id={item?.id}
				item={preloaded ? item : undefined}
				submitting={loading}
				setSubmitting={l => setLoading(l)}
				onCancel={hide}
			/>
		</Dialog>
	)
}
