import Page from "../../components/layout/Page";
import { connect } from "react-redux";
import { Dropdown, Menu } from "antd";
import Links from "../../navigation/Links";
import { DownOutlined } from "@ant-design/icons";
import TableView from "../../components/TableView";
import { deleteTag, getTags } from "../../redux/actions";
import TagDialog from "./TagDialog";

const TableColumns = ({ onItemClick, onDelete } = {}) => [
  {
    title: "Id",
    dataIndex: "id",
    fixed: "left",
    width: 92,
  },
  {
    title: "Name",
    dataIndex: "name",
    ellipsis: true,
    defaultSortOrder: "descend",
    sorter: (a, b) => b > a,
  },
  {
    title: "Action",
    key: "action",
    width: 180,
    fixed: "right",
    render: (word) => (
      <span className="table-action-buttons">
        <div className="text-button mr-10" onClick={() => onItemClick(word)}>
          View
        </div>
        <Dropdown
          trigger={["click"]}
          className="table-more-button"
          overlay={
            <Menu className="actions-menu">
              <Menu.Item className="negative" onClick={() => onDelete(word)}>
                Delete
              </Menu.Item>
            </Menu>
          }
        >
          <span className="text-button ant-dropdown-link">
            More <DownOutlined />
          </span>
        </Dropdown>
      </span>
    ),
  },
];

function Tags({ getTags, deleteTag }) {
  return (
    <Page title="Tags">
      <TableView
        title="Tags"
        columns={TableColumns}
        getItems={getTags}
        deleteItem={deleteTag}
        addButton={{ text: "Add Tag" }}
        Dialog={TagDialog}
        getLink={(s) => (s ? Links.Tag(s) : Links.TAGS)}
      />
    </Page>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getTags: (...args) => dispatch(getTags(...args)),
  deleteTag: (...args) => dispatch(deleteTag(...args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Tags);
