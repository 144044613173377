import React from 'react';
import PageLoader from '../PageLoader';
import Helmet from 'react-helmet';
import { withRouter } from 'react-router';

function Page({ loading, className = '', title, ...props }) {
	return loading ? <PageLoader /> : (
		<div className={`page-root ${className}`}>
			<Helmet>
				<title>{title}</title>
			</Helmet>
			{props.children}
		</div>
	);
}

export default withRouter(Page);
