import { REHYDRATE } from 'redux-persist/lib/constants';
import { LOGOUT_USER } from "../auth/constants";

const INIT_STATE = {
	isLoadingComplete: false
};

const App = (state = INIT_STATE, action) => {
	switch (action.type) {
		case REHYDRATE:
			return { ...state, isLoadingComplete: true, };
		case LOGOUT_USER:
			return state;

		default:
			return state;
	}
};

export default App;
