import { http } from "../util/http";
import Util from "../util";

export default class BankTransferService {
  static getRequests(filters) {
    return http.get(
      `users/bank_transfer/requests/list_all_requests/?${Util.toQuery(filters)}`
    );
  }
  static approveRequest(id) {
    return http.patch(`users/bank_transfer/requests/${id}/mark_approved/`);
  }
  static rejectRequest(id) {
    return http.patch(`users/bank_transfer/requests/${id}/mark_rejected/`);
  }
}
