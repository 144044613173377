import Page from "../../components/layout/Page";
import { connect } from "react-redux";
import { Dropdown, Menu, Button } from "antd";
import Links from "../../navigation/Links";
import {
  AlertFilled,
  AlertOutlined,
  DeleteFilled,
  DeleteOutlined,
  DownOutlined,
} from "@ant-design/icons";
import TableView from "../../components/TableView";
import { clearCache, deleteBundle, getBundles } from "../../redux/bundles/actions";
import BundleDialog from "./BundleDialog";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import { useState } from "react";

const TableColumns = ({ onItemClick, onDelete } = {}) => [
  {
    title: "Id",
    dataIndex: "id",
    fixed: "left",
    width: 92,
  },
  {
    title: "Name",
    dataIndex: "name",
    ellipsis: true,
  },
  {
    title: "Adventures",
    render: (item) => item.adventures?.length || 0,
  },
  {
    title: "Action",
    key: "action",
    width: 180,
    fixed: "right",
    render: (word) => (
      <span className="table-action-buttons">
        <div className="text-button mr-10" onClick={() => onItemClick(word)}>
          View
        </div>
        <Dropdown
          trigger={["click"]}
          className="table-more-button cursor-pointer"
          overlay={
            <Menu className="actions-menu">
              <Menu.Item className="negative" onClick={() => onDelete(word)}>
                Delete
              </Menu.Item>
            </Menu>
          }
        >
          <span className="text-button ant-dropdown-link">
            More <DownOutlined />
          </span>
        </Dropdown>
      </span>
    ),
  },
];

function Bundles({ getBundles, deleteBundle, clearCache }) {
  const [loading, setLoading] = useState(false);

  const clearingCache = () => {
    ConfirmationDialog({
      title: "Are you sure you want to clear the cache?",
      onConfirm: () => {
        setLoading(true);
        clearCache()
          .catch((e) => {})
          .finally(() => setLoading(false));
      },
    });
  };

  return (
    <Page title="Bundles">
      <TableView
        title="Bundles"
        columns={TableColumns}
        getItems={getBundles}
        deleteItem={deleteBundle}
        addButton={{ text: "Add Bundle" }}
        searchLabel="Search Bundle Name"
        extraTableButtons={
          <Button
            danger
            size="large"
            type="outlined"
            onClick={() => clearingCache()}
            key="11"
          >
            <DeleteOutlined />
            Clear Cache
          </Button>
        }
        Dialog={BundleDialog}
        getLink={(s) => (s ? Links.Bundle(s) : Links.BUNDLES)}
      />
    </Page>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getBundles: (...args) => dispatch(getBundles(...args)),
  deleteBundle: (...args) => dispatch(deleteBundle(...args)),
  clearCache: (...args) => dispatch(clearCache(...args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Bundles);
