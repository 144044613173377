import { Link } from "react-router-dom";
import { Button, Input, PageHeader } from "antd";
import Table from "./Table";
import { useCallback, useEffect, useState } from "react";
import ConfirmationDialog from "./ConfirmationDialog";
import { useParams, withRouter } from "react-router";
import Util from "../util";
import debounce from "lodash/debounce";
import Constants from "../util/Constants";

function TableView({
  title,
  columns,
  getItems,
  mapItem,
  getFilters,
  deleteItem,
  renderFilters,
  parseFilters,
  addButton = { text: "Add" },
  Dialog,
  getLink,
  extraTableButtons,
  isSearchable = true,
  isCheckable = true,
  searchLabel = "Search...",
  showTotal = false,
  ...props
}) {
  const { id } = useParams();
  const [selected, setSelected] = useState({ keys: null, items: null });
  const [deleting, setDeleting] = useState(false);
  const [dialogVisible, setDialogVisible] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [refresh, setRefresh] = useState(0);
  const [search, setSearch] = useState(null);
  const [tableFilters, setTableFilters] = useState(null);
  const [tableFiltersTemp, setTableFiltersTemp] = useState({});

  useEffect(() => {
    if (id) {
      setSelectedItem({ id });
      setDialogVisible(true);
    } else {
      setSelectedItem(null);
      setDialogVisible(false);
    }
  }, [id]);

  const onItemClick = !Dialog
    ? null
    : (item) => {
        if (item) Util.updateLocation(getLink(item), props.history);
        setSelectedItem(item);
        setDialogVisible(!!item);
      };

  const onDialogActionDone = !Dialog
    ? null
    : (item) => {
        onDialogClose();
        item && setRefresh(refresh + 1);
      };

  const onSelectionChange = (selected) => setSelected(selected);

  const onDeleteAll = () => {
    ConfirmationDialog({
      title: "Are you sure you want to delete selected items?",
      onConfirm: () => {
        selected.forEach((item) => {
          setDeleting(true);
          deleteItem(item.id)
            .then(() => setRefresh(refresh + 1))
            .finally(() => {
              setDeleting(false);
            });
        });
        setSelected(0);
      },
    });
  };

  const onDelete = (item) => {
    ConfirmationDialog({
      title: `Are you sure you want to delete this? (${
        item.name ? `${item.name} - ` : ""
      }${item.id})`,
      onConfirm: () => {
        setDeleting(true);
        deleteItem(item.id)
          .then(() => setRefresh(refresh + 1))
          .finally(() => setDeleting(false));
      },
    });
  };

  const onDialogClose = () => {
    onItemClick(false);
    props.history.push(getLink(null));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearch = useCallback(
    debounce((e) => setSearch(e.target.value), Constants.DEBOUNCE_VALUE),
    []
  );

  const onTableFiltersChange = (changed) =>
    setTableFiltersTemp((f) => ({ ...f, ...changed }));
  const onTableFiltersSearch = () =>
    setTableFilters(parseFilters(tableFiltersTemp));
  const onTableFiltersReset = () => {
    setSearch(null);
    setTableFilters({});
    setTableFiltersTemp({});
  };

  return (
    <div className="table-page">
      <PageHeader
        title={title}
        extra={[
          extraTableButtons,
          selected?.length > 0 && (
            <Button
              size="large"
              type="danger"
              onClick={onDeleteAll}
              key="2"
              disabled={deleting}
              loading={deleting}
            >
              Delete Selected
            </Button>
          ),
          addButton.link ? (
            <Link to={addButton.link} key="1">
              <Button size="large" type="primary">
                {addButton.text}
              </Button>
            </Link>
          ) : (
            <Button
              size="large"
              type="primary"
              onClick={() => setDialogVisible(true)}
              key="1"
            >
              {addButton.text}
            </Button>
          ),
          isSearchable ? (
            <Input
              placeholder={searchLabel}
              size="large"
              allowClear
              onChange={onSearch}
              style={{ width: 200 }}
              key="3"
            />
          ) : (
            <div></div>
          ),
        ]}
      >
        {renderFilters && (
          <div className="table-filters">
            {renderFilters(
              tableFiltersTemp,
              onTableFiltersChange,
              onTableFiltersSearch,
              onTableFiltersReset
            )}
          </div>
        )}
      </PageHeader>
      <Table
        search={search}
        refresh={refresh}
        mapItem={mapItem}
        columns={columns}
        onDelete={onDelete}
        getItems={getItems}
        getFilters={getFilters}
        tableFilters={tableFilters}
        onSelectionChange={onSelectionChange}
        onItemClick={onItemClick}
        isRowCheckable={isCheckable}
        showTotal={showTotal}
      />
      {Dialog && (
        <Dialog
          item={selectedItem}
          onDone={onDialogActionDone}
          visible={dialogVisible}
          hide={onDialogClose}
        />
      )}
    </div>
  );
}

export default withRouter(TableView);
