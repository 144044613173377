import Page from "../../components/layout/Page";
import { Tag } from "antd";
import TagsInput from "../../components/form/TagsInput";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Button, Image } from "antd";
import Util from "../../util";
import { CloseCircleOutlined } from "@ant-design/icons";
import { useState } from "react";
import Data from "../../data";
import SimpleTableView from "../../components/SimpleTableView";
import { getWords, updateBulkTag } from "../../redux/tag-update/actions";
import Notifications from "../../util/Notifications";
import ConfirmationDialog from "../../components/ConfirmationDialog";

const TableColumns = ({ filters, onDelete } = {}) => [
  {
    title: "Id",
    dataIndex: "id",
    fixed: "left",
    width: 82,
  },
  {
    title: "Word",
    dataIndex: "name",
    ellipsis: true,
    fixed: "left",
    width: 130,
  },
  {
    title: "Sentence",
    dataIndex: "sentence",
    ellipsis: true,
    fixed: "left",
    width: 250,
  },
  {
    title: "Tags",
    dataIndex: "tags",
    ellipsis: true,
    fixed: "left",
    filters: filters?.tags?.map((t) => ({ value: t.id, text: t.name })),
    filterMultiple: true,
    onFilter: (value, record) => !!record.tags?.find((t) => t.id === value),
    render: (value) =>
      value?.map((v) => v.name).map((s, i) => <Tag key={i}>{s}</Tag>),
  },
  {
    title: "Image",
    name: "has_image",
    width: 80,
    filters: [
      {
        text: "Has Image",
        value: true,
      },
      {
        text: "No Image",
        value: false,
      },
    ],
    filterMultiple: false,
    onFilter: (value, record) => (value ? !!record.image : !record.image),
    render: (value) =>
      !value.image ? (
        <CloseCircleOutlined />
      ) : (
        <Image
          src={value.image}
          height={50}
          width={50}
          style={{ objectFit: "contain" }}
        />
      ),
  },
];

function BulkTag({ getWords, updateTags }) {
  const [loading, setLoading] = useState(false);

  function updateTag(values) {
    setLoading(true);
    updateTags(
      values?.tagsQuery?.map((t) => t.id),
      values?.tagsAdd?.map((t) => t.id),
      values?.tagsRemove?.map((t) => t.id)
    )
      .catch((e) => {
        const status = Util.getResponseStatus(e);
        if ([400, 401, 403].includes(status)) {
          Notifications.error("Tags Updated Successfully");
        } else {
          Notifications.error("Failed to Update. Please try again.");
        }
      })
      .finally(() => setLoading(false));
  }

  const onUpdate = (values) => {
    ConfirmationDialog({
      title:
        "Are you sure you want to Update these tags? Once done, we can't revert the change.",
      onConfirm: () => {
        updateTag(values);
      },
    });
  };

  function renderWordsList() {
    //if (renderTable === false) return;

    return (
      <div>
        <SimpleTableView
          onUpdate={onUpdate}
          title="Tag Update"
          columns={TableColumns}
          getItems={getWords}
          mapItem={Data.mapWord}
          isAnalyticTable={false}
          isBulkTagTable={true}
          renderFilters={(values, onChange, onSearch, onReset, onUpdate) => (
            <>
              <h4 style={{ marginLeft: 400 }}>
                <span style={{ color: "#3B62C3" }}>Step 1:</span> Search words
                by Tags
              </h4>
              <TagsInput
                value={values.tags}
                onChange={(tags) => {
                  values.tagsQuery = tags;
                  onChange({ tags });
                }}
              />
              <Button
                size="large"
                style={{ marginLeft: 10 }}
                onClick={onSearch}
                disabled={Util.isEmpty(values)}
              >
                Search
              </Button>
              <>
                <h4>
                  <span style={{ color: "#3B62C3" }}>Step 2:</span> Update Tags
                </h4>
                <TagsInput
                  placeholder="Add Tags"
                  value={values.tagsAdd}
                  onChange={(tagsAdd) => {
                    onChange({ tagsAdd });
                  }}
                />
                <TagsInput
                  placeholder="Remove Tags"
                  value={values.tagsRemove}
                  onChange={(tagsRemove) => {
                    onChange({ tagsRemove });
                  }}
                />
                <Button
                  size="large"
                  style={{ marginLeft: 10 }}
                  onClick={onUpdate}
                  disabled={Util.isEmpty(values)}
                >
                  Update
                </Button>
              </>
            </>
          )}
          parseFilters={(filters) => ({
            ...filters,
            tags: filters.tags?.map((t) => t.id),
          })}
        />
      </div>
    );
  }

  return <Page title="Tag Update">{renderWordsList()}</Page>;
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getWords: (...args) => dispatch(getWords(...args)),
  updateTags: (...args) => dispatch(updateBulkTag(...args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BulkTag));
