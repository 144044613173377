import { http } from "../util/http";
import Util from "../util";

export default class StudentRewardService {
  static paginate(filters) {
    return http.get(`/users/admin/students/?${Util.toQuery(filters)}`);
  }

  static async search(input) {
    input = "";
    return http.get(`/users/admin/students/`);
  }

  static get(id) {
    return http.get(`/users/admin/students/${id}`);
  }

  static create(rewards) {
    return http.post("/users/student/profile/rewards/", rewards);
  }
}
