import { connect } from "react-redux";
import Page from "../../components/layout/Page";
import { loginUser } from "../../redux/auth/actions";
import { Button, Form, Input } from "antd";
import { useState } from "react";
import Notifications from "../../util/Notifications";
import InputRules from "../../util/InputRules";
import { withRouter } from "react-router";
import Util from "../../util";

function Login({ loginUser, history }) {
  const [loading, setLoading] = useState(false);

  const onSubmit = ({ username, password }) => {
    if (!username || !password) {
      return Notifications.error("Please enter login details");
    }

    setLoading(true);
    loginUser({ username, password }, history)
      .catch((e) => {
        const status = Util.getResponseStatus(e);
        if ([400, 401, 403].includes(status)) {
          Notifications.error("Username/Password don't match");
        } else {
          Notifications.error("Failed to login. Please try again.");
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <Page title="Login" className="full-page centered">
      <Form onFinish={onSubmit} className="login-form card" layout="vertical">
        <h2>Welcome Back.</h2>
        <h4 style={{ marginBottom: 24, marginTop: -2 }} className="font-normal">
          Login using credentials below
        </h4>

        <Form.Item label="Username" name="username" rules={InputRules.username}>
          <Input size="large" />
        </Form.Item>

        <Form.Item label="Password" name="password" rules={InputRules.password}>
          <Input.Password size="large" />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            size="large"
            htmlType="submit"
            loading={loading}
            disabled={loading}
            style={{ width: "100%" }}
          >
            Login
          </Button>
        </Form.Item>
      </Form>
    </Page>
  );
}

const mapDispatchToProps = (dispatch) => ({
  loginUser: (...args) => dispatch(loginUser(...args)),
});

export default connect(null, mapDispatchToProps)(withRouter(Login));
