import React, { useCallback, useEffect, useState } from "react";
import { Table as AntTable, Typography } from "antd";
import Util from "../util";

const PER_PAGE = 20;

export default function Table({
  search,
  refresh,
  columns,
  getFilters,
  getItems,
  mapItem,
  items,
  onDelete,
  onItemClick,
  onSelectionChange,
  tableFilters,
  isAnalyticTable = false,
  isRowCheckable = true,
  isBulkTagTable = false,
  showTotal = false,
}) {
  const [itemList, setItemList] = useState(items || []);
  const [selected, setSelected] = useState({ items: null, keys: null });
  const [loading, setLoading] = useState(false);
  const [fetchingFilters, setFetchingFilters] = useState(false);
  const [headerFilters, setHeaderFilters] = useState(null);
  const [total, setTotal] = useState(-1);
  const [filters, setFilters] = useState({
    page: 1,
    limit: PER_PAGE,
  });

  const fetchItems = useCallback(
    (newFilters = {}) => {
      setLoading(true);
      const offset =
        ((newFilters.page || filters.page) - 1) *
        (newFilters.limit || filters.limit);
      getItems({
        offset,
        ...filters,
        ...newFilters,
        search,
        ...(tableFilters || {}),
      })
        .then((data) => {
          if (data.results === undefined) {
            setItemList(
              mapItem ? data.top_5_words?.map(mapItem) : data.top_5_words
            );
            setTotal(data.total_view_count);
          } else {
            setItemList(mapItem ? data.results?.map(mapItem) : data.results);
            setTotal(data.count);
          }
        })
        .catch(() =>
          setFilters((filters) => ({ ...filters, page: filters.page - 1 }))
        )
        .finally(() => setLoading(false));
    },
    [tableFilters, mapItem, search, getItems, filters]
  );

  /** Fetch table header filters if specified */
  useEffect(() => {
    if (!getFilters) return;

    setFetchingFilters(true);
    getFilters()
      .then((filters) => setHeaderFilters(filters))
      .finally(() => setFetchingFilters(false));
  }, [getFilters]);

  useEffect(
    () => fetchItems(filters),
    [fetchItems, getItems, filters, refresh, search]
  );
  useEffect(() => items?.length && setItemList(items), [items]);

  const onPageChange = (page, limit) => {
    setFilters((filters) => ({ ...filters, page, limit }));
    setSelected({ items: null, keys: null });
  };

  const onChange = (pagination, mFilters, sorter, extra) => {
    if (onPageChange && filters.page !== pagination.current) {
      return onPageChange(pagination.current, pagination.pageSize);
    }

    const newFilters = { limit: pagination.pageSize };
    if (sorter.column) {
      newFilters.sortField = sorter.column.dataIndex || sorter.column.name;
      newFilters.sortOrder = sorter.order;
    }

    if (!Util.isEmpty(mFilters)) {
      for (const key of Object.keys(mFilters)) {
        const value = mFilters[key];
        if (!value) continue;
        if (Array.isArray(value)) {
          newFilters[key] = value?.length === 1 ? value[0] : value;
        } else {
          newFilters[key] = value;
        }
      }
    }
    setFilters((filters) => ({ ...filters, ...newFilters }));
  };

  const rowSelectionCriteria = () => {
    if (isRowCheckable) {
      return {
        selectedRowKeys: selected.keys,
        onChange: (keys, items) => {
          setSelected({ keys, items });
          onSelectionChange(items);
        },
      };
    }
  };

  const getPagination = () => {
    if (isAnalyticTable) return;
    return {
      position: "bottomRight",
      total: total === -1 ? undefined : total,
      pageSize: filters.limit,
    };
  };

  const getScroll = () => {
    if (isAnalyticTable) return;
    return { y: 600, x: "100%" };
  };

  const renderTagsTableView = () => {
    if (isAnalyticTable) {
      return (
        <>
          <Typography variant="h4" component="h4">
            Total view count : {total}
          </Typography>
          <Typography variant="h1" component="h1">
            Top Words
          </Typography>
        </>
      );
    }
    if (isBulkTagTable) {
      return (
        <>
          <Typography variant="h4" component="h4">
            Words Count : {total}
          </Typography>
        </>
      );
    }

    if (showTotal) {
      return (
        <>
          <Typography variant="p" component="p">
            Total : {total}
          </Typography>
        </>
      );
    }
  };

  return (
    <>
      {renderTagsTableView()}
      <AntTable
        title={null}
        pagination={getPagination()}
        columns={columns({ onItemClick, onDelete, filters: headerFilters })}
        dataSource={itemList?.map((i, index) => ({ key: i.id || index, ...i }))}
        scroll={getScroll()}
        showHeader
        rowSelection={rowSelectionCriteria()}
        size="default"
        bordered={false}
        loading={loading || fetchingFilters}
        onChange={onChange}
      />
    </>
  );
}
