import { connect } from "react-redux";
import AsyncSelect from "./AsyncSelect";
import { getStudents } from "../../redux/actions";

function StudentsInput({ getStudents, ...props }) {
	return (
		<AsyncSelect {...props} getItems={getStudents} labelField="name" valueField="id" />
	);
}

const mapDispatchToProps = (dispatch) => ({
	getStudents: (...args) => dispatch(getStudents(...args)),
});

export default connect(null, mapDispatchToProps)(StudentsInput);
