import { PageHeader, Input } from "antd";
import Table from "./Table";
import { useState, useCallback } from "react";
import { withRouter } from "react-router";
import debounce from "lodash/debounce";
import Constants from "../util/Constants";

function SimpleTableView({
  onUpdate,
  title,
  refresh,
  columns,
  getItems,
  mapItem,
  getFilters,
  renderFilters,
  parseFilters,
  isAnalyticTable = true,
  isSearchable = false,
  isBulkTagTable = false,
  searchLabel = "Search...",
}) {
  const [search, setSearch] = useState(null);
  const [tableFilters, setTableFilters] = useState(null);
  const [tableFiltersTemp, setTableFiltersTemp] = useState({});

  const onTabsUpdate = () => onUpdate(tableFiltersTemp);
  const onTableFiltersChange = (changed) =>
    setTableFiltersTemp((f) => ({ ...f, ...changed }));
  const onTableFiltersSearch = () =>
    setTableFilters(parseFilters(tableFiltersTemp));
  const onTableFiltersReset = () => {
    setSearch(null);
    setTableFilters({});
    setTableFiltersTemp({});
  };

  const onSearch = useCallback(
    debounce((e) => setSearch(e.target.value), Constants.DEBOUNCE_VALUE),
    []
  );

  return (
    <div className="table-page">
      <PageHeader
        title={title}
        extra={
          isSearchable ? (
            <Input
              placeholder={searchLabel}
              size="large"
              allowClear
              onChange={onSearch}
              style={{ width: 200 }}
              key="3"
            />
          ) : (
            <div></div>
          )
        }
      >
        {renderFilters && (
          <div className="table-filters">
            {renderFilters(
              tableFiltersTemp,
              onTableFiltersChange,
              onTableFiltersSearch,
              onTableFiltersReset,
              onTabsUpdate
            )}
          </div>
        )}
      </PageHeader>

      <Table
        search={search}
        columns={columns}
        getItems={getItems}
        getFilters={getFilters}
        tableFilters={tableFilters}
        isAnalyticTable={isAnalyticTable}
        isRowCheckable={false}
        mapItem={mapItem}
        isBulkTagTable={isBulkTagTable}
        refresh={refresh}
      />
    </div>
  );
}

export default withRouter(SimpleTableView);
