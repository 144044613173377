import React from 'react';
import { Modal } from 'antd';

export default function Dialog({ title, visible, onCancel, children, size, reset = true, className = '', closable = true }) {
	return (
		<Modal
			title={title}
			visible={visible}
			onCancel={closable ? onCancel : undefined}
			footer={null}
			centered
			wrapClassName={`${size ? ` modal-${size}` : ''} ${className}`}
			destroyOnClose={reset}
			keyboard={closable}
		>
			{children}
		</Modal>
	);
}
