import LocalStorage from "../data/LocalStorage";
import Constants from "../util/Constants";
import { http } from "../util/http";

export default class AuthService {
	static getToken = () => LocalStorage.get(Constants.KEY_TOKEN);

	static login(username, password) {
		return http.post('/users/login/', { username, password });
	}

	static logout = () => Promise.resolve();
}
