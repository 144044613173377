import { connect } from "react-redux";
import InformationDialog from "../../components/InformationDialog";
import { Button, Form, Input } from "antd";
import InputRules from "../../util/InputRules";
import { http } from "../../util/http";
import ReactJson from "react-json-view";

function AuthForm() {
  const onSubmit = async (values) => {
    const username = values.username;
    try {
      const res = await http.post("admin/authentoken/", { username });
      InformationDialog({
        title: "Auth Token",
        message: <ReactJson src={res} />,
      });
    } catch (e) {
      const JsonResponse = JSON.parse(e.request.responseText);
      InformationDialog({
        title: "Error!!",
        message: <ReactJson src={JsonResponse} />,
      });
    }
  };

  return (
    <Form className="tag-form" onFinish={onSubmit} layout="vertical">
      <Form.Item name="username" rules={InputRules.required("Enter Name")}>
        <Input placeholder="Enter User Name" />
      </Form.Item>

      <div className="form-buttons">
        <div />
        <Button type="primary" htmlType="submit" size="large" className="wide">
          Get Auth Token
        </Button>
      </div>
    </Form>
  );
}

export default connect()(AuthForm);
