import { Button, Table, Form, Input, Modal } from "antd";
import "antd/dist/antd.css";
import { useState, useEffect } from "react";
import { CloseCircleOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { isEmpty, omitBy } from "lodash";
import ExportCSV from "./exportCsv";
import axios from "axios";

function EditableTable(props) {
  const [dataSource, setDataSource] = useState([]);
  const [editingRow, setEditingRow] = useState(null);
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = (key) => {
    setEditingRow(key);
    if (dataSource[key].key === key) {
      setIsModalVisible(true);
    }
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSingle = (item) => {
    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/users/register/`,
        omitBy(
          {
            email: item.email,
            password: item.password,
            user_type: "student",
            first_name: item.FirstName,
            last_name: item.LastName,
            username: item.username,
            teacher_code: item.TeacherCode,
            school_code: item.InstituteCode,
          },
          isEmpty
        )
      )
      .then((response) => {
        if (response.status === 201) {
          let newArr = [...dataSource];
          newArr[item.key].Comments = "passed";
          newArr[item.key].status = "Created";
          setDataSource(newArr);
        }
        if (!response.ok) {
          throw response;
        }
        return response.json();
      })
      .then((result) => {
        console.log(result);
      })
      .catch((error) => {
        if (error.response) {
          const errors = JSON.parse(
            JSON.stringify(error.response.data).replace(/[\[\]']+/g, "")
          );
          dataSource[Number(item.key)].errors = [errors];
          let newArr = [...dataSource];
          newArr[item.key].Comments = "failed";
          newArr[item.key].status = Object.keys(errors)
            .map(function (key) {
              return `${key}: ` + errors[key];
            })
            .join(" ");
          setDataSource(newArr);
        }
      });
  };

  const handleRegister = () => {
    var myHeaders = new Headers();
    var raw;
    myHeaders.append("Content-Type", "application/json");

    dataSource?.map((item, key) =>
      setTimeout(() => {
        if (item?.Comments !== "passed") {
          raw = JSON.stringify(
            omitBy(
              {
                email: item.email,
                password: item.password,
                user_type: "student",
                first_name: item.FirstName,
                last_name: item.LastName,
                username: item.username,
                teacher_code: item.TeacherCode,
                school_code: item.InstituteCode,
              },
              isEmpty
            )
          );

          let requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
          };
          fetch(
            `${process.env.REACT_APP_SERVER_URL}/users/register/`,
            requestOptions
          )
            .then((response) => {
              if (response.status === 201) {
                let newArr = [...dataSource];
                newArr[key].Comments = "passed";
                newArr[item.key].status = "Created";
                setDataSource(newArr);
              }
              if (!response.ok) {
                throw response;
              }
              return response.json();
            })
            .then((result) => {
              console.log(result);
            })

            .catch((err) => {
              err.text().then((errorMessage) => {
                const errors = JSON.parse(
                  errorMessage.replace(/[\[\]']+/g, "")
                );
                dataSource[key].errors = [errors];
                let newArr = [...dataSource];
                newArr[key].Comments = "failed";
                newArr[key].status = Object.keys(errors)
                  .map(function (key) {
                    return `${key}: ` + errors[key];
                  })
                  .join(" ");
                setDataSource(newArr);
              });
            });
        }
      }, 200 * key)
    );
  };

  useEffect(() => {
    const data = [];
    props.data?.map((item, key) =>
      data.push({
        key: `${key}`,
        FirstName: item.FirstName,
        LastName: item.LastName,
        email: item.email,
        username: item.username,
        password: item.password,
        InstituteCode: item.InstituteCode?.replace(/(\r\n|\n|\r)/gm, ""),
        TeacherCode: item.TeacherCode?.replace(/(\r\n|\n|\r)/gm, ""),
        Comments: item.Comments,
        errors: [],
        status: "",
      })
    );
    setDataSource(data);
  }, [props.data]);
  const columns = [
    {
      title: "Email",
      dataIndex: "email",
      render: (text, record) => {
        if (editingRow === record.key) {
          return (
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "You must enter valid email",
                },
              ]}
            >
              <Input />
            </Form.Item>
          );
        } else {
          return (
            <div>
              <p> {text} </p>
            </div>
          );
        }
      },
    },
    {
      title: "Username",
      dataIndex: "username",
      render: (text, record) => {
        if (editingRow === record.key) {
          return (
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: "Username is required",
                },
              ]}
            >
              <Input />
            </Form.Item>
          );
        } else {
          return <p>{text}</p>;
        }
      },
    },
    {
      title: "Password",
      dataIndex: "password",
      render: (text, record) => {
        if (editingRow === record.key) {
          return (
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Password is required",
                },
              ]}
            >
              <Input />
            </Form.Item>
          );
        } else {
          return <p>{text}</p>;
        }
      },
    },
    {
      title: "First Name",
      dataIndex: "FirstName",
      render: (text, record) => {
        if (editingRow === record.key) {
          return (
            <Form.Item
              name="FirstName"
              rules={[
                {
                  required: true,
                  message: "Please enter your first name",
                },
              ]}
            >
              <Input />
            </Form.Item>
          );
        } else {
          return <p>{text}</p>;
        }
      },
    },
    {
      title: "Last Name",
      dataIndex: "LastName",
      render: (text, record) => {
        if (editingRow === record.key) {
          return (
            <Form.Item name="LastName">
              <Input />
            </Form.Item>
          );
        } else {
          return <p>{text}</p>;
        }
      },
    },
    {
      title: "Teacher Code",
      dataIndex: "TeacherCode",
      render: (text, record) => {
        if (editingRow === record.key) {
          return (
            <Form.Item name="TeacherCode">
              <Input />
            </Form.Item>
          );
        } else {
          return <p>{text}</p>;
        }
      },
    },
    {
      title: "Institute Code",
      dataIndex: "InstituteCode",
      render: (text, record) => {
        if (editingRow === record.key) {
          return (
            <Form.Item name="InstituteCode">
              <Input />
            </Form.Item>
          );
        } else {
          return <p>{text}</p>;
        }
      },
    },
    {
      title: "Status",
      dataIndex: "Comments",
      fixed: "right",
      width: 50,
      render: (text, record) => {
        return (
          <>
            <h6>
              {text === "passed" ? (
                <CheckCircleOutlined
                  style={{
                    color: "green",
                    fontSize: 30,
                    marginLeft: 25,
                    marginBottom: 10,
                  }}
                />
              ) : text === "failed" ? (
                <CloseCircleOutlined
                  style={{
                    color: "red",
                    fontSize: 30,
                    marginLeft: 25,
                    marginBottom: 10,
                  }}
                />
              ) : (
                "Pending"
              )}{" "}
            </h6>
            {text === "failed" && (
              <div>
                <Button
                  onClick={() => {
                    showModal(record.key);
                    form.setFieldsValue(record);
                  }}
                >
                  View Error
                </Button>
                <Modal
                  title="Errors"
                  visible={isModalVisible}
                  onOk={handleOk}
                  onCancel={handleCancel}
                >
                  {dataSource[editingRow]?.errors?.map((item) => {
                    return (
                      <>
                        <p>{item.email && `Email: ${item.email}`}</p>
                        <p>
                          {item.username && `Username: ${item.username}`}
                          {item.non_field_errors &&
                            `Username: ${item.non_field_errors}`}
                        </p>
                        <p>{item.password && `Password: ${item.password}`}</p>
                        <p>
                          {item.teacher_code &&
                            `Teacher Code: ${item.teacher_code}`}
                        </p>
                        <p>
                          {item.school_code &&
                            `School Code: ${item.school_code}`}
                        </p>
                      </>
                    );
                  })}
                </Modal>
              </div>
            )}
          </>
        );
      },
    },
    {
      title: "Actions",
      width: 350,
      render: (_, record) => {
        return (
          <>
            <Button
              type="link"
              onClick={() => {
                setEditingRow(record.key);
                form.setFieldsValue(record);
              }}
            >
              Edit
            </Button>
            <Button type="link" htmlType="submit">
              Save
            </Button>
            <Button type="link" onClick={() => handleSingle(record)}>
              Register
            </Button>
          </>
        );
      },
    },
  ];

  const onFinish = (values) => {
    if (!isEmpty(values)) {
      const updatedDataSource = [...dataSource];
      updatedDataSource.splice(editingRow, 1, { ...values, key: editingRow });
      setDataSource(updatedDataSource);
      setEditingRow(null);
    }
  };
  return (
    <div className="App">
      <header className="App-header">
        <Form form={form} onFinish={onFinish}>
          <Table
            columns={columns}
            dataSource={dataSource}
            pagination={false}
          ></Table>
        </Form>
        <h4 style={{ marginTop: 20, marginBottom: 10 }}>Step 3:</h4>
        <Button onClick={handleRegister}>Register</Button>
        <ExportCSV data={dataSource} />
      </header>
    </div>
  );
}

export default EditableTable;
