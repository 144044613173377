import Page from "../../components/layout/Page";
import { connect } from "react-redux";
import { Dropdown, Menu } from "antd";
import Links from "../../navigation/Links";
import { DownOutlined } from "@ant-design/icons";
import TableView from "../../components/TableView";
import { deleteSentence, getSentences } from "../../redux/actions";
import SentenceDialog from "./SentenceDialog";

const TableColumns = ({ onItemClick, onDelete } = {}) => [{
	title: 'Word',
	dataIndex: 'word',
	fixed: 'left',
	width: 92,
}, {
	title: 'Action',
	key: 'action',
	width: 130,
	fixed: 'right',
	render: (word) => (
		<span className="table-action-buttons">
	      <div className="text-button mr-10" onClick={() => onItemClick(word)}>View</div>
	      <Dropdown trigger={['click']} className="table-more-button" overlay={(
		      <Menu className="actions-menu">
			      <Menu.Item className="negative" onClick={() => onDelete(word)}>
				      Delete
			      </Menu.Item>
		      </Menu>
	      )}>
	          <span className="text-button ant-dropdown-link">More <DownOutlined /></span>
	      </Dropdown>
	  </span>
	)
}];

function Sentences({ getSentences, deleteSentence }) {
	return (
		<Page title="Sentences">
			<TableView
				title="Sentences"
				columns={TableColumns}
				getItems={getSentences}
				deleteItem={deleteSentence}
				addButton={{ text: "Add Sentence" }}
				Dialog={SentenceDialog}
				getLink={s => s ? Links.Sentence(s) : Links.SENTENCES}
			/>
		</Page>
	)
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
	getSentences: (...args) => dispatch(getSentences(...args)),
	deleteSentence: (...args) => dispatch(deleteSentence(...args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sentences);
