import { connect } from "react-redux";
import AsyncSelect from "./AsyncSelect";
import { getTeachers } from "../../redux/actions";

function TeachersInput({ getTeachers, ...props }) {
	return (
		<AsyncSelect {...props} getItems={getTeachers} labelField="name" valueField="id" />
	);
}

const mapDispatchToProps = (dispatch) => ({
	getTeachers: (...args) => dispatch(getTeachers(...args)),
});

export default connect(null, mapDispatchToProps)(TeachersInput);
