import Dialog from "../../components/Dialog";
import { useState } from "react";
import BulkWordsForm from "./BulkWordsForm";

export default function BulkWordsDialog({ visible, hide, ...props }) {
	const [loading, setLoading] = useState(false);

	return (
		<Dialog
			title="Bulk Words Upload"
			visible={visible} onCancel={hide} closable={!loading}
		>
			<BulkWordsForm
				{...props}
				submitting={loading}
				setSubmitting={l => setLoading(l)}
				onCancel={hide}
			/>
		</Dialog>
	)
}
