import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { authProtectedRoutes, publicRoutes } from './Routes';
import NonAuthLayout from '../components/layout/auth/NonAuthLayout';
import AuthLayout from '../components/layout/auth/AuthLayout';
import Links from './Links';
import PageLoader from '../components/PageLoader';
import Data from "../data";
import { connect } from "react-redux";

const AppRoute = ({ component: Component, layout: Layout, isAuthProtected, loggedIn, ...props }) => {
	return (
		<Route
			{...props}
			render={(props) => (
				isAuthProtected ? !loggedIn ? (
					<Redirect to={{ pathname: Links.LOGIN, state: { from: props.location } }} />
				) : (
					<Layout loggedIn={loggedIn}>
						<Component {...props} />
					</Layout>
				) : loggedIn ? (
					<Redirect to={{ pathname: Links.DASHBOARD }} />
				) : (
					<Layout loggedIn={loggedIn}>
						<Component {...props} />
					</Layout>
				)
			)}
		/>
	);
};

/**
 * Main Route component
 */
const Routes = ({ isLoggedIn }) => (
	<Suspense fallback={<PageLoader />}>
		<Switch>
			{publicRoutes.map((route, idx) => !route.redirect && (
				<AppRoute
					key={idx}
					path={route.path}
					layout={NonAuthLayout}
					component={route.component}
					isAuthProtected={false}
					loggedIn={isLoggedIn}
					exact={route.exact}
				/>
			))}

			{authProtectedRoutes.map((route, idx) => !route.redirect && (
				<AppRoute
					key={idx}
					path={route.path}
					layout={AuthLayout}
					component={route.component}
					isAuthProtected={true}
					loggedIn={isLoggedIn}
					exact={route.exact}
				/>
			))}
		</Switch>
	</Suspense>
);

const mapStateToProps = (state) => ({
	isLoggedIn: Data.getLoggedIn(state),
});

export default connect(mapStateToProps)(Routes);
