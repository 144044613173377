import Logger from "../../data/Logger";
import Notifications from "../../util/Notifications";
import WordService from "../../services/WordService";
import flatten from "lodash/flatten";
import Util from "../../util";
import SentenceService from "../../services/SentenceService";
import TagService from "../../services/TagService";

export const getWords = (filters) => () =>
  new Promise((resolve) => {
    if (typeof filters === "string") filters = { search: filters };
    WordService.paginate(filters)
      .then((r) => resolve(r))
      .catch(() => {
        Notifications.error("Failed to get words");
        resolve(false);
      });
  });

export const updateBulkTag = (query, add, remove) => (dispatch) =>
  new Promise((resolve, reject) => {
    TagService.bulkUpdate(query, add, remove)
      .then((r) => {
        Notifications.success("Tags updated successfully");
        resolve(r);
      })
      .catch((e) => {
        Logger.exception(e);
        Notifications.error("Failed to update tags");
        reject(e);
      });
  });
