import Dialog from "../../components/Dialog";
import { useState } from "react";
import ReportForm from "./ReportForm";
import AuthForm from "./AuthForm";
import PlayerDataForm from "./PlayerDataForm";

export function PasswordDialog({ visible, hide, item, ...props }) {
  const [loading, setLoading] = useState(false);

  return (
    <Dialog
      title={"Change Users Password"}
      visible={visible}
      onCancel={hide}
      closable={!loading}
    >
      <ReportForm
        {...props}
        id={item?.id}
        submitting={loading}
        setSubmitting={(l) => setLoading(l)}
        onCancel={hide}
      />
    </Dialog>
  );
}
export function AuthDialog({ visible, hide, item, ...props }) {
  const [loading, setLoading] = useState(false);

  return (
    <Dialog
      title={"Get Auth Token"}
      visible={visible}
      onCancel={hide}
      closable={!loading}
    >
      <AuthForm
        {...props}
        id={item?.id}
        submitting={loading}
        setSubmitting={(l) => setLoading(l)}
        onCancel={hide}
      />
    </Dialog>
  );
}
export function PlayerDataDialog({ visible, hide, item, ...props }) {
  const [loading, setLoading] = useState(false);

  return (
    <Dialog
      title={"Player Data URL"}
      visible={visible}
      onCancel={hide}
      closable={!loading}
    >
      <PlayerDataForm
        {...props}
        id={item?.id}
        submitting={loading}
        setSubmitting={(l) => setLoading(l)}
        onCancel={hide}
      />
    </Dialog>
  );
}
