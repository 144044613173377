import { connect } from 'react-redux';
import Multiselect from './Multiselect';
import { getNextPage, getWord, searchWords } from '../../redux/qna/actions';
import Data from '../../data';

function WordsInput({ searchWords, getNextPage, getWord, multiple = true, ...props }) {
  const onSubmit = word => {
    word = Data.mapWord(word);
    return {
      ...word,
      name: `${word.question}`,
    };
  };

  return multiple ? (
    <>
      <Multiselect
        {...props}
        getItems={searchWords}
        getValues={getWord}
        labelField="name"
        mapItem={onSubmit}
        getNextPage={getNextPage}
      />
    </>
  ) : (
    <></>
  );
}

const mapDispatchToProps = dispatch => ({
  searchWords: (...args) => dispatch(searchWords(...args)),
  getWord: (...args) => dispatch(getWord(...args)),
  getNextPage: (...args) => dispatch(getNextPage(...args)),
});

export default connect(null, mapDispatchToProps)(WordsInput);
