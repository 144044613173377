import Links from "./Links";
import Dashboard from "../pages/dashboard";
import Words from "../pages/words";
import CustomerSubscription from "../pages/customer-subscription";
import WordForm from "../pages/words/WordForm";
import Login from "../pages/auth/Login";
import Sentences from "../pages/sentences";
import SentenceTranslations from "../pages/sentence-translations";
import WordTranslations from "../pages/word-translations";
import Tags from "../pages/tags";
import Adventures from "../pages/adventures";
import ActivityBundles from "../pages/activity-bundles ";
import Bundles from "../pages/bundles";
import AssignedAdventures from "../pages/adventures-assigned";
import Analytics from "../pages/analytics";
import bankTransfer from "../pages/bank-transfer";
import exportReports from "../pages/export-reports";
import tagUpdate from "../pages/tag-update";
import unityLogs from "../pages/unity-logs";
import studentRewards from "../pages/student-reward";
import SubscriptionPassword from "../pages/customer-subscription/SubscriptionPassword";
import BankTransferPassword from "../pages/bank-transfer/BankTransferpassword";
import PlayerDataBackup from "../pages/player-data-backup";
import SubscriptionLogs from "../pages/subscription-logs";
import studentsRegistration from "../pages/students-registration";
import QnaAcitvity from "../pages/qna-activity";
import Qna from "../pages/qna";
import QnaWordForm from "../pages/qna/WordForm";


const authProtectedRoutes = [
  { path: Links.DASHBOARD, exact: true, component: Dashboard, auth: true },
 
  {
    path: Links.WORD_TRANSLATIONS,
    exact: true,
    component: WordTranslations,
    auth: true,
  },
  {
    path: Links.WORD_TRANSLATION,
    exact: true,
    component: WordTranslations,
    auth: true,
  },
  { path: Links.SENTENCES, exact: true, component: Sentences, auth: true },
  { path: Links.SENTENCE, exact: true, component: Sentences, auth: true },
  {
    path: Links.SENTENCE_TRANSLATIONS,
    exact: true,
    component: SentenceTranslations,
    auth: true,
  },
  {
    path: Links.SENTENCE_TRANSLATION,
    exact: true,
    component: SentenceTranslations,
    auth: true,
  },
  { path: Links.TAGS, exact: true, component: Tags, auth: true },
  { path: Links.TAG, exact: true, component: Tags, auth: true },
  { path: Links.ADVENTURES, exact: true, component: Adventures, auth: true },
  { path: Links.ADVENTURE, exact: true, component: Adventures, auth: true },
  {
    path: Links.ADVENTURES_ASSIGNED,
    exact: true,
    component: AssignedAdventures,
    auth: true,
  },
  {
    path: Links.ADVENTURE_ASSIGNED,
    exact: true,
    component: AssignedAdventures,
    auth: true,
  },

  { path: Links.BUNDLES, exact: true, component: Bundles, auth: true },
  { path: Links.BUNDLE, exact: true, component: Bundles, auth: true },

  { path: Links.ACTIVITY_BUNDLES, exact: true, component: ActivityBundles, auth: true },
  { path: Links.ACTIVITY_BUNDLE, exact: true, component: ActivityBundles, auth: true },

  { path: Links.ANALYTICS, exact: true, component: Analytics, auth: true },
  {
    path: Links.CUSTOMER_SUBSCRIPTIONS,
    exact: true,
    component: CustomerSubscription,
    auth: true,
  },
  {
    path: Links.CUSTOMER_SUBSCRIPTION,
    exact: true,
    component: CustomerSubscription,
    auth: true,
  },

  {
    path: Links.SUBSCRIPTION_LOGS,
    exact: true,
    component: SubscriptionLogs,
    auth: true,
  },

  {
    path: Links.SUBSCRIPTIONS_PASSWORD,
    exact: true,
    component: SubscriptionPassword,
    auth: true,
  },
  {
    path: Links.BANK_TRANSFERS,
    exact: true,
    component: bankTransfer,
    auth: true,
  },
  {
    path: Links.BANK_TRANSFERS_PASSWORD,
    exact: true,
    component: BankTransferPassword,
    auth: true,
  },
  {
    path: Links.EXPORT_REPORTS,
    exact: true,
    component: exportReports,
    auth: true,
  },
  { path: Links.TAG_UPDATE, exact: true, component: tagUpdate, auth: true },
  {
    path: Links.STUDENT_REWARDS,
    exact: true,
    component: studentRewards,
    auth: true,
  },
  { path: Links.STUDENTS_REGISTRATION, exact: true, component: studentsRegistration },
  { path: Links.UNITY_LOGS, exact: true, component: unityLogs, auth: true },
  {
    path: Links.PLAYER_DATA_BACKUPS,
    exact: true,
    component: PlayerDataBackup,
    auth: true,
  },

  { path: Links.QNA_ACVITIES, exact: true, component: QnaAcitvity, auth: true },
  { path: Links.QNA_ACTIVITY, exact: true, component: QnaAcitvity, auth: true },
  {
    path: Links.QNAS,
    exact: true,
    component: Qna,
    auth: true,
  },

  { path: Links.QNAS, exact: true, component: Qna, auth: true },
  { path: Links.ADD_QNA, exact: true, component: QnaWordForm, auth: true },
  { path: Links.QNA, exact: true, component: QnaWordForm, auth: true },
  
  { path: Links.WORDS, exact: true, component: Words, auth: true },
  { path: Links.ADD_WORD, exact: true, component: WordForm, auth: true },
  { path: Links.WORD, exact: true, component: WordForm, auth: true },
];

const publicRoutes = [{ path: Links.LOGIN, component: Login }];

export { authProtectedRoutes, publicRoutes };
