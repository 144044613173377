import Page from "../../components/layout/Page";
import { Link } from "react-router-dom";
import Links from "../../navigation/Links";
import TableView from "../../components/TableView";
import { getPlayerDataBackups } from "../../redux/actions";
import { connect } from "react-redux";
import Util from "../../util";
import { useState } from "react";
import { withRouter } from "react-router";
import PlayerDataBackupDialog from "./PlayerDataBackupDialog";

const TableColumns = ({ onItemClick } = {}) => [
  {
    title: "Id",
    dataIndex: "id",
  },
  {
    title: "User Name",
    dataIndex: "user",
    ellipsis: true,
    render: (user) => user.username,
  },
  {
    title: "Date",
    dataIndex: "date_created",
    ellipsis: true,
    render: (item) => {
      return Util.shortFormatedDateTime(item);
    },
  },
  {
    title: "Action",
    key: "action",
    width: 160,
    fixed: "right",
    render: (playerDataBackup) => (
      <span className="table-action-buttons">
        <Link
          className="text-button view"
          style={{ color: "white" }}
          onClick={() => onItemClick(playerDataBackup)}
          to="/player-data-backups"
        >
          View
        </Link>
      </span>
    ),
  },
];

function PlayerDataBackup({ PlayerDataBackups, history }) {
  const [playerDataBackupDialog, setPlayerDataBackupDialog] = useState(false);

  return (
    <Page title="Player Data Backups">
      <TableView
        title="Player Data Backups"
        columns={TableColumns}
        getItems={PlayerDataBackups}
        addButton={false}
        Dialog={PlayerDataBackupDialog}
        getLink={(s) =>
          s ? Links.PlayerDataBackup(s) : Links.PLAYER_DATA_BACKUPS
        }
        isSearchable={true}
        showTotal={true}
        isCheckable={false}
      />

      <PlayerDataBackupDialog
        visible={playerDataBackupDialog}
        hide={() => setPlayerDataBackupDialog(false)}
        onDone={() => {
          setPlayerDataBackupDialog(false);
          history.go(0);
        }}
      />
    </Page>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  PlayerDataBackups: (...args) => dispatch(getPlayerDataBackups(...args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PlayerDataBackup));
