import { connect } from "react-redux";
import AsyncSelect from "./AsyncSelect";
import {
  getAdventure,
  searchAdventures,
  getNextPageAdventures,
} from "../../redux/actions";
import Multiselect from "./Multiselect";

function AdventuresInput({
  searchAdventures,
  getNextPage,
  getAdventure,
  multiple = true,
  ...props
}) {
  return multiple ? (
    <Multiselect
      {...props}
      getItems={searchAdventures}
      getValues={getAdventure}
      getNextPage={getNextPage}
    />
  ) : (
    <AsyncSelect
      {...props}
      getItems={searchAdventures}
      getValues={getAdventure}
      labelField="name"
      valueField="id"
    />
  );
}

const mapDispatchToProps = (dispatch) => ({
  searchAdventures: (...args) => dispatch(searchAdventures(...args)),
  getAdventure: (...args) => dispatch(getAdventure(...args)),
  getNextPage: (...args) => dispatch(getNextPageAdventures(...args)),
});

export default connect(null, mapDispatchToProps)(AdventuresInput);
