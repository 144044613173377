import Constants from '../util/Constants';

export default class Logger {
	static log(tag, ...args) {
		if (!Constants.LOGS_ENABLED) return;

		console.log(tag || 'log', ...args);
	}

	static info(tag, ...args) {
		if (!Constants.LOGS_ENABLED) return;

		console.info(tag || 'info', ...args);
	}

	static error(tag, ...args) {
		if (!Constants.LOGS_ENABLED) return;

		console.error(tag || 'error', ...args);
	}

	static exception(exception) {
		Logger.error('exception', exception);
	}
}
