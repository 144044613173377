import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { Button, Form, Input } from 'antd';
import {
  addBundle,
  deleteBundle,
  getBundle,
  updateBundle,
} from '../../redux/bundles/actions';
import { searchAdventures } from '../../redux/actions';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import PageLoader from '../../components/PageLoader';
import InputRules from '../../util/InputRules';
import AdventuresInput from '../../components/form/AdventuresInput';
import Util from '../../util';
import ImageInput from '../../components/form/ImageInput';

function parseBundle(bundle) {
  return bundle;
}

function formatBundle(form) {
  return {
    ...form,
    adventures: Util.isObjectArray(form.adventures)
      ? form.adventures?.map(a => a.id)
      : form.adventures,
  };
}

function BundleForm({
  id,
  item,
  addBundle,
  deleteBundle,
  getBundle,
  updateBundle,
  onDone,
  onCancel,
  submitting,
  setSubmitting,
}) {
  const [loading, setLoading] = useState(false);
  const isEdit = !!id;

  const [bundle, setBundle] = useState(item ? parseBundle(item) : null);

  useEffect(() => {
    if (!id || item) return;

    setLoading(true);
    getBundle(id)
      .then(data => (data ? setBundle(parseBundle(data)) : onCancel()))
      .finally(() => setLoading(false));
  }, [item, onCancel, getBundle, id]);

  const onSubmit = values => {
    values = formatBundle(values);
    setSubmitting(true);
    if (isEdit) {
      updateBundle(id, values)
        .then(updated => onDone(updated))
        .catch(() => {})
        .finally(() => setSubmitting(false));
    } else {
      addBundle(values)
        .then(added => onDone(added))
        .catch(() => {})
        .finally(() => setSubmitting(false));
    }
  };

  const onDelete = () => {
    ConfirmationDialog({
      title: 'Are you sure you want to delete this bundle?',
      onConfirm: () => {
        deleteBundle(id).then(() => onDone(bundle));
      },
    });
  };

  return loading ? (
    <PageLoader />
  ) : (
    <Form
      className="bundle-form"
      onFinish={onSubmit}
      layout="vertical"
      initialValues={isEdit ? bundle : {}}
    >
      <Form.Item
        label="Name"
        name="name"
        rules={InputRules.required('Please input name')}
      >
        <Input placeholder="Bundle name" />
      </Form.Item>

      <Form.Item label="Bundle Image" name="cover_image">
        <ImageInput className="word-image-input" />
      </Form.Item>

      <Form.Item name="adventures" label="Adventures">
        <AdventuresInput multiple />
      </Form.Item>

      <div className="form-buttons">
        {!isEdit ? (
          <div />
        ) : (
          <Button
            type="text"
            size="large"
            loading={loading}
            onClick={onDelete}
            className="danger"
            disabled={submitting}
          >
            Delete
          </Button>
        )}
        <Button
          type="primary"
          htmlType="submit"
          size="large"
          loading={submitting}
          className="wide"
          disabled={submitting}
        >
          {isEdit ? 'Update this ' : 'Add'} Bundle
        </Button>
      </div>
    </Form>
  );
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  getBundle: (...args) => dispatch(getBundle(...args)),
  searchAdventures: (...args) => dispatch(searchAdventures(...args)),
  addBundle: (...args) => dispatch(addBundle(...args)),
  updateBundle: (...args) => dispatch(updateBundle(...args)),
  deleteBundle: (...args) => dispatch(deleteBundle(...args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BundleForm);
