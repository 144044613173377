import { connect } from "react-redux";
import { getLearningAims } from "../../redux/actions";
import AsyncSelect from "./AsyncSelect";

function LearningAimInput({ label, name, index, required, getLearningAims, ...props }) {
	return (
		<AsyncSelect {...props} getItems={getLearningAims} />
	)
}

const mapDispatchToProps = (dispatch) => ({
	getLearningAims: (...args) => dispatch(getLearningAims(...args)),
});

export default connect(null, mapDispatchToProps)(LearningAimInput);
