const Language = {
	ENGLISH: {
		id: 1,
		name: 'English',
		code: 'en-US'
	},
	JAPANESE: {
		id: 2,
		name: 'Japanese',
		code: 'jpn'
	}
}

export default Language;
