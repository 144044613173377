import { connect } from "react-redux";
import AsyncSelect from "./AsyncSelect";
import { getSentence, searchSentences } from "../../redux/actions";

function SentencesInput({ searchSentences, getSentence, ...props }) {
	return (
		<AsyncSelect
			{...props}
			getItems={searchSentences}
			getValues={getSentence}
			labelField="id"
			valueField="id"
		/>
	);
}

const mapDispatchToProps = (dispatch) => ({
	searchSentences: (...args) => dispatch(searchSentences(...args)),
	getSentence: (...args) => dispatch(getSentence(...args)),
});

export default connect(null, mapDispatchToProps)(SentencesInput);
