import AsyncSelect from "./AsyncSelect";
import Multiselect from "./Multiselect";
import { getNextPage, getWord, searchWords } from "../../redux/actions";
import { connect } from "react-redux";
import Data from "../../data";
import TagsInput from "./TagsInput";

function WordsInput({
  searchWords,
  getNextPage,
  getWord,
  multiple = true,
  ...props
}) {
  return multiple ? (
    <>
      <Multiselect
        {...props}
        getItems={searchWords}
        getValues={getWord}
        labelField="name"
        mapItem={Data.mapWordItem}
        renderFilters={(filters, setFilters) => (
          <TagsInput
            value={filters.tags}
            onChange={(tags) => setFilters({ tags })}
            placeholder="Filter by tag"
            allowNew={false}
          />
        )}
        parseFilters={(filters) => {
          let tagsList = "";
          for (let i = 0; i < filters.tags?.length; i++) {
            tagsList += filters.tags[i].id + ",";
          }

          return {
            ...filters,
            tags: tagsList,
          };
        }}
        getNextPage={getNextPage}
      />
    </>
  ) : (
    <AsyncSelect
      {...props}
      getItems={searchWords}
      getValues={getWord}
      mapItem={Data.mapWord}
      labelFormat={(label, item) => `${item.id} — ${label}`}
      labelField="name"
      valueField="id"
    />
  );
}

const mapDispatchToProps = (dispatch) => ({
  searchWords: (...args) => dispatch(searchWords(...args)),
  getWord: (...args) => dispatch(getWord(...args)),
  getNextPage: (...args) => dispatch(getNextPage(...args)),
});

export default connect(null, mapDispatchToProps)(WordsInput);
