import { http } from "../util/http";
import Util from "../util";

export default class AdventureService {
  static paginate(filters) {
    return http.get(
      `/adventures-data-entry/adventures/?${Util.toQuery(filters)}`
    );
  }

  static search(input) {
    return http.get(`/adventures-data-entry/adventures/?search=${input || ""}`);
  }

  static get(id) {
    return http.get(`adventures-data-entry/adventures/${id}/`);
  }

  static getNextPage(next) {
    return http.get(`/adventures-data-entry/adventures/?${next}`);
  }

  static add(adventure) {
    return http.post("/adventures-data-entry/adventures/", adventure);
  }

  static update(id, data) {
    return http.patch(`/adventures-data-entry/adventures/${id}/`, data);
  }

  static delete(id) {
    return http.delete(`/adventures-data-entry/adventures/${id}/`);
  }
}
