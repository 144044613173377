import Links from "../../navigation/Links";
import { LOGIN_USER, LOGOUT_USER } from "./constants";
import AuthService from "../../services/AuthService";
import Logger from "../../data/Logger";
import Notifications from "../../util/Notifications";
import LocalStorage from "../../data/LocalStorage";
import Constants from "../../util/Constants";

const onLogin = (data, history) => (dispatch) =>
  new Promise((resolve) => {
    if (data.user_type !== null) {
      Notifications.error("You are not allowed to access admin portal!");
      resolve(false);
    } else {
      LocalStorage.set(Constants.KEY_TOKEN, data.access_token);
      dispatch(loginUserEvent(data));
      history?.push(Links.DASHBOARD);
      Notifications.success("Welcome Back!");
      resolve(true);
    }
  });

export const loginUserEvent = (user) => ({
  type: LOGIN_USER,
  user,
});

export const loginUser =
  ({ username, password }, history) =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      AuthService.login(username, password)
        .then((response) => {
          return dispatch(onLogin(response, history));
        })
        .then(() => resolve(true))
        .catch((e) => {
          Logger.exception(e);
          reject(e);
        });
    });

export const logoutUserEvent = () => ({
  type: LOGOUT_USER,
});

export const logoutUser = (history) => (dispatch) =>
  new Promise((resolve) => {
    AuthService.logout()
      .then(() => {
        dispatch(logoutUserEvent());
        history?.push(Links.LOGIN);
      })
      .catch(() => Notifications.error("Failed to logout"));
  });
